import React, { useState, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import _ from "lodash";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import * as yup from "yup";
import { CommentsContext } from "./index";

const Details = () => {
  const { _id } = useParams();
  const { editSelected, setEditSelected, refreshComments } = useContext(CommentsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(editSelected?._id ? { ...editSelected } : {});
    setFormErrors({});
  }, [editSelected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id
          ? await api.patch(`/private/customers/${_id}/comments/${data._id}`, data)
          : await api.put(`/private/customers/${_id}/comments`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setEditSelected();
        refreshComments();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshComments, toast, setEditSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        content: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Modal size="6xl" isOpen={editSelected} onClose={() => setEditSelected()} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Observação</Text>
          </ModalHeader>
          <Divider />
          <ModalBody py={4}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.content}>
                  <FormLabel fontSize="sm">Observação</FormLabel>
                  <Textarea
                    value={formData.content ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, content: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.content}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={() => setEditSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Details;
