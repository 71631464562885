import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Link as Routerlink, useLocation, useNavigate, useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import BrazilPhoneInput from "react-telefone-brasileiro";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  SlideFade,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Breadcrumb, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, useArrayItemHandlers } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdAddCircleOutline, MdChevronLeft, MdHistory, MdOutlineDelete } from "react-icons/md";
import { Content } from "pages/Private/Container";
import moment from "moment";
import { TbExternalLink } from "react-icons/tb";

export const ClickRecSolicitationsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar Click Rec" : "Nova Click Rec");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/click-rec-solicitations/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: true };
    if (formData.periodRecStart) formData.periodRecStart = moment(formData.periodRecStart).format("DD/MM/YYYY");
    if (formData.periodRecEnd) formData.periodRecEnd = moment(formData.periodRecEnd).format("DD/MM/YYYY");
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id
          ? await api.patch(`/private/click-rec-solicitations/${_id}`, data)
          : await api.put("/private/click-rec-solicitations", data);
        navigate(`/click-rec-solicitations/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        phone: yup.string().required(messages.error.required),
        contactName: yup.string().required(messages.error.required),
        periodRecStart: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        periodRecEnd: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        consumerUnits: yup.array().of(
          yup.object().shape({
            installationNumber: yup.string().isValidCemigNumber(3).required(messages.error.required),
          })
        ),
      });
      const data = {
        ...formData,
        periodRecStart: moment(formData.periodRecStart, "DD/MM/YYYY").toDate(),
        periodRecEnd: moment(formData.periodRecEnd, "DD/MM/YYYY").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/click-rec-solicitations")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/click-rec-solicitations", label: "click rec" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Click Rec</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors.nid}>
              <FormLabel fontSize="sm">NID</FormLabel>
              <Input value={formData.nid ?? ""} isDisabled={true} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel fontSize="sm">Status</FormLabel>
              <Select value={formData.status ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
                <option>--Selecione</option>
                <option value="pending">Pendente</option>
                <option value="issued">Emitido</option>
                <option value="archived">Arquivado</option>
              </Select>
              <FormErrorMessage>{formErrors.status}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 5 }}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel fontSize="sm">Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.document}>
              <FormLabel fontSize="sm">CNPJ</FormLabel>
              <Input
                as={InputMask}
                mask="99.999.999/9999-99"
                value={formData.document ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              />
              <FormErrorMessage>{formErrors.document}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.email}>
              <FormLabel fontSize="sm">E-mail</FormLabel>
              <Input value={formData.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.phone}>
              <FormLabel fontSize="sm">Telefone</FormLabel>
              <Input
                as={BrazilPhoneInput}
                value={formData.phone ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
                temDDD
                separaDDD
              />
              <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.contactName}>
              <FormLabel fontSize="sm">Nome do contato</FormLabel>
              <Input
                value={formData.contactName ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, contactName: target.value }))}
              />
              <FormErrorMessage>{formErrors.contactName}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.periodRecStart || formErrors.periodRecEnd}>
              <FormLabel fontSize="sm">Período REC</FormLabel>
              <HStack>
                <InputGroup>
                  <InputLeftAddon fontSize="sm">de</InputLeftAddon>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    value={formData.periodRecStart ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, periodRecStart: target.value }))}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon fontSize="sm">até</InputLeftAddon>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    value={formData.periodRecEnd ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, periodRecEnd: target.value }))}
                  />
                </InputGroup>
              </HStack>
              <FormErrorMessage>{formErrors.periodRecStart || formErrors.periodRecEnd}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={4} />

        <HStack justifyContent="space-between" mb={4}>
          <Heading size="md">Unidades consumidoras</Heading>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icon as={MdAddCircleOutline} />}
            onClick={() => handleAddArrayItem("consumerUnits", { installationNumber: "" })}
          >
            adicionar UC
          </Button>
        </HStack>

        {_.map(formData.consumerUnits, (consumerUnit, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={4}>
              <FormControl isRequired={true} isInvalid={formErrors[`consumerUnits[${index}].installationNumber`]}>
                <FormLabel fontSize="sm">Número de instalação</FormLabel>
                <HStack>
                  <Input
                    value={consumerUnit.installationNumber ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("consumerUnits", index, { installationNumber: target.value })}
                  />
                  <IconButton
                    icon={<Icon as={TbExternalLink} />}
                    size="md"
                    variant="outline"
                    as={Routerlink}
                    to={`/customers/edit/${consumerUnit?.customer}#consumer-units`}
                    target="_blank"
                  />
                </HStack>
                <FormErrorMessage>{formErrors[`consumerUnits[${index}].installationNumber`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={7} borderWidth="1px" borderRadius="lg" py="10px" px="15px">
              <Text fontSize="sm" fontWeight="semibold">
                Endereço
              </Text>
              <Text>
                {consumerUnit?.address?.line1} - {consumerUnit?.address?.line2}
              </Text>
            </GridItem>
            <GridItem>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("consumerUnits", index)}
              >
                excluir
              </Button>
            </GridItem>
          </Grid>
        ))}
      </Content>

      <PermissionedContainer required={"clickRecSolicitations.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/click-rec-solicitations")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory
          path={`/private/click-rec-solicitations/${_id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </>
  );
};
