import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import _ from "lodash";
import InputMask from "react-input-mask";
import { InputCurrency } from "components";
import { api, currency, EventEmitter, yup } from "lib";
import { messages } from "consts";
import { useCustomToast } from "hooks";
import { MdBlock, MdClose, MdSave } from "react-icons/md";
import { CreditsContext, entityTypes } from "./index";
import moment from "moment";

const Create = () => {
  const { consumerUnit, entityType, query, isOpenCreate, onCloseCreate } = useContext(CreditsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = isOpenCreate ? { entityType, status: query?.status || "consolidated" } : {};
    setFormData(formData);
    setFormErrors({});
  }, [entityType, query?.status, isOpenCreate]);

  useEffect(() => {
    setFormData((state) => {
      const transactionTypes = { blocked: "outflow", scheduled: "inflow", consolidated: "inflow" };
      return { ...state, transactionType: transactionTypes[state.status] };
    });
  }, [formData.status]);

  useEffect(() => {
    setFormData((state) => ({ ...state, installmentCount: 1 }));
  }, [formData.status, formData.transactionType]);

  useEffect(() => {
    setFormData((state) => ({ ...state, scheduledDate: moment().format("DD/MM/YYYY") }));
  }, [formData.installmentCount]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`/private/consumer-units/${consumerUnit._id}/credits`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        EventEmitter.emit("consumerUnits.refresh");
        EventEmitter.emit("consumerUnitCredits.refresh");
        onCloseCreate();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [consumerUnit._id, onCloseCreate, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        transactionType: yup.string().required(messages.error.required),
        entityType: yup.string().required(messages.error.required),
        status: yup.string().required(messages.error.required),
        amount: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
        scheduledDate: yup.date().when("status", {
          is: "scheduled",
          then: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        }),
        installmentCount: yup.number().moreThan(0, messages.error.moreThan.concat("0.")).required(messages.error.required),
        description: yup.string().required(messages.error.required),
      });
      const data = { ...formData, scheduledDate: moment(formData.scheduledDate, "DD/MM/YYYY").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        size="xl"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Box flex="1">
              <Text fontSize="md" mb="-2px">
                Novo lançamento
              </Text>
              <Text fontSize="xs" fontWeight="light">
                Créditos {entityTypes[entityType]}
              </Text>
            </Box>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onCloseCreate} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={6}>
                <FormControl isRequired={true} isInvalid={formErrors.status}>
                  <FormLabel fontSize="xs" mb="2px">
                    Status
                  </FormLabel>
                  <Select
                    value={formData.status || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}
                  >
                    <option value="consolidated">Consolidado</option>
                    <option value="scheduled">Agendado</option>
                    <option value="blocked">Bloqueado</option>
                  </Select>
                  <FormErrorMessage>{formErrors.status}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={6}>
                <FormControl isRequired={true} isInvalid={formErrors.transactionType}>
                  <FormLabel fontSize="xs" mb="2px">
                    Tipo de transação
                  </FormLabel>
                  <Select
                    value={formData.transactionType || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, transactionType: target.value }))}
                    isDisabled={formData.status !== "consolidated"}
                  >
                    <option value="inflow">Entrada</option>
                    <option value="outflow">Saída</option>
                  </Select>
                  <FormErrorMessage>{formErrors.transactionType}</FormErrorMessage>
                </FormControl>
              </GridItem>

              {formData.status === "scheduled" && (
                <GridItem colSpan={12}>
                  <FormControl isRequired={true} isInvalid={formErrors.scheduledDate}>
                    <FormLabel fontSize="xs" mb="2px">
                      Data do agendamento
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      value={formData.scheduledDate ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, scheduledDate: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.scheduledDate}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}

              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.amount}>
                  <FormLabel fontSize="xs" mb="2px">
                    Valor
                  </FormLabel>
                  <HStack>
                    <InputGroup flex="1">
                      <InputLeftAddon>R$</InputLeftAddon>
                      <Input
                        as={InputCurrency}
                        value={formData.amount ?? ""}
                        onChange={(amount) => setFormData((state) => ({ ...state, amount }))}
                      />
                    </InputGroup>
                    {formData.status === "blocked" && (
                      <Button
                        fontSize="sm"
                        leftIcon={<Icon as={MdBlock} />}
                        onClick={() =>
                          setFormData((state) => ({
                            ...state,
                            amount: consumerUnit[entityType.concat("AvailableCreditBalance")],
                          }))
                        }
                      >
                        saldo total
                      </Button>
                    )}
                  </HStack>
                  <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
                </FormControl>
              </GridItem>

              {formData.status === "consolidated" && formData.transactionType === "inflow" && (
                <GridItem colSpan={formData.installmentCount > 1 ? 6 : 12}>
                  <FormControl isRequired={true} isInvalid={formErrors.installmentCount}>
                    <FormLabel fontSize="xs" mb="2px">
                      Quantidade de parcelas
                    </FormLabel>
                    <InputGroup>
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={formData.installmentCount ?? ""}
                        onChange={(installmentCount) => setFormData((state) => ({ ...state, installmentCount }))}
                      />
                      <InputRightAddon fontSize="xs">x de {currency(formData.amount / formData.installmentCount)}</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.installmentCount}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}

              {formData.installmentCount > 1 && (
                <GridItem colSpan={6}>
                  <FormControl isRequired={true} isInvalid={formErrors.scheduledDate}>
                    <FormLabel fontSize="xs" mb="2px">
                      Data da primeira parcela
                    </FormLabel>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      value={formData.scheduledDate ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, scheduledDate: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.scheduledDate}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}

              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.description}>
                  <FormLabel fontSize="xs" mb="2px">
                    Descrição
                  </FormLabel>
                  <Textarea
                    value={formData.description ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.description}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseCreate}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" leftIcon={<Icon as={MdSave} />} isLoading={isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Create;
