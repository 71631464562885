import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DocumentHistory } from "containers";
import { useFetchData, useQueryParams, useWatcherZIndex } from "hooks";
import { MdHistory } from "react-icons/md";
import PaymentsDetailsContext from "./context";
import General from "./general";

const queryParamsKey = "webhook_id";

export const WebhooksDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/webhooks/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  return (
    <PaymentsDetailsContext.Provider value={{ formData, isLoadingData, refreshData }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <HStack flex="1">
              <Text>Webhook</Text>
              {isLoadingData ? <Spinner size="sm" /> : <Text>#{formData._id || "-"}</Text>}
            </HStack>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <General />
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <DocumentHistory path={`/private/webhooks/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
    </PaymentsDetailsContext.Provider>
  );
};
