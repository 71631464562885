import React from "react";
import _ from "lodash";
import { HStack, Icon, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { LuFileEdit } from "react-icons/lu";
import Context from "./context";
import Dialog from "./dialog";

export const ChargeUpdate = ({ charge, isDisabled }) => {
  const { isOpen: isOpenUpdateDialog, onOpen: onOpenUpdateDialog, onClose: onCloseUpdateDialog } = useDisclosure();

  return (
    <Context.Provider value={{ charge, isOpenUpdateDialog, onCloseUpdateDialog }}>
      <PermissionedContainer required="charges.update">
        <MenuItem isDisabled={isDisabled} onClick={onOpenUpdateDialog}>
          <HStack>
            <Icon as={LuFileEdit} />
            <Text>atualizar cobrança</Text>
          </HStack>
        </MenuItem>
        <Dialog />
      </PermissionedContainer>
    </Context.Provider>
  );
};
