import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { masks } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "document",
    title: "CNPJ",
    isVisible: true,
    formatter: ({ document }) => masks.document(document),
  },
  {
    accessor: "productionInKwhByMonth",
    title: "Geração mensal",
    isVisible: true,
    formatter: ({ productionInKwhByMonth }) => productionInKwhByMonth.toLocaleString().concat("kWh"),
  },
  {
    accessor: "powerInKw",
    title: "Potência",
    isVisible: true,
    formatter: ({ powerInKw }) => powerInKw.toLocaleString().concat("kW"),
  },
  {
    accessor: "address.zipCode",
    title: "CEP",
    isVisible: true,
    formatter: ({ address }) => masks.zipCode(address?.zipCode),
  },
  {
    accessor: "address.street",
    title: "Rua",
    isVisible: false,
    formatter: ({ address }) => address?.street,
  },
  {
    accessor: "address.number",
    title: "Número",
    isVisible: false,
    formatter: ({ address }) => address?.number,
  },
  {
    accessor: "address.complement",
    title: "Complemento",
    isVisible: false,
    formatter: ({ address }) => address?.complement,
  },
  {
    accessor: "address.neighborhood",
    title: "Bairro",
    isVisible: false,
    formatter: ({ address }) => address?.neighborhood,
  },
  {
    accessor: "address.city",
    title: "Cidade",
    isVisible: true,
    formatter: ({ address }) => address?.city,
  },
  {
    accessor: "address.state",
    title: "Estado",
    isVisible: false,
    formatter: ({ address }) => address?.state,
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
