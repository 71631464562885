import React, { useEffect, useState, useCallback } from "react";
import { Flex, Text, Icon, Modal, ModalOverlay, ModalContent, ModalBody, Button, Heading, Center, useToken } from "@chakra-ui/react";
import { MdOutlineCloudDownload } from "react-icons/md";
import { EventEmitter } from "lib";
import { Lottie } from "components";
import animationData from "assets/lottie/cogs.json";

export const AppVersionError = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [main500] = useToken("colors", ["main.500"]);

  useEffect(() => {
    const listener = EventEmitter.addListener("onAppVersionError", (error) => setMessage(error.message));
    return () => listener.remove();
  }, []);

  const handleRefresh = useCallback(async () => {
    try {
      setIsLoading(true);
      if ("caches" in window) {
        const names = await caches.keys();
        for (const name of names) await caches.delete(name);
      }
    } finally {
      window.location.reload(true);
    }
  }, []);

  return (
    <Modal isOpen={message} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody as={Flex} direction="column" justifyContent="center" alignItems="center" padding="30px">
          <Center overflow="hidden">
            <Lottie
              replaceColors={[{ from: [8, 69, 221], to: main500 }]}
              animationData={animationData}
              showColors={true}
              style={{ width: 300, margin: -50 }}
            />
          </Center>
          <Heading size="lg">Atualização necessária</Heading>
          <Text fontSize="sm" textAlign="center" mb="20px">
            {message}
          </Text>
          <Button colorScheme="main" rightIcon={<Icon as={MdOutlineCloudDownload} />} isLoading={isLoading} onClick={handleRefresh}>
            Atualizar aplicação
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
