import { useRef, useEffect } from "react";

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = [title, process.env.REACT_APP_NAME].join(" | ");
  }, [title]);

  useEffect(() => {
    return () => {
      if (!prevailOnUnmount) document.title = defaultTitle.current;
    };
  }, []);
};
