import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { masks, translator } from "lib";
import { StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { messageTopics } from "consts";

const defaultColumns = [
  {
    accessor: "verificationStatus",
    title: "Status da verificação",
    isVisible: true,
    render: ({ verificationStatus }) => <StatusBadge schema="messagesVerification" status={verificationStatus} />,
    exporter: ({ verificationStatus }) => translator(verificationStatus),
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="messages" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "channel",
    title: "Canal",
    isVisible: true,
    formatter: ({ channel }) => translator(channel),
  },
  {
    accessor: "topic",
    title: "Tópico",
    isVisible: true,
    formatter: ({ topic }) => messageTopics[topic] || "-",
  },
  {
    accessor: "contactTarget",
    title: "Destinatário",
    isVisible: true,
    formatter: ({ contactTarget, channel }) => (channel === "whatsapp" ? masks.phone(contactTarget) : contactTarget),
  },
  {
    accessor: "charge.nid",
    title: "Cobrança/NID",
    isVisible: true,
    isSortable: false,
    render: ({ charge }, { setQueryParams }) =>
      charge ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => setQueryParams((params) => ({ ...params, charge_id: charge._id }))}
        >
          <HStack>
            <StatusBadge schema="charges" status={charge.status} />
            <Text>#{charge.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ charge }) => charge?.nid || "-",
  },
  {
    accessor: "invoice.nid",
    title: "Fatura/NID",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) =>
      invoice ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          as={RouterLink}
          to={`/invoices/details/${invoice._id}`}
          target="_blank"
        >
          <HStack>
            <StatusBadge schema="invoices" status={invoice.status} />
            <Text>#{invoice.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ invoice }) => invoice?.nid || "-",
  },
  {
    accessor: "invoice.status",
    title: "Fatura/Status",
    isVisible: false,
    isSortable: false,
    render: ({ invoice }) => <StatusBadge schema="invoices" status={invoice.status} />,
    exporter: ({ invoice }) => translator(invoice?.status),
  },
  {
    accessor: "customer.name",
    title: "Cliente/Nome",
    isVisible: true,
    isSortable: false,
    formatter: ({ customer }) => customer?.name || "-",
  },
  {
    accessor: "customer.document",
    title: "Cliente/Documento",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => masks.document(customer?.document || "-"),
  },
  {
    accessor: "customer.primaryBillingContact.phone",
    title: "Cliente/Telefone faturamento",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => masks.phone(customer?.primaryBillingContact?.phone || "-"),
  },
  {
    accessor: "customer.primaryBillingContact.email",
    title: "Cliente/E-mail faturamento",
    isVisible: false,
    isSortable: false,
    formatter: ({ customer }) => customer?.primaryBillingContact?.email || "-",
  },
  {
    accessor: "consumerUnit.cemigInstallationNumber",
    title: "N° instalação",
    isVisible: true,
    formatter: ({ consumerUnit }) => consumerUnit?.cemigInstallationNumber || "-",
  },
  {
    accessor: "consumerUnit.status.title",
    title: "Status da UC",
    isVisible: true,
    isSortable: false,
    render: ({ consumerUnit }) => <StatusBadge {...consumerUnit?.status} />,
    exporter: ({ consumerUnit }) => translator(consumerUnit?.status?.title),
  },
  {
    accessor: "archivedBy",
    title: "Arquivado por",
    isVisible: true,
    formatter: ({ archivedBy }) => archivedBy?.name || "-",
  },
  {
    accessor: "archivedAt",
    title: "Arquivado em",
    isVisible: true,
    formatter: ({ archivedAt }) => (archivedAt ? moment(archivedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "archivedComments",
    title: "Observações",
    isVisible: false,
    formatter: ({ archivedComments }) => archivedComments || "-",
  },
  {
    accessor: "isNewest",
    title: "Última mensagem?",
    isVisible: true,
    formatter: ({ isNewest }) => (isNewest ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "minSendDate",
    title: "Dt. min. envio",
    isVisible: true,
    formatter: ({ minSendDate }) => (minSendDate ? moment(minSendDate).format("DD/MM/YYYY [às] HH:mm:ss") : "-"),
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
