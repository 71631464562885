import { useEffect, useState } from "react";

const cached = {};

export const useCacheState = ({ key, defaultValue, useCached = true }) => {
  const [value, setValue] = useState(() => {
    if (useCached && cached[key]) return cached[key];
    return defaultValue;
  });

  useEffect(() => {
    if (useCached) cached[key] = value;
  }, [key, useCached, value]);

  return [value, setValue];
};
