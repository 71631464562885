import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { masks, translator } from "lib";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="clickRecSolicitations" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "document",
    title: "CNPJ",
    isVisible: true,
    formatter: ({ document }) => masks.document(document),
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    formatter: ({ email }) => email,
  },
  {
    accessor: "phone",
    title: "Telefone",
    isVisible: true,
    formatter: ({ phone }) => phone,
  },
  {
    accessor: "contactName",
    title: "Nome contato",
    isVisible: true,
    formatter: ({ contactName }) => contactName,
  },
  {
    accessor: "periodRecStart",
    title: "Per. REC início",
    isVisible: true,
    formatter: ({ periodRecStart }) => (periodRecStart ? moment(periodRecStart).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "periodRecEnd",
    title: "Per. REC fim",
    isVisible: true,
    formatter: ({ periodRecEnd }) => (periodRecEnd ? moment(periodRecEnd).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
