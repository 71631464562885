import React, { useCallback, useState } from "react";
import _ from "lodash";
import { Box, HStack, Icon, MenuItem, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, VStack } from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { api } from "lib";
import { BsFileEarmarkPdf } from "react-icons/bs";

export const BankSlipDownload = ({ invoice = {}, charge, isPreview, isDisabled }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useCustomToast();

  const handleDownloadData = useCallback(async () => {
    try {
      setIsDownloading(true);
      const props = {
        method: "post",
        url: `/private/invoices/${invoice._id}/bank-slip`,
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
        params: {},
      };
      if (isPreview) props.params.mode = "preview";
      if (charge) props.params.charge = charge._id;
      const response = await api(props);
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (arrayBufferError) {
      const error = JSON.parse(new TextDecoder().decode(arrayBufferError));
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  }, [invoice._id, charge, isPreview]);

  return (
    <MenuItem isDisabled={isDisabled} onClick={handleDownloadData}>
      <HStack>
        <Icon as={BsFileEarmarkPdf} />
        <Text>{isPreview && "pré-"}visualizar boleto</Text>
      </HStack>
      <Modal isOpen={isDownloading} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody as={VStack} spacing={6} p="40px">
            <Spinner size="lg" />
            <Box textAlign="center">
              <Text fontWeight="semibold">Gerando visualização do boleto</Text>
              <Text fontSize="xs">Por favor aguarde enquanto a visualização do boleto é gerada.</Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </MenuItem>
  );
};
