import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { HStack, Icon, IconButton, Text, VStack } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { MdRefresh } from "react-icons/md";
import CustomersDetailsContext from "../context";
import GroupList from "./groupList";

export const FilesContext = createContext();

const Files = () => {
  const { _id } = useParams();
  const { formData, setCounters, setIsLoading, setIsInvalid, legalRepresentatives } = useContext(CustomersDetailsContext);
  const [files, isLoadingFiles, refreshFiles] = useFetchData(
    useMemo(() => {
      return {
        path: `/private/files`,
        params: {
          query: { owner: _id, group: { $ne: "cemig" } },
          perPage: -1,
          sort: { title: "asc" },
        },
        options: { isEnabled: _.isString(_id) },
      };
    }, [_id])
  );
  const grouped = useMemo(() => _.groupBy(files?.data, "group"), [files?.data]);
  const withPowerOfAttorney = useMemo(
    () => _.findIndex(legalRepresentatives?.data, (o) => o.isRepresentedByAttorney) !== -1,
    [legalRepresentatives?.data]
  );
  const [isInvalidGroup, setIsInvalidGroup] = useState({});

  useEffect(() => {
    setCounters((state) => ({ ...state, files: files?.size }));
  }, [files?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, files: isLoadingFiles }));
  }, [isLoadingFiles]);

  useEffect(() => {
    if (_id && files !== undefined) {
      const isInvalidGroup = {
        social_contract: formData.type === "pj" && _.size(grouped.social_contract) == 0,
        energy_account: _.size(grouped.energy_account) == 0,
        cnh: _.size(grouped.cnh) == 0 && (_.size(grouped.rg_front) == 0 || _.size(grouped.rg_back) == 0),
        rg_front: _.size(grouped.cnh) == 0 && _.size(grouped.rg_front) == 0,
        rg_back: _.size(grouped.cnh) == 0 && _.size(grouped.rg_back) == 0,
      };
      if (withPowerOfAttorney)
        isInvalidGroup.power_of_attorney = _.size(grouped.power_of_attorney) == 0 && _.size(grouped.power_of_attorney) == 0;
      setIsInvalidGroup(isInvalidGroup);
    } else setIsInvalidGroup({});
  }, [_id, files, formData.type, grouped, withPowerOfAttorney]);

  useEffect(() => {
    setIsInvalid((state) => ({ ...state, files: Object.values(isInvalidGroup).includes(true) }));
  }, [isInvalidGroup]);

  return (
    <FilesContext.Provider value={{ refreshFiles }}>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {_.size(files?.data)} arquivos adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingFiles} onClick={refreshFiles} />
      </HStack>

      <VStack align="stretch">
        {formData.type === "pj" && (
          <GroupList
            group="social_contract"
            title="Contrato social"
            description="Última alteração contratual ou ata do síndico em caso de condomínio."
            data={grouped.social_contract}
            isInvalid={isInvalidGroup.social_contract}
          />
        )}
        <GroupList
          group="energy_account"
          title="Conta de energia"
          description="Última conta de energia."
          data={grouped.energy_account}
          isInvalid={isInvalidGroup.energy_account}
        />
        <GroupList
          group="cnh"
          title="CNH"
          description="CNH do titular legal. Caso seja o RG tenha sido fornecido esta informação não é obrigatória."
          data={grouped.cnh}
          isInvalid={isInvalidGroup.cnh}
        />
        <GroupList
          group="rg_front"
          title="RG frente"
          description="RG frente do titular legal. Caso seja a CNH tenha sido fornecida esta informação não é obrigatória."
          data={grouped.rg_front}
          isInvalid={isInvalidGroup.rg_front}
        />
        <GroupList
          group="rg_back"
          title="RG verso"
          description="RG verso do titular legal. Caso seja a CNH tenha sido fornecida esta informação não é obrigatória."
          data={grouped.rg_back}
          isInvalid={isInvalidGroup.rg_back}
        />
        {withPowerOfAttorney && (
          <GroupList
            group="power_of_attorney"
            title="Procurações"
            description="Procurações dos representantes legais."
            data={grouped.power_of_attorney}
            isInvalid={isInvalidGroup.power_of_attorney}
          />
        )}
        <GroupList
          group="undefined"
          title="Não categorizados"
          description="Documentos de propósitos diversos que não se encaixam nas categorias acima."
          data={grouped.undefined}
          isInvalid={isInvalidGroup.undefined}
        />
      </VStack>
    </FilesContext.Provider>
  );
};

export default Files;
