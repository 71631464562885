import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { HStack, Icon, IconButton, Text, VStack } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";
import GroupList from "./groupList";
import LeadsDetailsContext from "../context";

const Files = () => {
  const { _id } = useParams();
  const { formData, files, isLoadingFiles, refreshFiles, setIsSomeFileGroupInvalid } = useContext(LeadsDetailsContext);
  const grouped = useMemo(() => _.groupBy(files?.data, "group"), [files?.data]);
  const [isInvalid, setIsInvalid] = useState({});

  useEffect(() => {
    if (_id)
      setIsInvalid({
        social_contract: formData.type === "pj" && _.size(grouped.social_contract) == 0,
        energy_account: _.size(grouped.energy_account) == 0,
        cnh: _.size(grouped.cnh) == 0 && (_.size(grouped.rg_front) == 0 || _.size(grouped.rg_back) == 0),
        rg_front: _.size(grouped.cnh) == 0 && _.size(grouped.rg_front) == 0,
        rg_back: _.size(grouped.cnh) == 0 && _.size(grouped.rg_back) == 0,
      });
    else setIsInvalid({});
  }, [_id, formData.type, grouped]);

  useEffect(() => {
    setIsSomeFileGroupInvalid(Object.values(isInvalid).includes(true));
  }, [isInvalid, setIsSomeFileGroupInvalid]);

  return (
    <>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {_.size(files?.data)} arquivos adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingFiles} onClick={refreshFiles} />
      </HStack>

      <VStack align="stretch">
        {formData.type === "pj" && (
          <GroupList
            group="social_contract"
            title="Contrato social"
            description="Última alteração contratual ou ata do síndico em caso de condomínio."
            data={grouped.social_contract}
            isInvalid={isInvalid.social_contract}
          />
        )}
        <GroupList
          group="energy_account"
          title="Conta de energia"
          description="Última conta de energia."
          data={grouped.energy_account}
          isInvalid={isInvalid.energy_account}
        />
        <GroupList
          group="cnh"
          title="CNH"
          description="CNH do titular legal. Caso seja o RG tenha sido fornecido esta informação não é obrigatória."
          data={grouped.cnh}
          isInvalid={isInvalid.cnh}
        />
        <GroupList
          group="rg_front"
          title="RG frente"
          description="RG frente do titular legal. Caso seja a CNH tenha sido fornecida esta informação não é obrigatória."
          data={grouped.rg_front}
          isInvalid={isInvalid.rg_front}
        />
        <GroupList
          group="rg_back"
          title="RG verso"
          description="RG verso do titular legal. Caso seja a CNH tenha sido fornecida esta informação não é obrigatória."
          data={grouped.rg_back}
          isInvalid={isInvalid.rg_back}
        />
        <GroupList
          group="undefined"
          title="Não categorizados"
          description="Documentos de propósitos diversos que não se encaixam nas categorias acima."
          data={grouped.undefined}
          isInvalid={isInvalid.undefined}
        />
      </VStack>
    </>
  );
};

export default Files;
