import React, { memo, useCallback, useMemo, useState } from "react";
import { Image, Spinner, Center, Icon, Box, Modal, ModalOverlay, ModalContent, IconButton, HStack, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { BsFile } from "react-icons/bs";
import icons from "./icons";
import { TbMinus, TbPlus, TbX, TbZoomIn, TbZoomOut } from "react-icons/tb";

export const FileImage = memo(({ _id, title, type, location, ext, ...props }) => {
  const [imageSrc, setImageSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [imageOffsetWidth, setImageOffsetWidth] = useState();
  const [zoom, setZoom] = useState(1);
  const [maxZoomIncrement, setMaxZoomIncrement] = useState(0);
  const imageWidth = useMemo(() => (imageOffsetWidth ? imageOffsetWidth * zoom : "50%"), [imageOffsetWidth, zoom]);
  const toast = useCustomToast();

  const handleOpen = useCallback(async () => {
    try {
      setIsLoading(true);
      if (/^image/i.test(type)) {
        const data = await api({
          method: "post",
          url: `/private/files/${_id}/object`,
          responseType: "blob",
        });
        setImageSrc(URL.createObjectURL(data));
      } else {
        const response = await api({
          method: "post",
          url: `/private/files/${_id}/object`,
          responseType: "arraybuffer",
          headers: { Accept: type },
        });
        const file = new Blob([response], { type });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoading(false);
    }
  }, [_id, type]);

  const handleOnClose = useCallback(() => {
    setImageSrc();
    setImageOffsetWidth();
    setZoom(1);
  }, []);

  const handleImageLoad = useCallback(({ target }) => {
    setImageOffsetWidth(target.offsetWidth);
    setMaxZoomIncrement(_.round(window.innerWidth / target.offsetWidth));
  }, []);

  if (/^image/i.test(type))
    return (
      <>
        <Box position="relative" overflow="hidden" borderRadius="md">
          <Image
            src={location}
            objectFit="cover"
            alt={title}
            fallback={
              <Center py={6}>
                <Spinner size="sm" />
              </Center>
            }
            onClick={handleOpen}
            cursor="pointer"
            {...props}
          />
          {isLoading && (
            <Center w="100%" h="100%" top="0" position="absolute" bg="blackAlpha.500" zIndex="1">
              <Spinner color="white" />
            </Center>
          )}
        </Box>
        <Modal isOpen={imageSrc} size="full" isCentered scrollBehavior="inside" onClose={handleOnClose}>
          <ModalOverlay />
          <ModalContent as={VStack} backgroundColor="transparent" overflow="auto">
            <IconButton
              position="fixed"
              top="5px"
              right="20px"
              colorScheme="blackAlpha"
              icon={<Icon as={TbX} boxSize="20px" />}
              onClick={handleOnClose}
            />
            <Image src={imageSrc} alt={title} w={imageWidth} borderRadius="md" onLoad={handleImageLoad} />
            <HStack position="fixed" bottom="10px" p="5px" backgroundColor="blackAlpha.500" borderRadius="lg">
              <IconButton
                colorScheme="blackAlpha"
                icon={<Icon as={TbMinus} boxSize="20px" />}
                isDisabled={zoom <= 1}
                onClick={() => setZoom((state) => state - 0.2)}
              />
              <IconButton
                colorScheme="blackAlpha"
                icon={<Icon as={TbZoomOut} boxSize="20px" />}
                isDisabled={zoom <= 1}
                onClick={() => setZoom(1)}
              />
              <IconButton
                colorScheme="blackAlpha"
                icon={<Icon as={TbZoomIn} boxSize="20px" />}
                isDisabled={zoom >= maxZoomIncrement}
                onClick={() => setZoom(maxZoomIncrement)}
              />
              <IconButton
                colorScheme="blackAlpha"
                icon={<Icon as={TbPlus} boxSize="20px" />}
                isDisabled={zoom >= maxZoomIncrement}
                onClick={() => setZoom((state) => state + 0.2)}
              />
            </HStack>
          </ModalContent>
        </Modal>
      </>
    );
  return (
    <Box position="relative">
      {isLoading && (
        <Center position="absolute" w="100%" h="80%">
          <Spinner size="sm" />
        </Center>
      )}
      <Icon as={icons[ext] ?? BsFile} onClick={handleOpen} cursor="pointer" {...props} />
    </Box>
  );
});
