import React, { useState, useEffect, Fragment, useCallback } from "react";
import _ from "lodash";
import {
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdOutlineClose, MdOutlineModeEdit, MdOutlineSearch } from "react-icons/md";

export const ModalSelect = ({
  selected,
  onChange,
  isClearable,
  defaultOptions: isAllowedDefaultOptions = true,
  loadOptions,
  getOptionValue,
  formatOptionLabel,
  isDisabled = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setSearchText("");
  }, [isOpen]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [isOpen, defaultOptions]);

  const fetchData = useCallback(
    async (searchText = "") => {
      try {
        setIsLoading(true);
        const response = await loadOptions(searchText);
        return response;
      } finally {
        setIsLoading(false);
      }
    },
    [loadOptions]
  );

  useEffect(() => {
    if (isAllowedDefaultOptions) {
      const timeout = setTimeout(async () => setDefaultOptions(await fetchData()), 1000);
      return () => clearTimeout(timeout);
    }
  }, [isAllowedDefaultOptions, fetchData]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const [input] = e.target;
      const options = await fetchData(input.value);
      setOptions(options);
    },
    [searchText, fetchData]
  );

  const handleChange = useCallback(
    (item) => {
      onChange(item);
      onClose();
    },
    [onChange]
  );

  return (
    <Fragment>
      <HStack>
        <IconButton size="sm" icon={<Icon as={MdOutlineModeEdit} />} onClick={onOpen} isDisabled={isDisabled} />
        {isClearable && selected && (
          <IconButton size="sm" icon={<Icon as={MdOutlineClose} />} onClick={() => handleChange(null)} isDisabled={false} />
        )}
      </HStack>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box flex="1">
              <form onSubmit={handleSubmit}>
                <InputGroup>
                  <Input
                    variant="filled"
                    placeholder="Pesquisar..."
                    value={searchText}
                    onChange={({ target }) => setSearchText(target.value)}
                  />
                  <InputRightElement>
                    <IconButton type="submit" size="sm" icon={<Icon as={MdOutlineSearch} />} isLoading={isLoading} />
                  </InputRightElement>
                </InputGroup>
              </form>
            </Box>
            <IconButton variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody p="10px">
            <VStack alignItems="stretch">
              {_.map(options, (item, index) => (
                <Box
                  key={getOptionValue(item)}
                  p="20px"
                  borderWidth="1px"
                  borderRadius="lg"
                  _hover={{ cursor: "pointer", _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}
                  onClick={() => handleChange(item)}
                >
                  {formatOptionLabel(item, index)}
                </Box>
              ))}
            </VStack>
            {_.size(options) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={MdOutlineSearch} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum registro encontrado
                  </Text>
                  <Text fontSize="sm">Não foram encontrados registros reference à busca informada.</Text>
                </Box>
              </Center>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
