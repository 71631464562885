import { useContext, useMemo } from "react";
import _ from "lodash";
import { PrivateContext } from "pages/Private";

export const usePermissioned = (required) => {
  const { currentUser } = useContext(PrivateContext);
  return useMemo(() => {
    if (_.isString(required)) {
      if (required.charAt(required.length - 1) === "*") {
        const replaced = required.replace("*", "");
        const permissions = currentUser.userGroup.permissions.filter((v) => v.includes(replaced)).map((v) => v.replace(replaced, ""));
        const response = {};
        for (const permission of permissions) response[permission] = true;
        return response;
      }
      return currentUser.userGroup.permissions.findIndex((permission) => permission === required) !== -1;
    }
    if (_.isArray(required)) {
      for (const item of required) {
        const index = currentUser.userGroup.permissions.findIndex((permission) => permission === item);
        if (index !== -1) return true;
      }
    }
    return false;
  }, [required, currentUser.userGroup.permissions]);
};
