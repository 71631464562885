import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { currency, masks, translator } from "lib";
import { StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="payments" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "customer.name",
    title: "Nome/Pagador",
    isVisible: true,
    isSortable: false,
    render: ({ customer }) => customer?.name || "-",
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return customer?.name || "-";
      return "****";
    },
  },
  {
    accessor: "customer.document",
    title: "Documento/Pagador",
    isVisible: false,
    isSortable: false,
    render: ({ customer }, { isAllowedReadSensitiveData }) => {
      if (isAllowedReadSensitiveData.personalData) return masks.document(customer?.document || "-");
      return "****";
    },
    exporter: ({ customer }, { isAllowedExportSensitiveData }) => {
      if (isAllowedExportSensitiveData.personalData) return `'${customer?.document || "-"}`;
      return "****";
    },
  },
  {
    accessor: "charge.nid",
    title: "NID/Cobrança",
    isVisible: true,
    isSortable: false,
    render: ({ charge }, { setQueryParams }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        onClick={() => setQueryParams((params) => ({ ...params, charge_id: charge._id }))}
      >
        <HStack>
          <StatusBadge schema="charges" status={charge.status} />
          <Text>#{charge.nid}</Text>
        </HStack>
      </Button>
    ),
    exporter: ({ charge }) => charge.nid,
  },
  {
    accessor: "charge.ourNumber",
    title: "Nosso número/Cobrança",
    isVisible: true,
    isSortable: false,
    formatter: ({ charge }) => charge.ourNumber,
  },
  {
    accessor: "charge.referenceNumber",
    title: "N° documento/Cobrança",
    isVisible: true,
    isSortable: false,
    formatter: ({ charge }) => charge.referenceNumber,
  },
  {
    accessor: "charge.amount",
    title: "Val. boleto Click",
    isVisible: true,
    isSortable: false,
    render: ({ charge }) => currency(charge.amount),
    exporter: ({ charge }) => currency(charge.amount, { compact: true }),
    renderFoot: ({ chargeAmount }) => currency(chargeAmount),
  },
  {
    accessor: "charge.amountPaid",
    title: "Valor pago",
    isVisible: true,
    isSortable: false,
    render: ({ charge }) => currency(charge.amountPaid),
    exporter: ({ charge }) => currency(charge.amountPaid, { compact: true }),
    renderFoot: ({ chargeAmountPaid }) => currency(chargeAmountPaid),
  },
  {
    accessor: "invoice.distributorCreditAmount",
    title: "Créd. CEMIG",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) => currency(invoice.distributorCreditAmount),
    exporter: ({ invoice }) => currency(invoice.distributorCreditAmount, { compact: true }),
  },
  {
    accessor: "invoice.traderCreditAmount",
    title: "Créd. Click",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) => currency(invoice.traderCreditAmount),
    exporter: ({ invoice }) => currency(invoice.traderCreditAmount, { compact: true }),
  },
  {
    accessor: "invoice.distributor.current.baseAmount",
    title: "Valor CEMIG",
    isVisible: true,
    isSortable: false,
    render: ({ invoice }) => currency(invoice.distributor.current.baseAmount),
    exporter: ({ invoice }) => currency(invoice.distributor.current.baseAmount, { compact: true }),
  },
  {
    accessor: "charge.paidAt",
    title: "Dt. pgto Click",
    isVisible: true,
    isSortable: false,
    formatter: ({ charge }) => moment(charge.paidAt).format("DD/MM/YYYY"),
  },
  {
    accessor: "invoice.distributor.current.dueDate",
    title: "Dt. venc. CEMIG",
    isVisible: true,
    isSortable: false,
    formatter: ({ invoice }) => moment(invoice.distributor.current.dueDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "updatedAt",
    title: "Dt. do último evento",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm"),
  },
  {
    accessor: "scheduledDate",
    title: "Dt. do agendamento",
    isVisible: true,
    formatter: ({ scheduledDate }) => (scheduledDate ? moment(scheduledDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "amount",
    title: "Valor agendamento",
    isVisible: true,
    render: ({ amount }) => currency(amount),
    exporter: ({ amount }) => currency(amount, { compact: true }),
    renderFoot: ({ amount }) => currency(amount),
  },
  {
    accessor: "invoice.nid",
    title: "NID/Fatura",
    isVisible: true,
    render: ({ invoice }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        as={RouterLink}
        to={`/invoices/details/${invoice._id}`}
        target="_blank"
      >
        <HStack>
          <StatusBadge schema="invoices" status={invoice.status} />
          <Text>#{invoice.nid}</Text>
        </HStack>
      </Button>
    ),
    exporter: ({ invoice }) => invoice.nid,
  },
  {
    accessor: "approval.nid",
    title: "NID/Aprovação",
    isVisible: true,
    render: ({ approval }, { setQueryParams }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        onClick={() => setQueryParams((params) => ({ ...params, approval_id: approval._id }))}
      >
        <HStack>
          <StatusBadge schema="approvals" status={approval.status} />
          <Text>#{approval.nid}</Text>
        </HStack>
      </Button>
    ),
    exporter: ({ approval }) => approval.nid,
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
