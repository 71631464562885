import React, { createContext, useContext, useEffect, useState } from "react";
import { Flex, Box, useDisclosure, useColorModeValue, useToken } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppContext } from "AppProvider";
import Sidebar from "./sidebar";
import Toolbar from "./toolbar";

export const ContainerContext = createContext();

export const Content = ({ children }) => {
  return (
    <Box flex="1" p="20px" w="100%" overflow="auto">
      {children}
    </Box>
  );
};

export const ContentHeader = ({ children, ...props }) => {
  return (
    <Box position="sticky" left="0" zIndex="1" {...props}>
      {children}
    </Box>
  );
};

export const ContentBody = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};

const Container = () => {
  const { isMobileView } = useContext(AppContext);
  const { isOpen: isOpenSidebar, onOpen: onOpenSidebar, onClose: onCloseSidebar } = useDisclosure();
  const [innerHeight, setInnerHeight] = useState("100vh");
  const [themeColor] = useToken("colors", [useColorModeValue("gray.50", "gray.900")]);

  useEffect(() => {
    const onInnerHeight = () => setInnerHeight(`${window.innerHeight}px`);
    onInnerHeight();
    window.addEventListener("resize", onInnerHeight);
    window.addEventListener("orientationchange", onInnerHeight);
    return () => {
      window.removeEventListener("resize", onInnerHeight);
      window.removeEventListener("orientationchange", onInnerHeight);
    };
  }, []);

  useEffect(() => {
    document.querySelector("meta[name='theme-color']").setAttribute("content", themeColor);
  }, [themeColor]);

  return (
    <ContainerContext.Provider value={{ isMobileView, isOpenSidebar, onOpenSidebar, onCloseSidebar }}>
      <Flex direction="column" w="100vw" h={innerHeight} _light={{ bg: "white" }} _dark={{ bg: "gray.800" }}>
        <Toolbar />
        <Flex flex="1" overflow="hidden">
          <Sidebar />
          <Flex flex="1" direction="column" overflow="hidden">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default Container;
