import React, { memo, useCallback, useState, useEffect } from "react";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useCustomToast } from "hooks";
import { messages, firebaseAuthErrors } from "consts";
import { MdClose } from "react-icons/md";

const PasswordChange = memo(({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({});
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async ({ currentPassword, newPassword }) => {
      try {
        setIsLoadingSaveData(true);
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(auth.currentUser, newPassword);
        toast({ description: messages.success.updatePassword, status: "success", isClosable: true });
        onClose();
      } catch (error) {
        if (error.isHandled) return;
        const description = firebaseAuthErrors[error.code] ?? error.message;
        toast({ description, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        currentPassword: yup.string().required(messages.error.required),
        newPassword: yup.string().required(messages.error.required),
        newPasswordConfirm: yup.string().oneOf([yup.ref("newPassword"), null], messages.error.passwordsMustMatch),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Alterar senha</Text>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired={true} isInvalid={formErrors.currentPassword}>
              <FormLabel fontSize="sm">Senha atual</FormLabel>
              <Input
                type="password"
                value={formData.currentPassword ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, currentPassword: target.value }))}
              />
              <FormErrorMessage>{formErrors.currentPassword}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.newPassword}>
              <FormLabel fontSize="sm">Nova senha</FormLabel>
              <Input
                type="password"
                value={formData.newPassword ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, newPassword: target.value }))}
              />
              <FormErrorMessage>{formErrors.newPassword}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired={true} isInvalid={formErrors.newPasswordConfirm}>
              <FormLabel fontSize="sm">Confirme a nova senha</FormLabel>
              <Input
                type="password"
                value={formData.newPasswordConfirm ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, newPasswordConfirm: target.value }))}
              />
              <FormErrorMessage>{formErrors.newPasswordConfirm}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter as={HStack} justifyContent="flex-end">
          <Button onClick={onClose}>cancelar</Button>
          <Button colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default PasswordChange;
