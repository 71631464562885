import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import _ from "lodash";
import ObjectID from "bson-objectid";
import fileDownload from "js-file-download";
import { Parser } from "@json2csv/plainjs";
import { SyncSelect } from "components";
import { useConfirmDialog, useCustomToast, useFetchData } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdClose, MdRefresh, MdSave } from "react-icons/md";
import ConsumerUnitsStatusesContext from "./context";
import useSubstatusMessageConfirmDialog from "pages/Private/Customers/details/consumerUnits/useSubstatusMessageConfirmDialog";
import { TbExternalLink, TbFileExport } from "react-icons/tb";

const UploadConfirm = ({ file, consumerUnits, setConsumerUnits }) => {
  const { refreshData } = useContext(ConsumerUnitsStatusesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [statuses, isLoadingStatuses, refreshStatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", parent: { $exists: false }, isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
          withChildren: true,
        },
      }),
      []
    )
  );
  const isOpen = useMemo(() => _.size(consumerUnits) >= 1, [consumerUnits]);
  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  const [substatusMessageConfirmAction, SubstatusMessageConfirmDialog] = useSubstatusMessageConfirmDialog();
  const toast = useCustomToast();
  const [errs, setErrs] = useState([]);

  useEffect(() => {
    setFormData({});
    setFormErrors({});
  }, [isOpen]);

  const handleClose = useCallback(() => setConsumerUnits(), []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const response = await api.upload("/private/imports/consumer-units-statuses/create", [file], data);
        if (response.errs) setErrs(response.errs);
        else {
          toast({ description: messages.success.saveData, status: "success", isClosable: true });
          refreshData();
        }
        handleClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [file, handleClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        status: yup.string().required(messages.error.required),
        substatus: yup.string().required(messages.error.required),
      });
      const data = {
        _id: ObjectID().toHexString(),
        status: formData.status?._id,
        substatus: formData.substatus?._id,
        consumerUnits,
      };

      await schema.validate(data, { abortEarly: false });
      const isConfirmed = await confirmAction();
      if (isConfirmed) {
        const result = await substatusMessageConfirmAction({
          statuses,
          params: {
            isVisibleOutgoingMessage: true,
            isVisibleIncomingMessage: true,
          },
        });
        if (result === "cancel") return;
        data._outgoingMessage = result?._outgoingMessage;
        data._incomingMessage = result?._incomingMessage;
        handleSaveData(data);
      }
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [statuses, formData, consumerUnits, handleSaveData]);

  const handleExportData = useCallback(() => {
    const parser = new Parser({
      fields: [
        { value: "installationNumber", label: "N° instalação" },
        { value: "currentSubstatus", label: "Substatus atual" },
      ],
      delimiter: ";",
      withBOM: true,
    });
    const csv = parser.parse(errs);
    fileDownload(csv, `ucs_com_problemas_${new Date().toISOString()}.csv`);
  }, [errs]);

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Confirmar importação</Text>
            <IconButton sze="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
          </ModalHeader>
          <ModalBody>
            <Text mb={4}>
              O arquivo selecionado possui {_.size(consumerUnits)} unidades consumidoras. Por favor, escolha o status e substatus abaixo
              para realizar a aplicação em massa.
            </Text>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.status}>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <HStack>
                    <SyncSelect
                      placeholder="Selecione"
                      options={statuses?.data || []}
                      value={formData.status || {}}
                      onChange={(status) => setFormData((state) => ({ ...state, status, substatus: null }))}
                      getOptionValue={({ _id }) => _id}
                      formatOptionLabel={({ colorScheme, title, ordination }) => (
                        <HStack>
                          <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                          <Text>
                            {ordination} - {title}
                          </Text>
                        </HStack>
                      )}
                    />
                    <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingStatuses} onClick={refreshStatuses} />
                  </HStack>
                  <FormErrorMessage>{formErrors.status}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.substatus}>
                  <FormLabel fontSize="sm">Substatus</FormLabel>
                  <SyncSelect
                    placeholder="Selecione"
                    options={formData.status?.children}
                    value={formData.substatus || {}}
                    onChange={(substatus) => setFormData((state) => ({ ...state, substatus }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ colorScheme, title, ordination }) => (
                      <HStack>
                        <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>
                          {ordination} - {title}
                        </Text>
                      </HStack>
                    )}
                  />
                  <FormErrorMessage>{formErrors.substatus}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={handleClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSave} />} onClick={handleSubmit} isLoading={isLoadingSaveData}>
              confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmDialog
        title="Atenção"
        description="Deseja realmente realizar a aplicação em massa do status e substatus selecionado?"
        buttons={[
          { value: false, text: "cancelar" },
          { value: true, text: "aplicar", colorScheme: "red" },
        ]}
      />

      <SubstatusMessageConfirmDialog />

      <Modal isOpen={_.size(errs) >= 1} onClose={() => setErrs([])} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box>
              <Text>UCs com problemas</Text>
              <Text fontSize="sm" fontWeight="light">
                Existem UCs cujo substatus atual não corresponde ao substatus inserido no arquivo de importação. Por favor, verifique a
                informação.
              </Text>
            </Box>
            <Tooltip label="Exportar lista">
              <IconButton size="sm" variant="outline" icon={<Icon as={TbFileExport} />} onClick={handleExportData} />
            </Tooltip>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={() => setErrs([])} />
          </ModalHeader>
          <ModalBody>
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th>N° instalação</Th>
                  <Th>Substatus atual</Th>
                </Tr>
              </Thead>
              <Tbody>
                {_.map(errs, (item) => (
                  <Tr key={item._id}>
                    <Td>
                      <HStack>
                        <Text>{item.installationNumber}</Text>
                        <IconButton
                          size="xs"
                          variant="outline"
                          icon={<Icon as={TbExternalLink} />}
                          as={RouterLink}
                          to={`/customers/edit/${item.customer}#consumer-units`}
                          target="_blank"
                        />
                      </HStack>
                    </Td>
                    <Td>{item.currentSubstatus}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={() => setErrs([])}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default UploadConfirm;
