import _ from "lodash";

export const setOneOrMany = (key, value, response, formatter) => {
  if (value?.length) {
    const values = value.split(",").map((v) => {
      if (formatter) return formatter(v.trim());
      return v.trim();
    });
    if (values.length > 1) {
      _.set(response, key.concat(".$in"), values);
    } else {
      const [_value] = values;
      _.set(response, key, _value);
    }
  }
};
