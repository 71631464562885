import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import _ from "lodash";
import ObjectID from "bson-objectid";
import { useCustomToast, useFetchData } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { SyncSelect } from "components";
import { MdClose, MdRefresh, MdSave } from "react-icons/md";
import ConsumerUnitsQuotasContext from "./context";
import moment from "moment";

const UploadConfirm = ({ file, consumerUnits, setConsumerUnits }) => {
  const { refreshData } = useContext(ConsumerUnitsQuotasContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isOpen = useMemo(() => _.size(consumerUnits) >= 1, [consumerUnits]);
  const [powerPlants, isLoadingPowerPlants, refreshPowerPlants] = useFetchData(useMemo(() => ({ path: "/private/power-plants" }), []));
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({});
  }, [isOpen]);

  const handleClose = useCallback(() => setConsumerUnits(), []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.upload("/private/imports/consumer-units-quotas/create", [file], data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        handleClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [file, handleClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        _id: ObjectID().toHexString(),
        powerPlant: formData.powerPlant?._id,
        anchorInstallationNumber: formData.anchorInstallationNumber,
        sentAt: moment(formData.sentAt, "DD/MM/YYYY").toDate(),
        consumerUnits,
      };
      const schema = yup.object().shape({
        powerPlant: yup.string().required(messages.error.required),
        anchorInstallationNumber: yup.string().isValidCemigNumber(3).required(messages.error.required),
        sentAt: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, consumerUnits, handleSaveData]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Confirmar importação</Text>
          <IconButton sze="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
        </ModalHeader>
        <ModalBody>
          <Text mb={4}>
            O arquivo selecionado possui {_.size(consumerUnits)} unidades consumidoras. Deseja realmente realizar a importação?
          </Text>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.powerPlant}>
                <FormLabel fontSize="sm">Usina</FormLabel>
                <HStack>
                  <SyncSelect
                    placeholder="Selecione"
                    options={powerPlants?.data || []}
                    value={formData.powerPlant || {}}
                    onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                  />
                  <IconButton
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingPowerPlants}
                    onClick={refreshPowerPlants}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.powerPlant}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.anchorInstallationNumber}>
                <FormLabel fontSize="sm">N° instalação da âncora</FormLabel>
                <Input
                  value={formData.anchorInstallationNumber || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, anchorInstallationNumber: target.value }))}
                />
                <FormErrorMessage>{formErrors.anchorInstallationNumber}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.sentAt}>
                <FormLabel fontSize="sm">Data de envio</FormLabel>
                <Input
                  as={InputMask}
                  mask="99/99/9999"
                  value={formData.sentAt || ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, sentAt: target.value }))}
                />
                <FormErrorMessage>{formErrors.sentAt}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={handleClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSave} />} onClick={handleSubmit} isLoading={isLoadingSaveData}>
            confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadConfirm;
