import moment from "moment";

const defaultColumns = [
  {
    accessor: "powerPlant.name",
    title: "Usina",
    isVisible: true,
    formatter: ({ powerPlant }) => powerPlant?.name || "-",
  },
  {
    accessor: "referenceDate",
    title: "Referência",
    isVisible: true,
    formatter: ({ referenceDate }) => moment(referenceDate).format("MM/YYYY"),
  },
  {
    accessor: "compensatedOffPeakPowerQttInKwh",
    title: "Energia compensada HFP",
    isVisible: true,
    formatter: ({ compensatedOffPeakPowerQttInKwh }) => compensatedOffPeakPowerQttInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "compensatedOnPeakPowerQttInKwh",
    title: "Energia compensada HP",
    isVisible: true,
    formatter: ({ compensatedOnPeakPowerQttInKwh }) => compensatedOnPeakPowerQttInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "injectedOffPeakPowerQttInKwh",
    title: "Energia injetada HFP",
    isVisible: true,
    formatter: ({ injectedOffPeakPowerQttInKwh }) => injectedOffPeakPowerQttInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "injectedOnPeakPowerQttInKwh",
    title: "Energia injetada HP",
    isVisible: true,
    formatter: ({ injectedOnPeakPowerQttInKwh }) => injectedOnPeakPowerQttInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "transferredPowerQttInKwh",
    title: "Energia transferida",
    isVisible: true,
    formatter: ({ transferredPowerQttInKwh }) => transferredPowerQttInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "currentGenerationOffPeakBalanceInKwh",
    title: "Saldo atual HFP",
    isVisible: true,
    formatter: ({ currentGenerationOffPeakBalanceInKwh }) => currentGenerationOffPeakBalanceInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "currentGenerationOnPeakBalanceInKwh",
    title: "Saldo atual HP",
    isVisible: true,
    formatter: ({ currentGenerationOnPeakBalanceInKwh }) => currentGenerationOnPeakBalanceInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "currentGenerationBalanceInKwh",
    title: "Saldo atual total",
    isVisible: true,
    formatter: ({ currentGenerationBalanceInKwh }) => currentGenerationBalanceInKwh.toLocaleString().concat("kWh"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
