import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import { HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { MessagesList } from "containers";
import { MdRefresh } from "react-icons/md";
import ChargesDetailsContext from "./context";
import { EventEmitter } from "lib";
import { useFetchData } from "hooks";

const Messages = () => {
  const { _id, formData, setCounters, setIsLoading } = useContext(ChargesDetailsContext);
  const [messages, isLoadingMessages, refreshMessages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/messages",
        params: { query: { message: { charge: formData._id } }, sort: { createdAt: -1 }, isAutocomplete: true },
        options: { isEnabled: _.isString(formData._id) },
      }),
      [formData._id]
    )
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, messages: _.size(messages?.data) }));
  }, [messages?.data]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, messages: isLoadingMessages }));
  }, [isLoadingMessages]);

  useEffect(() => {
    const listener = EventEmitter.addListener("messages.refresh", refreshMessages);
    return () => listener.remove();
  }, [_id, refreshMessages]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(messages?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingMessages} onClick={refreshMessages} />
      </HStack>

      <MessagesList data={messages?.data} isLoading={isLoadingMessages} />
    </>
  );
};

export default Messages;
