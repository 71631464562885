import moment from "moment";
import { translator } from "lib";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="alerts" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "classification",
    title: "Classificação",
    isVisible: true,
    render: ({ classification }) => <StatusBadge schema="alertClassifications" status={classification} />,
    exporter: ({ classification }) => translator(classification),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    formatter: ({ title }) => title,
  },
  {
    accessor: "description",
    title: "Descrição",
    isVisible: true,
    formatter: ({ description }) => description,
  },
  {
    accessor: "solvedBy",
    title: "Solucionado por",
    isVisible: true,
    formatter: ({ solvedBy }) => solvedBy?.name || "-",
  },
  {
    accessor: "solvedAt",
    title: "Solucionado em",
    isVisible: true,
    formatter: ({ solvedAt }) => (solvedAt ? moment(solvedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "archivedBy",
    title: "Arquivado por",
    isVisible: true,
    formatter: ({ archivedBy }) => archivedBy?.name || "-",
  },
  {
    accessor: "archivedAt",
    title: "Arquivado em",
    isVisible: true,
    formatter: ({ archivedAt }) => (archivedAt ? moment(archivedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "archivedComments",
    title: "Obs. do arquivamento",
    isVisible: false,
    formatter: ({ archivedComments }) => archivedComments || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
