import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Select,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { EventEmitter, api, currency, percent, yup } from "lib";
import { messages } from "consts";
import Context from "./context";
import { CheckboxBody, CheckboxHeader, CheckboxProvider } from "components";

export const MonetaryCorrections = ({ formData, setFormData, invoice, receivableAmount, onNextReceivableAmount }) => {
  const nextReceivableAmount = useMemo(() => {
    const sum = _(["penaltyAmount", "arrearsAmount", "monetaryCorrectionAmount"])
      .map((key) => formData[key] || 0)
      .sum();
    return receivableAmount + sum;
  }, [receivableAmount, formData]);
  const options = useMemo(() => {
    return [
      {
        _id: "penalty",
        penaltyAmount: invoice.predictedPenaltyAmount,
      },
      {
        _id: "arrears",
        arrearsAmount: invoice.predictedArrearsAmount,
        arrearsPercentage: invoice.predictedArrearsPercentage,
      },
      {
        _id: "monetaryCorrection",
        monetaryCorrectionAmount: invoice.predictedMonetaryCorrectionAmount,
        monetaryCorrectionPercentage: invoice.predictedMonetaryCorrectionPercentage,
      },
    ];
  }, [invoice]);
  const [checkeds, setCheckeds] = useState([]);

  useEffect(() => {
    setCheckeds([...options]);
  }, [options]);

  useEffect(() => {
    onNextReceivableAmount?.(nextReceivableAmount);
  }, [nextReceivableAmount]);

  useEffect(() => {
    setFormData(({ dueDate, isUnified, installments }) => {
      let tmp = { dueDate, isUnified, installments };
      for (const { _id, ...rest } of checkeds) tmp = { ...tmp, ...rest };
      return tmp;
    });
  }, [checkeds]);

  return (
    <CheckboxProvider values={checkeds} onChange={setCheckeds}>
      <VStack w="100%" spacing={3} alignItems="stretch" divider={<StackDivider />}>
        <HStack>
          <CheckboxHeader />
          <Text fontSize="sm" flex="1" fontWeight="semibold">
            Tempo em atraso
          </Text>
          <Text fontSize="sm">{invoice.overdueDays.toLocaleString()} dias</Text>
        </HStack>
        <HStack>
          <CheckboxBody value={options[0]} />
          <HStack flex="1" spacing={1}>
            <Text fontSize="sm" fontWeight="semibold">
              Multa
            </Text>
            <Text fontSize="xs">(2%)</Text>
          </HStack>
          <Text fontSize="sm">{currency(invoice.predictedPenaltyAmount)}</Text>
        </HStack>
        <HStack>
          <CheckboxBody value={options[1]} />
          <HStack flex="1" spacing={1}>
            <Text fontSize="sm" fontWeight="semibold">
              Juros
            </Text>
            <Text fontSize="xs">({percent(invoice.predictedArrearsPercentage)} no período)</Text>
          </HStack>
          <Text fontSize="sm">{currency(invoice.predictedArrearsAmount)}</Text>
        </HStack>
        <HStack>
          <CheckboxBody value={options[2]} />
          <HStack flex="1" spacing={1}>
            <Text fontSize="sm" fontWeight="semibold">
              Correção IPCA
            </Text>
            <Text fontSize="xs">({percent(invoice.predictedMonetaryCorrectionPercentage)} no período)</Text>
          </HStack>
          <Text fontSize="sm">{currency(invoice.predictedMonetaryCorrectionAmount)}</Text>
        </HStack>
        <HStack spacing={4}>
          <Box flex="1">
            <Heading size="sm">Total</Heading>
          </Box>
          <Heading size="sm">{currency(nextReceivableAmount)}</Heading>
        </HStack>
        <Text fontSize="xs">As taxas e correções são aplicadas somente sobre o valor Click.</Text>
      </VStack>
    </CheckboxProvider>
  );
};

export const Dialog = () => {
  const { charge, isOpenUpdateDialog, onCloseUpdateDialog } = useContext(Context);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const receivableAmount = useMemo(() => {
    if (formData.isUnified === "yes")
      return (
        charge.invoice.amount +
        (charge.invoice.distributor?.current.amount || 0) -
        charge.invoice.distributorCreditAmount -
        charge.invoice.traderCreditAmount
      );
    return charge.invoice.amount - charge.invoice.traderCreditAmount;
  }, [formData.isUnified, charge.invoice]);

  const toast = useCustomToast();

  useEffect(() => {
    setFormData((state) => ({
      ...state,
      dueDate: moment(charge.dueDate).format("DD/MM/YYYY"),
      isUnified: charge.invoice.isUnified ? "yes" : "no",
    }));
    setFormErrors({});
  }, [isOpenUpdateDialog, charge]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/invoices/${charge.invoice._id}/charges`, data);
        toast({ description: messages.success.requestUpdate, status: "success", isClosable: true });
        EventEmitter.emit("charges.refresh");
        onCloseUpdateDialog();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [charge.invoice._id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        dueDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .min(moment().startOf("day").toDate(), messages.error.minDueDate),
        isUnified: yup.boolean().required(messages.error.required),
      });
      const data = {
        ...formData,
        dueDate: moment(formData.dueDate, "DD/MM/YYYY").endOf("day").toDate(),
        isUnified: formData.isUnified === "yes",
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <AlertDialog isOpen={isOpenUpdateDialog} onClose={onCloseUpdateDialog} scrollBehavior="inside" isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent onDoubleClick={(e) => e.stopPropagation()}>
          <AlertDialogHeader>
            <Heading size="sm">Atualizar cobrança</Heading>
            <Text fontSize="sm" fontWeight="light">
              Deseja realmente atualizar esta cobrança?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={2}>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.dueDate}>
                  <FormLabel fontSize="sm">Vencimento</FormLabel>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    value={formData.dueDate ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, dueDate: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.dueDate}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isUnified} isDisabled={charge.invoice.type === "manual"}>
                  <FormLabel fontSize="sm">Modalidade de fatura</FormLabel>
                  <Select
                    value={formData.isUnified ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, isUnified: target.value }))}
                  >
                    <option value="yes">Fatura unificada</option>
                    <option value="no">Fatura separada</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isUnified}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="10px" textAlign="center">
                <Text fontSize="xs">Valor CEMIG</Text>
                <Text fontSize="sm" fontWeight="semibold" textDecoration={formData.isUnified === "yes" ? "none" : "line-through"}>
                  {currency(charge.invoice.distributor?.current.amount || 0)}
                </Text>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="10px" textAlign="center">
                <Text fontSize="xs">Valor Click</Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {currency(charge.invoice.amount)}
                </Text>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }} borderWidth="1px" borderRadius="lg" p="10px" textAlign="center">
                <Text fontSize="xs">Valor total</Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {currency(receivableAmount)}
                </Text>
              </GridItem>
            </Grid>

            {charge.invoice.overdueDays >= 1 && (
              <Fragment>
                <Divider my={4} />
                <MonetaryCorrections {...{ formData, setFormData, receivableAmount, invoice: charge.invoice }} />
              </Fragment>
            )}
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseUpdateDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="yellow" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              atualizar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Dialog;
