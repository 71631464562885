import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { api, yup } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { MdAdd } from "react-icons/md";

const AddSegment = ({ isLoading, onChange, isDisabled }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ isActive: true });
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = await api.put("/private/segments", data);
        onChange(saved);
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [onChange, onClose, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="right">
      <PopoverTrigger>
        <Button rightIcon={<Icon as={MdAdd} />} isLoading={isLoading} isDisabled={isDisabled}>
          outro
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Adicionar outro Segmento</PopoverHeader>
        <PopoverBody>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel fontSize="sm">Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </PopoverBody>
        <PopoverFooter as={HStack} justifyContent="flex-end">
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
            salvar
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default AddSegment;
