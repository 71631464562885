import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, translator, setOneOrMany } from "lib";
import { SyncSelect, RangeDateInput, InputCurrency, RangeInput, AsyncSelect } from "components";
import { statuses } from "consts";
import { useCacheState, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadUsersTimeout = {};

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? {},
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          dueDateStart: data.dueDateStart && moment(data.dueDateStart).toDate(),
          dueDateEnd: data.dueDateEnd && moment(data.dueDateEnd).toDate(),
          validatedAtStart: data.validatedAtStart && moment(data.validatedAtStart).toDate(),
          validatedAtEnd: data.validatedAtEnd && moment(data.validatedAtEnd).toDate(),
          invalidatedAtStart: data.invalidatedAtStart && moment(data.invalidatedAtStart).toDate(),
          invalidatedAtEnd: data.invalidatedAtEnd && moment(data.invalidatedAtEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    setOneOrMany("nid", query.nid, response, (v) => parseInt(v));
    if (query.status?.length) _.set(response, "status.$in", _.map(query.status, "value"));
    if (query.amountStart) _.set(response, "amount.$gte", query.amountStart);
    if (query.amountEnd) _.set(response, "amount.$lte", query.amountEnd);
    if (query.dueDateStart) _.set(response, "dueDate.$gte", ["@ISODate", query.dueDateStart]);
    if (query.dueDateEnd) _.set(response, "dueDate.$lte", ["@ISODate", query.dueDateEnd]);
    if (query.validatedBy?.length) _.set(response, `["validatedBy._id"].$in`, toObjectId(query.validatedBy));
    if (query.invalidatedBy?.length) _.set(response, `["invalidatedBy._id"].$in`, toObjectId(query.invalidatedBy));
    if (query.validatedAtStart) _.set(response, "validatedAt.$gte", ["@ISODate", query.validatedAtStart]);
    if (query.validatedAtEnd) _.set(response, "validatedAt.$lte", ["@ISODate", query.validatedAtEnd]);
    if (query.invalidatedAtStart) _.set(response, "invalidatedAt.$gte", ["@ISODate", query.invalidatedAtStart]);
    if (query.invalidatedAtEnd) _.set(response, "invalidatedAt.$lte", ["@ISODate", query.invalidatedAtEnd]);
    if (query.createdAtStart) _.set(response, "createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "createdAt.$lte", ["@ISODate", query.createdAtEnd]);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.approvals}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.amountStart}
                  defaultEndValue={formData.amountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, amountStart: startValue, amountEnd: endValue }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de vencimento
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.dueDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.dueDateStart}
                    defaultEndDate={formData.dueDateEnd}
                    onChange={(dueDateStart, dueDateEnd) => setFormData((state) => ({ ...state, dueDateStart, dueDateEnd }))}
                  />
                  {formData.dueDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          dueDateStart: null,
                          dueDateEnd: null,
                          dueDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Validado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.validatedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("validatedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(validatedBy) => setFormData((state) => ({ ...state, validatedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Validado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.validatedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.validatedAtStart}
                    defaultEndDate={formData.validatedAtEnd}
                    onChange={(validatedAtStart, validatedAtEnd) =>
                      setFormData((state) => ({ ...state, validatedAtStart, validatedAtEnd }))
                    }
                  />
                  {formData.validatedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          validatedAtStart: null,
                          validatedAtEnd: null,
                          validatedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Invalidado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.invalidatedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("invalidatedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(invalidatedBy) => setFormData((state) => ({ ...state, invalidatedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Invalidado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.invalidatedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.invalidatedAtStart}
                    defaultEndDate={formData.invalidatedAtEnd}
                    onChange={(invalidatedAtStart, invalidatedAtEnd) =>
                      setFormData((state) => ({ ...state, invalidatedAtStart, invalidatedAtEnd }))
                    }
                  />
                  {formData.invalidatedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          invalidatedAtStart: null,
                          invalidatedAtEnd: null,
                          invalidatedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
