import React, { Fragment, createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useCustomToast, useFetchData } from "hooks";
import { api } from "lib";
import { messages } from "consts";
import { MdOutlineDelete, MdRefresh } from "react-icons/md";
import { FaRegComment } from "react-icons/fa";
import Details from "./details";
import CustomersDetailsContext from "../context";

export const CommentsContext = createContext();

const Comments = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading } = useContext(CustomersDetailsContext);
  const [comments, isLoadingComments, refreshComments] = useFetchData(
    useMemo(
      () => ({
        path: `/private/customers/${_id}/comments`,
        params: { sort: { createdAt: 1 }, perPage: -1 },
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );
  const [editSelected, setEditSelected] = useState();
  const [deleteSelected, setDeleteSelected] = useState();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setCounters((state) => ({ ...state, comments: comments?.size }));
  }, [comments?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, comments: isLoadingComments }));
  }, [isLoadingComments]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/private/customers/${_id}/comments/${deleteSelected._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshComments();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      setDeleteSelected();
    }
  }, [toast, deleteSelected?._id, refreshComments]);

  return (
    <CommentsContext.Provider value={{ editSelected, setEditSelected, refreshComments }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(comments?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingComments} onClick={refreshComments} />
        <Tooltip
          placement="left"
          isDisabled={_id}
          shouldWrapChildren
          label="Antes de adicionar observações você deve salvar o cliente atual."
        >
          <Button size="sm" colorScheme="main" isDisabled={!_id} onClick={() => setEditSelected({})}>
            incluir observação
          </Button>
        </Tooltip>
      </HStack>

      <VStack align="stretch" divider={<StackDivider />} spacing={6}>
        {_.map(comments?.data, (item) => (
          <Fragment key={item._id}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 4, lg: 6 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Criado por
                </Text>
                <Text fontSize="sm">{item.createdBy.name}</Text>
              </GridItem>
              <GridItem colSpan={{ base: 4, lg: 5 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Criado em
                </Text>
                <Text fontSize="sm">{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
              </GridItem>
              <GridItem colSpan={{ base: 4, lg: 1 }}>
                <Button size="sm" rightIcon={<Icon as={MdOutlineDelete} />} variant="outline" onClick={() => setDeleteSelected(item)}>
                  excluir
                </Button>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Observação
                </Text>
                <Text fontSize="sm">{item.content}</Text>
              </GridItem>
            </Grid>
          </Fragment>
        ))}
      </VStack>

      {isLoadingComments ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(comments?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={FaRegComment} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma observação adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui observações adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      <Details />

      <AlertDialog isOpen={deleteSelected} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir a observação selecionada?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => setDeleteSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" isLoading={isLoadingDeleteData} onClick={handleDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </CommentsContext.Provider>
  );
};

export default Comments;
