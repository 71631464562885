import moment from "moment";
import { HStack, Box, VStack, Text } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { translator, locale } from "lib";
import { useMemo } from "react";

const TextStatus = ({ status, value }) => {
  const [bg, label] = useMemo(() => {
    switch (status) {
      case "fulfilled":
        return ["green.500", "sucessos"];
      case "rejected":
        return ["red.500", "falhas"];
      default:
        return ["gray.300", "ignorados"];
    }
  });
  return (
    value >= 1 && (
      <HStack spacing={1}>
        <Box w="10px" h="10px" borderRadius="10px" bg={bg} />
        <Text fontSize="xs">
          <strong>{locale(value, { precision: 0 })}</strong> {label}
        </Text>
      </HStack>
    )
  );
};

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="invoiceCaptures" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    formatter: ({ title }) => title,
  },
  {
    accessor: "powerPlant",
    title: "Usina",
    isVisible: true,
    render: ({ powerPlant }) => powerPlant?.name || "-",
    exporter: ({ powerPlant }) => powerPlant?.name || "-",
  },
  {
    accessor: "invoicesCounters.size",
    title: "Faturas",
    isVisible: true,
    render: ({ invoicesCounters }) => (
      <VStack alignItems="flex-start" spacing={0}>
        <TextStatus status="fulfilled" value={invoicesCounters.fulfilled} />
        <TextStatus status="rejected" value={invoicesCounters.rejected} />
        <TextStatus status="ignored" value={invoicesCounters.ignored} />
      </VStack>
    ),
    exporter: ({ invoicesCounters }) => invoicesCounters.size?.toLocaleString() || "-",
  },
  {
    accessor: "invoicesCounters.fulfilled",
    title: "Faturas sucessos",
    isVisible: false,
    formatter: ({ invoicesCounters }) => invoicesCounters.fulfilled?.toLocaleString() || "-",
  },
  {
    accessor: "invoicesCounters.ignored",
    title: "Faturas ignorados",
    isVisible: false,
    formatter: ({ invoicesCounters }) => invoicesCounters.ignored?.toLocaleString() || "-",
  },
  {
    accessor: "invoicesCounters.rejected",
    title: "Faturas falhas",
    isVisible: false,
    formatter: ({ invoicesCounters }) => invoicesCounters.rejected?.toLocaleString() || "-",
  },
  {
    accessor: "electricityBalancesCounters.size",
    title: "Relatórios GD",
    isVisible: true,
    render: ({ electricityBalancesCounters }) => (
      <VStack alignItems="flex-start" spacing={0}>
        <TextStatus status="fulfilled" value={electricityBalancesCounters.fulfilled} />
        <TextStatus status="rejected" value={electricityBalancesCounters.rejected} />
        <TextStatus status="ignored" value={electricityBalancesCounters.ignored} />
      </VStack>
    ),
    exporter: ({ electricityBalancesCounters }) => electricityBalancesCounters.size?.toLocaleString() || "-",
  },
  {
    accessor: "electricityBalancesCounters.fulfilled",
    title: "Relatórios GD sucessos",
    isVisible: false,
    formatter: ({ electricityBalancesCounters }) => electricityBalancesCounters.fulfilled?.toLocaleString() || "-",
  },
  {
    accessor: "electricityBalancesCounters.ignored",
    title: "Relatórios GD ignorados",
    isVisible: false,
    formatter: ({ electricityBalancesCounters }) => electricityBalancesCounters.ignored?.toLocaleString() || "-",
  },
  {
    accessor: "electricityBalancesCounters.rejected",
    title: "Relatórios GD falhas",
    isVisible: false,
    formatter: ({ electricityBalancesCounters }) => electricityBalancesCounters.rejected?.toLocaleString() || "-",
  },
  {
    accessor: "startedAt",
    title: "Iniciado em",
    isVisible: true,
    formatter: ({ startedAt }) => (startedAt ? moment(startedAt).format("DD/MM/YYYY HH:mm:ss") : "-"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizado em",
    isVisible: true,
    formatter: ({ finishedAt }) => (finishedAt ? moment(finishedAt).format("DD/MM/YYYY HH:mm:ss") : "-"),
  },
  {
    accessor: "durationInSeconds",
    title: "Duração",
    isVisible: true,
    formatter: ({ durationInSeconds }) => (durationInSeconds ? `${durationInSeconds.toLocaleString()}s` : "-"),
  },
  {
    accessor: "abortedBy",
    title: "Abortado por",
    isVisible: true,
    formatter: ({ abortedBy }) => abortedBy?.name ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
