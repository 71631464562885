import { useMemo } from "react";
import Fuse from "fuse.js";

export const useSearch = ({ keys, data, searchText }) => {
  return useMemo(() => {
    if (!searchText) return data;
    const fuse = new Fuse(data, { keys, includeScore: true, threshold: 0.1 });
    const results = fuse.search(searchText);
    return results.map((o) => o.item);
  }, [keys, data, searchText]);
};
