import { MdPhone, MdSmartphone, MdWhatsapp } from "react-icons/md";

export const phoneCategories = [
  {
    icon: MdPhone,
    label: "Telefone fixo",
    value: "phone",
    mask: () => "(99) 9999-9999",
  },
  {
    icon: MdSmartphone,
    label: "Celular",
    value: "cellphone",
    mask: () => "(99) 99999-9999",
  },
  {
    icon: MdWhatsapp,
    label: "Whatsapp",
    value: "whatsapp",
    mask: (phoneNumber = "") => {
      const value = phoneNumber.replace(/\D/g, "");
      if (value.charAt(2) === "3") return "(99) 9999-9999";
      return "(99) 99999-9999";
    },
  },
];
