import React, { useContext, useState, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { api, translator } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { VscDebugStart } from "react-icons/vsc";
import { MdCheck, MdOutlineBlock } from "react-icons/md";
import ApprovalsDetailsContext from "./context";

const StatusActions = () => {
  const { formData, refreshData } = useContext(ApprovalsDetailsContext);
  const [status, setStatus] = useState();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const toast = useCustomToast();

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.patch(`/private/approvals/${formData._id}`, { status });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
      setStatus();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [formData._id, status]);

  return (
    <>
      {formData.status === "waiting_analysis" && (
        <PermissionedContainer required="approvals.update">
          <Button size="sm" colorScheme="green" leftIcon={<Icon as={VscDebugStart} />} onClick={() => setStatus("analyzing")}>
            iniciar análise
          </Button>
        </PermissionedContainer>
      )}

      {formData.status === "analyzing" && (
        <PermissionedContainer required="approvals.update">
          <HStack>
            <Button
              size="sm"
              variant="ghost"
              colorScheme="red"
              leftIcon={<Icon as={MdOutlineBlock} />}
              onClick={() => setStatus("invalidated")}
            >
              invalidar
            </Button>
            <Button size="sm" colorScheme="purple" leftIcon={<Icon as={MdCheck} />} onClick={() => setStatus("validated")}>
              validar
            </Button>
          </HStack>
        </PermissionedContainer>
      )}

      <AlertDialog isOpen={status} onClose={() => setStatus()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            Deseja realmente alterar esta aprovação para o status <strong>{translator(status)}</strong>?
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={() => setStatus()}>cancelar</Button>
            <Button colorScheme="green" onClick={handleSaveData} isLoading={isLoadingSaveData}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default StatusActions;
