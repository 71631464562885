import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useCustomToast, useFetchData, useNewTabNavigate, useQueryParams, useWatcherZIndex } from "hooks";
import DistributorMonitoringsDetailsContext from "./context";
import General from "./general";
import { MdAttachMoney, MdMoreHoriz, MdRefresh, MdSave } from "react-icons/md";
import { TbArchive, TbExternalLink, TbFileInvoice, TbPigMoney } from "react-icons/tb";
import { api, EventEmitter, yup } from "lib";
import { messages } from "consts";
import { PermissionedContainer } from "components";

const queryParamsKey = "distributor_monitoring_id";

export const DistributorMonitoringsDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/distributor-monitorings/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const newTabNavigate = useNewTabNavigate();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/distributor-monitorings/${_id}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        handleClose();
        EventEmitter.emit("distributorMonitorings.refresh");
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast, handleClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = { comments: formData.comments };
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <DistributorMonitoringsDetailsContext.Provider value={{ _id, formData, setFormData, isLoadingData, refreshData, formErrors }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <Text flex="1">Monitoramento CEMIG</Text>
            {formData.status === "pending" && <Archive />}
            <PermissionedContainer required={["payments.read", "charges.read", "invoices.read"]}>
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                    mais ações
                  </MenuButton>
                  <MenuList fontSize="sm">
                    <PermissionedContainer required="payments.read">
                      <MenuItem
                        onClick={() =>
                          newTabNavigate("/payments", { installationNumber: formData.invoice?.distributor?.current?.installationNumber })
                        }
                      >
                        <HStack w="100%">
                          <Icon as={MdAttachMoney} />
                          <Text flex="1">pagamentos da UC</Text>
                          <Icon as={TbExternalLink} />
                        </HStack>
                      </MenuItem>
                    </PermissionedContainer>
                    <PermissionedContainer required="charges.read">
                      <MenuItem
                        onClick={() =>
                          newTabNavigate("/charges", { installationNumber: formData.invoice?.distributor?.current?.installationNumber })
                        }
                      >
                        <HStack w="100%">
                          <Icon as={TbPigMoney} />
                          <Text flex="1">cobranças da UC</Text>
                          <Icon as={TbExternalLink} />
                        </HStack>
                      </MenuItem>
                    </PermissionedContainer>
                    <PermissionedContainer required="invoices.read">
                      <MenuItem
                        onClick={() =>
                          newTabNavigate("/invoices", { installationNumber: formData.invoice?.distributor?.current?.installationNumber })
                        }
                      >
                        <HStack w="100%">
                          <Icon as={TbFileInvoice} />
                          <Text flex="1">faturas da UC</Text>
                          <Icon as={TbExternalLink} />
                        </HStack>
                      </MenuItem>
                    </PermissionedContainer>
                  </MenuList>
                </Menu>
              </Box>
            </PermissionedContainer>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          </DrawerHeader>
          <Divider />
          <DrawerBody pt="20px">
            <General />
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack} alignItems="flex-end">
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSave} />} onClick={handleSubmit} isLoading={isLoadingSaveData}>
              salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </DistributorMonitoringsDetailsContext.Provider>
  );
};

const Archive = () => {
  const { _id, refreshData } = useContext(DistributorMonitoringsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ status: "archived", comments: "" });
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/distributor-monitorings/${_id}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshData();
        EventEmitter.emit("distributorMonitorings.refresh");
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        comments: yup.string().max(100, messages.error.maxLength(100)).required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      <Button size="sm" variant="outline" leftIcon={<Icon as={TbArchive} />} isLoading={isLoadingSaveData} onClick={onOpen}>
        arquivar
      </Button>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>Atenção</Text>
            <Text fontSize="md" fontWeight="light">
              Deseja realmente arquivar o monitoramento atual?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.comments}>
              <HStack mb="5px" justifyContent="space-between">
                <FormLabel mb="0">Observações</FormLabel>
                <Text fontSize="xs" color={_.size(formData.comments) > 100 && "red.500"}>
                  {_.size(formData.comments)}/100
                </Text>
              </HStack>
              <Textarea
                h="100px"
                value={formData.comments || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              continuar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};
