import React, { useMemo, useRef, useState, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import fileDownload from "js-file-download";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { MdMoreHoriz } from "react-icons/md";
import { FiClipboard, FiEdit, FiTrash } from "react-icons/fi";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  PermissionedContainer,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { api } from "lib";
import { useFetchData, useCacheState, useClipboard, useCustomToast, useDocumentTitle, usePermissioned, useTable } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbFileExport } from "react-icons/tb";
import { RiCake2Line } from "react-icons/ri";
import defaultColumns from "./defaultColumns";
import ExportBirthdays from "./exportBirthdays";
import Contracts from "./contracts";
import Filters from "./filters";
import { BiSupport } from "react-icons/bi";

export const CustomersList = () => {
  useDocumentTitle("Clientes");
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "CustomersList.sort", defaultValue: { createdAt: 1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "CustomersList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "CustomersList.perPage", defaultValue: 100 }), []));
  const [search, setSearch] = useCacheState(useMemo(() => ({ key: "CustomersList.search", defaultValue: "" }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/customers",
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 12 }), [location.pathname])
  );
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const { isOpen: isOpenExportBirthdays, onOpen: onOpenExportBithdays, onClose: onCloseExportBirthdays } = useDisclosure();
  const [isLoadingExportPhones, setIsLoadingExportPhones] = useState(false);
  const isAllowedExportSensitiveData = usePermissioned("customers.exportSensitiveData.*");
  const isAllowedReadSensitiveData = usePermissioned("customers.readSensitiveData.*");
  const toast = useCustomToast();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/private/customers", { data: _.map(checkeds, "_id") });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleExportPhones = useCallback(async () => {
    try {
      setIsLoadingExportPhones(true);
      const data = await fetchAllPages();
      fileDownload(_.map(data, "phone1"), `telefones_${new Date().toISOString()}.txt`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingExportPhones(false);
    }
  }, [fetchAllPages]);

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "cadastros" }, { to: "/customers", label: "clientes" }]} />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <PermissionedContainer required="customers.create">
                  <Button as={RouterLink} to="new" size="sm" colorScheme="main">
                    incluir cadastro
                  </Button>
                </PermissionedContainer>
                <PermissionedContainer required={["customers.export", "customers.exportSensitiveData"]}>
                  <Box>
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="outline"
                        rightIcon={<Icon as={MdMoreHoriz} />}
                        isLoading={isLoadingExportPhones}
                      >
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="customers.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar clientes</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                          <PermissionedContainer required="customers.exportSensitiveData">
                            <MenuItem onClick={handleExportPhones}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar telefones</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                          <PermissionedContainer required="customers.exportSensitiveData">
                            <MenuItem onClick={onOpenExportBithdays}>
                              <HStack>
                                <Icon as={RiCake2Line} />
                                <Text>exportar aniversariantes</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Clientes
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider key={timestampData} values={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr
                    key={item._id}
                    cursor="pointer"
                    _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                    _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                    onDoubleClick={() => navigate(`edit/${item._id}`)}
                  >
                    <Td>
                      <HStack>
                        <CheckboxBody value={item} />
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.name} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                <PermissionedContainer required="customers.update.*">
                                  <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`edit/${item._id}`}>
                                    editar
                                  </MenuItem>
                                </PermissionedContainer>
                                <PermissionedContainer required="customers.delete">
                                  <MenuDivider />
                                  <MenuItem
                                    icon={<Icon as={FiTrash} />}
                                    onClick={() => {
                                      setCheckeds([item]);
                                      onOpenDeleteDialog();
                                    }}
                                  >
                                    excluir
                                  </MenuItem>
                                </PermissionedContainer>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                        {_.size(item.contracts) > 0 && <Contracts {...item} />}
                        {item.sentToCollectionAgency && (
                          <Tooltip label="Cobrança terceirizada">
                            <Center bg="purple.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={BiSupport} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { isAllowedReadSensitiveData })}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="clientes"
        onFetchData={fetchAllPages}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
        isAllowedExportSensitiveData={isAllowedExportSensitiveData}
      />

      <ExportBirthdays isOpen={isOpenExportBirthdays} onClose={onCloseExportBirthdays} />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
