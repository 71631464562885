import React, { useCallback, useContext, Fragment, useMemo } from "react";
import _ from "lodash";
import ObjectID from "bson-objectid";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useArrayItemHandlers, useConfirmDialog } from "hooks";
import { MdAdd } from "react-icons/md";
import { FiChevronDown, FiChevronUp, FiPlus, FiTrash } from "react-icons/fi";
import StatusesDetailsContext from "./context";

const WhatsApp = ({ title, path }) => {
  const { formData, setFormData, formErrors } = useContext(StatusesDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const isActive = useMemo(() => _.isObject(formData[path]), [formData[path]]);
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  const handleToggleWhatsApp = useCallback(async () => {
    if (isActive) {
      const confirm = await confirmAction();
      if (confirm) setFormData((state) => ({ ...state, [path]: null }));
      onClose();
    } else {
      setFormData((state) => ({ ...state, [path]: {} }));
      onOpen();
    }
  }, [path, isActive]);

  return (
    <VStack p="20px" alignItems="stretch" borderWidth="1px" borderRadius="lg">
      <HStack>
        <Box flex="1">
          <Heading size="sm">{title}</Heading>
          <Text fontSize="xs">Informações para o envio de mensagem via WhatsApp.</Text>
        </Box>
        {isActive && <IconButton variant="outline" icon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />} onClick={onToggle} />}
        <Tooltip
          placement="left"
          label={isActive ? `Remover informações de envio do ${title}.` : `Adicionar informações de envio do ${title}.`}
        >
          <IconButton variant="outline" icon={<Icon as={isActive ? FiTrash : FiPlus} />} onClick={handleToggleWhatsApp} />
        </Tooltip>
      </HStack>

      {isActive && isOpen && (
        <Fragment>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors[path]?.templateId}>
                <FormLabel fontSize="sm">Id do template</FormLabel>
                <Input
                  value={formData[path]?.templateId ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, [path]: { ...state[path], templateId: target.value } }))}
                />
                <FormErrorMessage>{formErrors[path]?.templateId}</FormErrorMessage>
              </FormControl>
            </GridItem>

            {_.map(formData[path]?.variables, (variable, index) => (
              <Fragment key={variable._id}>
                <GridItem colSpan={5}>
                  <FormControl isRequired={true} isInvalid={formErrors[path]?.variables?.[index].key}>
                    <FormLabel fontSize="sm">Variável {index + 1}</FormLabel>
                    <Input
                      value={variable.key ?? ""}
                      onChange={({ target }) => handleChangeArrayItem(`${path}.variables`, index, { key: target.value })}
                    />
                    <FormErrorMessage>{formErrors[path]?.variables?.[index].key}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={7}>
                  <FormControl isRequired={true} isInvalid={formErrors[path]?.variables?.[index].value}>
                    <FormLabel fontSize="sm">Valor</FormLabel>
                    <HStack>
                      <Select
                        value={variable.value ?? ""}
                        onChange={({ target }) => handleChangeArrayItem(`${path}.variables`, index, { value: target.value })}
                      >
                        <option value="">--Selecione</option>
                        <optgroup label="Cliente">
                          <option value="customer.name">Nome</option>
                          <option value="customer.document">Documento</option>
                          <option value="customer.email">E-mail</option>
                          <option value="customer.phone1">Telefone 1</option>
                        </optgroup>
                        <optgroup label="Unidade consumidora">
                          <option value="consumerUnit.cemigInstallationNumber">N° de instalação</option>
                          <option value="consumerUnit.address.line1">Endereço linha 1 (Rua, N°, Compl., Bairro)</option>
                          <option value="consumerUnit.address.line2">Endereço linha 2 (Cidade/Estado - CEP)</option>
                          <option value="consumerUnit.isUnifiedInvoice">Modalidade de fatura</option>
                        </optgroup>
                      </Select>
                      <IconButton
                        variant="outline"
                        icon={<Icon as={FiTrash} />}
                        onClick={() => handleDeleteArrayItem(`${path}.variables`, index)}
                      />
                    </HStack>
                    <FormErrorMessage>{formErrors[path]?.variables?.[index].value}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Fragment>
            ))}
          </Grid>

          <Button
            w="100%"
            variant="outline"
            leftIcon={<Icon as={MdAdd} />}
            onClick={() => handleAddArrayItem(`${path}.variables`, { _id: ObjectID().toHexString(), key: "", value: "" })}
          >
            adicionar variável
          </Button>
        </Fragment>
      )}

      <ConfirmDialog
        title="Atenção"
        description={`Deseja realmente remover as informações de envio do ${title}?`}
        buttons={[
          { value: false, text: "cancelar" },
          { value: true, text: "remover", colorScheme: "red" },
        ]}
      />
    </VStack>
  );
};

export default WhatsApp;
