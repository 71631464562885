import React, { useState, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import _ from "lodash";
import { Address, EmailAutocomplete, PhoneInput } from "components";
import { DocumentHistory } from "containers";
import { messages } from "consts";
import { api, yup } from "lib";
import { useCustomToast } from "hooks";
import { MdHistory } from "react-icons/md";
import { LegalRepresentativesContext } from "./index";
import CustomersDetailsContext from "../context";

const Details = () => {
  const { _id } = useParams();
  const { refreshLegalRepresentatives } = useContext(CustomersDetailsContext);
  const { editSelected, setEditSelected } = useContext(LegalRepresentativesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData(editSelected?._id ? { ...editSelected } : { phoneCategory: "whatsapp", isRepresentedByAttorney: false, isActive: true });
    setFormErrors({});
  }, [editSelected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id
          ? await api.patch(`/private/customers/${_id}/legal-representatives/${data._id}`, data)
          : await api.put(`/private/customers/${_id}/legal-representatives`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setEditSelected();
        refreshLegalRepresentatives();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshLegalRepresentatives, toast, setEditSelected]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        name: yup.string().isValidName().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        phone: yup.string().isValidPhoneNumber(),
        email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          number: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required),
          city: yup.string().required(messages.error.required),
          state: yup.string().required(messages.error.required),
        }),
        isRepresentedByAttorney: yup.bool(),
        attorneyName: yup.string().when("isRepresentedByAttorney", {
          is: true,
          then: yup.string().required(messages.error.required),
        }),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Modal size="6xl" isOpen={editSelected} onClose={() => setEditSelected()} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Representante legal</Text>
            {editSelected?._id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
            <HStack>
              <Text fontSize="xs">Ativo?</Text>
              <Switch
                size="sm"
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </HStack>
          </ModalHeader>
          <Divider />
          <ModalBody py={4}>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 8 }}>
                <FormControl isRequired={true} isInvalid={formErrors.name}>
                  <FormLabel fontSize="sm">Nome</FormLabel>
                  <Input
                    value={formData.name ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.document}>
                  <FormLabel fontSize="sm">CPF</FormLabel>
                  <Input
                    as={InputMask}
                    mask="999.999.999-99"
                    value={formData.document ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.document}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isInvalid={formErrors.rg}>
                  <FormLabel fontSize="sm">RG</FormLabel>
                  <Input value={formData.rg ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, rg: target.value }))} />
                  <FormErrorMessage>{formErrors.rg}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.email}>
                  <FormLabel fontSize="sm">E-mail</FormLabel>
                  <EmailAutocomplete
                    value={formData.email ?? ""}
                    onChange={(email) => setFormData((state) => ({ ...state, email }))}
                    isInvalid={formErrors.email}
                  />
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isRequired={true} isInvalid={formErrors.phone}>
                  <FormLabel fontSize="sm">Telefone</FormLabel>
                  <PhoneInput
                    phone={formData.phone}
                    category={formData.phoneCategory}
                    onChangePhone={(phone) => setFormData((state) => ({ ...state, phone }))}
                    onChangeCategory={(phoneCategory) => setFormData((state) => ({ ...state, phoneCategory }))}
                  />
                  <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>

            <Divider my={8} />

            <Heading size="sm" mb={6}>
              Endereço
            </Heading>
            <Address
              data={formData.address}
              errors={formErrors.address}
              isRequired={true}
              onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
            />

            <Divider my={8} />

            <Heading size="sm" mb={6}>
              Procurador
            </Heading>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: formData.isRepresentedByAttorney ? 6 : 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.isRepresentedByAttorney}>
                  <FormLabel fontSize="sm">Representado por procuração?</FormLabel>
                  <Select
                    value={formData.isRepresentedByAttorney ? "yes" : "no"}
                    onChange={({ target }) => setFormData((state) => ({ ...state, isRepresentedByAttorney: target.value === "yes" }))}
                  >
                    <option value="yes">Sim</option>
                    <option value="no">Não</option>
                  </Select>
                  <FormErrorMessage>{formErrors.isRepresentedByAttorney}</FormErrorMessage>
                </FormControl>
              </GridItem>
              {formData.isRepresentedByAttorney && (
                <GridItem colSpan={{ base: 12, lg: 6 }}>
                  <FormControl isRequired={true} isInvalid={formErrors.attorneyName}>
                    <FormLabel fontSize="sm">Nome do procurador</FormLabel>
                    <Input
                      value={formData.attorneyName ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, attorneyName: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.attorneyName}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              )}
            </Grid>

            {editSelected?._id && (
              <DocumentHistory
                path={`/private/customers/${_id}/legal-representantives/${editSelected._id}/history`}
                isOpen={isOpenDocumentHistory}
                onClose={onCloseDocumentHistory}
              />
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={() => setEditSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Details;
