import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Center, Box, useColorModeValue, Text, VStack } from "@chakra-ui/react";
import { SignIn } from "./SignIn";
import { PasswordReset } from "./PasswordReset";

const Container = () => {
  const backgroundColor = useColorModeValue("white", "gray.900");

  return (
    <Center width="100vw" height="100vh" bg={backgroundColor}>
      <VStack>
        <Box borderWidth="1px" borderRadius="xl" width={{ base: "90%", md: "400px" }}>
          <Outlet />
        </Box>
        <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
      </VStack>
    </Center>
  );
};

const Public = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="sign-in" element={<SignIn />} />
          <Route path="password-reset" element={<PasswordReset />} />
        </Route>
        <Route path="*" element={<Navigate to="/sign-in" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Public;
