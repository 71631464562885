import React, { memo, useCallback, useContext, useEffect, cloneElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  useToken,
  VStack,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { MdAttachMoney, MdClose, MdOutlineWebhook } from "react-icons/md";
import { RiCalendarScheduleLine, RiSettings4Line } from "react-icons/ri";
import { BiHome } from "react-icons/bi";
import { HiOutlineBolt } from "react-icons/hi2";
import { IoMdCheckboxOutline } from "react-icons/io";
import { TbDatabaseImport, TbFileInvoice, TbFolderSearch, TbHomeBolt, TbPigMoney, TbUserBolt, TbUserExclamation } from "react-icons/tb";
import { VscSend } from "react-icons/vsc";
import { ContainerContext } from "./index";
import useCounters from "./useCounters";
import { AiOutlineAlert } from "react-icons/ai";
import { PiPlantBold } from "react-icons/pi";

const MenuItem = memo(({ href, icon, title, RightComponent, ...props }) => {
  const Icon = cloneElement(icon, { color: icon.props.color || "green.500" });
  const [backgroundColor, color] = useToken("colors", [
    useColorModeValue("green.50", "whiteAlpha.100"),
    useColorModeValue("green.500", "white"),
  ]);

  const handleActiveStyle = useCallback(
    ({ isActive }) => {
      if (isActive) return { backgroundColor, color };
      return {};
    },
    [backgroundColor, color]
  );

  return (
    <HStack
      py="5px"
      px="10px"
      cursor="pointer"
      transition="200ms"
      borderRadius="md"
      _hover={{ bg: "blackAlpha.100" }}
      as={href ? "a" : NavLink}
      style={href ? {} : handleActiveStyle}
      {...props}
    >
      {Icon}
      <Text fontSize="xs" fontWeight="500" flex="1">
        {title}
      </Text>
      {RightComponent}
    </HStack>
  );
});

const CountBadge = ({ colorScheme, label, count }) => {
  return (
    count > 0 && (
      <Tooltip label={`${count.toLocaleString()} ${label}`} placement="right">
        <Badge variant="solid" colorScheme={colorScheme} borderRadius="full" fontSize="x-small">
          {count}
        </Badge>
      </Tooltip>
    )
  );
};

const MenuList = () => {
  const counters = useCounters();

  return (
    <Box minW="220px" overflowY="auto">
      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <Text fontWeight="600" fontSize="xs" py="5px" px="8px">
          Índice
        </Text>
        <MenuItem to="/home" icon={<Icon as={BiHome} />} title="Página inicial" />
        <PermissionedContainer required="alerts.read">
          <MenuItem
            to="/alerts"
            icon={<Icon as={AiOutlineAlert} />}
            title="Central de alertas"
            RightComponent={<CountBadge colorScheme="red" label="pendentes" count={counters.alerts} />}
          />
        </PermissionedContainer>
      </VStack>

      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <Text fontWeight="600" fontSize="xs" py="5px" px="8px">
          Cadastros
        </Text>
        <MenuItem to="/customers" icon={<Icon as={TbUserBolt} />} title="Clientes" />
        <MenuItem to="/consumer-units" icon={<Icon as={TbHomeBolt} />} title="Un. consumidoras" />
        <MenuItem to="/leads" icon={<Icon as={TbUserExclamation} />} title="Leads" />
        <PermissionedContainer required="clickRecSolicitations.read">
          <MenuItem to="/click-rec-solicitations" icon={<Icon as={PiPlantBold} />} title="Click Rec" />
        </PermissionedContainer>
      </VStack>

      <PermissionedContainer required={["invoiceCaptures.read", "invoices.read", "distributorMonitorings.read", "messages.read"]}>
        <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
          <Text fontWeight="600" fontSize="xs" py="5px" px="8px">
            Faturamento
          </Text>
          <PermissionedContainer required="invoiceCaptures.read">
            <MenuItem to="/invoice-captures" icon={<Icon as={TbDatabaseImport} />} title="Capturas de faturas" />
          </PermissionedContainer>
          <PermissionedContainer required={["invoices.read", "invoices.readOnlySentToCollectionAgency"]}>
            <MenuItem to="/invoices" icon={<Icon as={TbFileInvoice} />} title="Faturas" />
          </PermissionedContainer>
          <PermissionedContainer required="distributorMonitorings.read">
            <MenuItem to="/distributor-monitorings" icon={<Icon as={TbFolderSearch} />} title="Monitoramentos CEMIG" />
          </PermissionedContainer>
          <PermissionedContainer required="messages.read">
            <MenuItem to="/messages" icon={<Icon as={VscSend} />} title="Central de mensagens" />
          </PermissionedContainer>
        </VStack>
      </PermissionedContainer>

      <PermissionedContainer
        required={["charges.read", "charges.readOnlySentToCollectionAgency", "approvals.read", "payments.read", "webhooks.read"]}
      >
        <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
          <Text fontWeight="600" fontSize="xs" py="5px" px="8px">
            Pagadoria
          </Text>
          <PermissionedContainer required={["charges.read", "charges.readOnlySentToCollectionAgency"]}>
            <MenuItem to="/charges" icon={<Icon as={TbPigMoney} />} title="Cobranças" />
          </PermissionedContainer>
          <PermissionedContainer required="approvals.read">
            <MenuItem to="/approvals" icon={<Icon as={IoMdCheckboxOutline} />} title="Aprovações" />
          </PermissionedContainer>
          <PermissionedContainer required="payments.read">
            <MenuItem to="/payments" icon={<Icon as={MdAttachMoney} />} title="Pagamentos" />
          </PermissionedContainer>
          <PermissionedContainer required={["webhooks.read"]}>
            <MenuItem to="/webhooks" icon={<Icon as={MdOutlineWebhook} />} title="Webhooks" />
          </PermissionedContainer>
        </VStack>
      </PermissionedContainer>

      <PermissionedContainer required={["powerManagement.read"]}>
        <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
          <Text fontWeight="600" fontSize="xs" py="5px" px="8px">
            Gestão
          </Text>
          <PermissionedContainer required="powerManagement.read">
            <MenuItem to="/power-management" icon={<Icon as={HiOutlineBolt} />} title="Gestão de energia" />
          </PermissionedContainer>
        </VStack>
      </PermissionedContainer>

      <VStack alignItems="stretch" spacing="0" py="5px" px="10px">
        <PermissionedContainer
          required={[
            "users.read",
            "powerPlants.read",
            "exhibitions.read",
            "segments.read",
            "tariffGroups.read",
            "promotions.read",
            "expirationGroups.read",
            "sendingGroups.read",
            "statuses.read",
            "userGroups.read",
            "faq.read",
            "tariffBases.read",
            "invoiceTemplates.read",
            "imports.consumerUnitsStatuses",
            "imports.consumerUnitsPowerPlants",
          ]}
        >
          <MenuItem to="/settings" title="Configurações" icon={<Icon as={RiSettings4Line} />} />
        </PermissionedContainer>
        <PermissionedContainer required={["crons.read"]}>
          <MenuItem to="/crons" icon={<Icon as={RiCalendarScheduleLine} />} title="Crons" />
        </PermissionedContainer>
      </VStack>
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isMobileView, isOpenSidebar, onCloseSidebar } = useContext(ContainerContext);

  useEffect(() => {
    onCloseSidebar();
  }, [location]);

  return isMobileView ? (
    <Drawer isOpen={isOpenSidebar} placement="left" onClose={onCloseSidebar}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <IconButton variant="outline" isRound icon={<Icon as={MdClose} />} onClick={onCloseSidebar} />
        </DrawerHeader>
        <DrawerBody p="0">
          <MenuList />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <MenuList />
  );
};

export default Sidebar;
