import { useMemo } from "react";
import { useQueryParams } from "./useQueryParams";

export const useWatcherZIndex = (key) => {
  const { queryParams } = useQueryParams();
  return useMemo(() => {
    const index = Object.keys(queryParams).indexOf(key);
    let overlay = 1300;
    if (index !== -1) overlay += index * 100;
    return { overlay, content: overlay + 1 };
  }, [queryParams, key]);
};
