import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Box, Center, Icon, Text, VStack } from "@chakra-ui/react";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ListItem from "./listItem";
import StatusesListContext from "./context";
import { PiEmpty } from "react-icons/pi";

export const ListContext = createContext();

export const List = ({ type, droppableId, items = [] }) => {
  const { refreshStatuses } = useContext(StatusesListContext);
  const [isOpen, setIsOpen] = useState({});
  const [isLoadingDragEnd, setIsLoadingDragEnd] = useState(false);
  const toast = useCustomToast();
  const [rows, setRows] = useState([]);
  const isOuter = useMemo(() => type === "OUTER", [type]);

  useEffect(() => {
    setRows(items);
  }, [items]);

  const onDragEnd = useCallback(
    async (result) => {
      try {
        if (!result.destination) return;
        const tmp = [...rows];
        const [removed] = tmp.splice(result.source.index, 1);
        tmp.splice(result.destination.index, 0, removed);
        const mapped = tmp.map((o, ordination) => ({ ...o, ordination }));
        setRows(mapped);
        setIsLoadingDragEnd(true);
        await api.patch("/private/statuses", mapped);
      } catch (error) {
        refreshStatuses();
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingDragEnd(false);
      }
    },
    [rows]
  );

  return (
    <ListContext.Provider value={{ isOuter, type, isOpen, setIsOpen, isLoadingDragEnd }}>
      <DragDropContext onBeforeDragStart={() => setIsOpen({})} onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId} direction="vertical">
          {(provided) => (
            <VStack {...provided.droppableProps} ref={provided.innerRef} align="stretch">
              {_.map(rows, (item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => <ListItem {...{ provided, item }} />}
                </Draggable>
              ))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>

      {_.size(rows) === 0 && isOuter === false && (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={PiEmpty} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Nenhum substatus adicionado
            </Text>
            <Text fontSize="sm">Este status ainda não possui nenhum substatus adicionado.</Text>
          </Box>
        </Center>
      )}
    </ListContext.Provider>
  );
};
