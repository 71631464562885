import React, { useCallback } from "react";
import _ from "lodash";
import { Button, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, VStack } from "@chakra-ui/react";
import { TbVariable } from "react-icons/tb";

export const colorSchemes = {
  gray: "Cinza",
  red: "Vermelho",
  orange: "Laranja",
  yellow: "Amarelo",
  green: "Verde",
  teal: "Verde-azulado",
  blue: "Azul",
  cyan: "Ciano",
  purple: "Roxo",
  pink: "Rosa",
};

const emailVariables = {
  "{NOME}": "Nome",
  "{DOCUMENTO}": "Documento",
  "{EMAIL}": "E-mail",
  "{TELEFONE_1}": "Telefone 1",
  "{NUM_INSTALACAO}": "Número de instalação",
  "{ENDERECO_LINHA_1}": "Endereço linha 1 (Rua, N°, Compl., Bairro)",
  "{ENDERECO_LINHA_2}": "Endereço linha 2 (Cidade/Estado - CEP)",
  "{MODALIDADE_FATURA}": "Modalidade de fatura",
};

const EmailVariables = ({ inputRef, text = "", onChangeText }) => {
  const insetEmailBodyVariable = useCallback(
    (insertText) => {
      const start = inputRef.current.selectionStart;
      const end = inputRef.current.selectionEnd;
      const newText = text.slice(0, start) + insertText + text.slice(end);
      onChangeText(newText);
      setTimeout(() => {
        inputRef.current.setSelectionRange(start + insertText.length, start + insertText.length);
        inputRef.current.focus();
      }, 0);
    },
    [inputRef.current, text]
  );

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button size="xs" variant="outline" leftIcon={<Icon as={TbVariable} />}>
          variáveis disponíveis
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody as={VStack} alignItems="stretch">
          {Object.entries(emailVariables).map(([key, label]) => (
            <Button key={key} size="sm" variant="outline" onClick={() => insetEmailBodyVariable(key)}>
              {label}
            </Button>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default EmailVariables;
