import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CheckboxGroup, Checkbox } from "@chakra-ui/react";

const CheckboxContext = createContext();

export const CheckboxHeader = memo(() => {
  const { items, checkeds, onChange } = useContext(CheckboxContext);
  const allChecked = useMemo(() => items.length > 0 && items.length === checkeds.length, [items, checkeds]);
  const isIndeterminate = useMemo(() => checkeds.length > 0 && !allChecked, [checkeds, allChecked]);

  const handleHeadCheck = useCallback(() => {
    onChange(checkeds.length !== items.length ? items : []);
  }, [checkeds, items, onChange]);

  return <Checkbox colorScheme="main" isChecked={allChecked} isIndeterminate={isIndeterminate} onChange={handleHeadCheck} />;
});

export const CheckboxBody = memo(({ value, isDisabled }) => {
  const { setItems, values, onChange } = useContext(CheckboxContext);

  useEffect(() => {
    setItems((state) => {
      const index = state.findIndex((o) => o._id === value._id);
      if (index !== -1) return state;
      return [...state, value];
    });
  }, [value]);

  const handleCheck = useCallback(() => {
    const tmp = [...values];
    const index = tmp.findIndex((o) => o._id === value._id);
    if (index === -1) tmp.push(value);
    else tmp.splice(index, 1);
    onChange(tmp);
  }, [value, values, onChange]);

  return <Checkbox colorScheme="main" value={value._id} onChange={handleCheck} isDisabled={isDisabled} />;
});

export const CheckboxProvider = ({ values, onChange, children }) => {
  const [items, setItems] = useState([]);
  const checkeds = useMemo(() => values.map((o) => o._id), [values]);

  return (
    <CheckboxContext.Provider value={{ items, setItems, checkeds, values, onChange }}>
      <CheckboxGroup value={checkeds}>{children}</CheckboxGroup>
    </CheckboxContext.Provider>
  );
};
