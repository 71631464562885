import React, { Fragment, useCallback, useRef, useState } from "react";
import _ from "lodash";
import Papa from "papaparse";
import {
  Button,
  Heading,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { MdAttachFile, MdClose } from "react-icons/md";
import UploadConfirm from "./uploadConfirm";
import ExampleImg from "assets/imgs/consumer-units-statuses-csv-example.png";

const parse = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      complete: resolve,
      error: reject,
    });
  });
};

const Upload = () => {
  const attachFormRef = useRef();
  const attachInputRef = useRef();
  const toast = useCustomToast();
  const [file, setFile] = useState();
  const [consumerUnits, setConsumerUnits] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFileChange = useCallback(
    async ({ target }) => {
      try {
        const [file] = target.files;
        const result = await parse(file);
        const consumerUnits = result.data
          .map((o) => ({ installationNumber: o["INSTALAÇÃO"], currentSubstatus: o["SUBSTATUS"], powerPlant: o["USINA"] }))
          .filter((o) => _.isString(o.installationNumber) && /^\d+$/.test(o.installationNumber) && _.size(o.installationNumber) === 10);
        if (consumerUnits.length === 0) throw new Error(messages.error.consumerUnitsInvalidCsvFile);
        setConsumerUnits(consumerUnits);
        setFile(file);
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
        setFile();
      } finally {
        attachFormRef.current.reset();
      }
    },
    [attachFormRef.current, onClose]
  );

  return (
    <Fragment>
      <Button size="sm" colorScheme="main" leftIcon={<Icon as={MdAttachFile} />} onClick={onOpen}>
        nova importação
      </Button>
      <Modal size="lg" isCentered {...{ isOpen, onClose }}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody as={VStack} p="30px">
            <IconButton
              position="absolute"
              top="20px"
              right="20px"
              size="sm"
              variant="outline"
              icon={<Icon as={MdClose} />}
              onClick={onClose}
            />
            <Heading size="lg">Nova importação</Heading>
            <Text fontSize="sm" textAlign="center">
              Certifique-se de que as três primeiras colunas do arquivo selecionado para a importação siga o padrão da imagem abaixo.
            </Text>
            <Image my={4} src={ExampleImg} />
            <Button variant="outline" leftIcon={<Icon as={MdAttachFile} />} onClick={() => attachInputRef.current.click()}>
              selecionar arquivo csv
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <VisuallyHidden>
        <form ref={attachFormRef}>
          <input ref={attachInputRef} type="file" accept=".csv" multiple={false} onChange={handleFileChange} />
        </form>
      </VisuallyHidden>
      <UploadConfirm {...{ file, consumerUnits, setConsumerUnits }} />
    </Fragment>
  );
};

export default Upload;
