import { Fragment } from "react";
import { ApprovalsDetails } from "./ApprovalsDetails";
import { ChargesDetails } from "./ChargesDetails";
import { PaymentsDetails } from "./PaymentsDetails";
import { WebhooksDetails } from "./WebhooksDetails";
import { DistributorMonitoringsDetails } from "./DistributorMonitoringsDetails";
import { MessagesDetails } from "./MessageDetails";
import { AlertsDetails } from "./AlertsDetails";

const Watchers = () => {
  return (
    <Fragment>
      <ApprovalsDetails />
      <ChargesDetails />
      <PaymentsDetails />
      <WebhooksDetails />
      <DistributorMonitoringsDetails />
      <MessagesDetails />
      <AlertsDetails />
    </Fragment>
  );
};

export default Watchers;
