import React, { Fragment, memo, useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { Box, Checkbox, Divider, HStack, Heading, Icon, IconButton, Text, Tooltip, VStack, useDisclosure } from "@chakra-ui/react";
import { MdInfoOutline, MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import groups from "./groups";
import UserGroupsDetailsContext from "../context";

const ListItem = memo(({ item }) => {
  const { formData, setFormData } = useContext(UserGroupsDetailsContext);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: item.defaultIsOpen });
  const uncheckeds = useMemo(() => _.difference(item.values, formData.permissions), [formData.permissions, item.values]);
  const isParent = useMemo(() => item.items?.length >= 1, [item.items?.length]);
  const isIndeterminate = useMemo(
    () => uncheckeds.length > 0 && uncheckeds.length < item.values.length,
    [uncheckeds.length, item.values.length]
  );
  const isChecked = useMemo(() => uncheckeds.length === 0, [uncheckeds.length]);

  const handleSelection = useCallback(() => {
    setFormData((state) => {
      let permissions = _.filter(state.permissions, (permission) => {
        return _.findIndex(item.values, (value) => value === permission) === -1;
      });
      if (isIndeterminate || !isChecked) permissions = [...permissions, ...item.values];
      return { ...state, permissions };
    });
  }, [isIndeterminate, isChecked, item.values]);

  return (
    <>
      <HStack py="4px" spacing="0">
        <Checkbox
          colorScheme={isIndeterminate ? "gray" : "main"}
          isIndeterminate={isIndeterminate}
          isChecked={isChecked}
          onChange={handleSelection}
        />
        <HStack cursor={isParent ? "pointer" : "default"} pl={2} onClick={onToggle}>
          <Text flex="1" fontSize="xs">
            {item.label}
          </Text>
          {item.description && (
            <Tooltip label={item.description} placement="left">
              <IconButton icon={<Icon as={MdInfoOutline} boxSize="18px" />} size="16px" variant="ghost" borderRadius="full" />
            </Tooltip>
          )}
          {isParent && <Icon as={isOpen ? MdKeyboardArrowDown : MdKeyboardArrowRight} />}
        </HStack>
      </HStack>
      {isParent && isOpen && (
        <Box pl="15px" ml="8px" borderLeftWidth="1px">
          <List items={item.items} />
        </Box>
      )}
    </>
  );
});

const List = memo(({ items }) => {
  return (
    <VStack alignItems="flex-start" spacing={1}>
      {_.map(items, (item) => (
        <ListItem key={item.key} {...{ item }} />
      ))}
    </VStack>
  );
});

const Permissions = () => {
  return (
    <Fragment>
      <Divider my={8} />
      <Heading mb={4} size="sm">
        Permissões
      </Heading>
      <List items={groups} />
    </Fragment>
  );
};

export default Permissions;
