import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  Tooltip,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import { MdCheck, MdError, MdClose, MdOutlineCloud } from "react-icons/md";
import { FileImage } from "components";
import { api, formatBytes } from "lib";
import { FileUploader } from "react-drag-drop-files";

export const FileUpload = ({ group, ownerRef, owner, isOpen, onClose, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [successUploadedFilesCount, setSuccessUploadedFilesCount] = useState(0);
  const [errorUploadedFilesCount, setErrorUploadedFilesCount] = useState(0);
  const inputRef = useRef();

  useEffect(() => {
    setDroppedFiles([]);
    setUploadedFiles({});
    setSuccessUploadedFilesCount(0);
    setErrorUploadedFilesCount(0);
  }, [isOpen]);

  useEffect(() => {
    const uploadedFilesValues = Object.values(uploadedFiles);
    const successUploadedFilesCount = uploadedFilesValues.filter((o) => o._id).length;
    setSuccessUploadedFilesCount(successUploadedFilesCount);
    setErrorUploadedFilesCount(uploadedFilesValues.length - successUploadedFilesCount);
    setIsLoading(uploadedFilesValues.length !== droppedFiles.length);
  }, [droppedFiles, uploadedFiles]);

  useEffect(() => {
    if (droppedFiles.length > 0) {
      const responses = Object.values(uploadedFiles);
      const isFinished = responses.length === droppedFiles.length;
      if (isFinished) onChange?.(responses.filter((o) => o._id));
    }
  }, [droppedFiles, uploadedFiles, onChange]);

  useEffect(() => {
    (async () => {
      for (const droppedFile of droppedFiles) {
        try {
          const doc = await api.upload("/private/files", [droppedFile], { group, ownerRef, owner });
          setUploadedFiles((state) => ({ ...state, [droppedFile.name]: doc }));
        } catch (error) {
          setUploadedFiles((state) => ({ ...state, [droppedFile.name]: error }));
        }
      }
    })();
  }, [group, ownerRef, owner, droppedFiles]);

  const handleChangeFiles = (files) => {
    setDroppedFiles([]);
    setUploadedFiles({});
    const data = Array.from(files);
    for (const item of data) {
      item.ext = item.name.split(".").pop();
      item.location = URL.createObjectURL(item);
    }
    setDroppedFiles(data);
  };

  return (
    <>
      <Modal isOpen={isOpen} isCentered scrollBehavior="inside">
        <input type="file" ref={inputRef} multiple={true} onChange={handleChangeFiles} style={{ visibility: "hidden" }} />
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Upload de arquivos</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} isLoading={isLoading} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            {droppedFiles.length === 0 ? (
              <FileUploader hoverTitle="Solte aqui" multiple={true} handleChange={handleChangeFiles}>
                <VStack
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="xl"
                  p="20px"
                  spacing="0"
                  cursor="pointer"
                  transition="500ms"
                  my="15px"
                  _hover={{ _light: { backgroundColor: "main.50" } }}
                >
                  <Icon as={MdOutlineCloud} boxSize="80px" _light={{ color: "gray.300" }} />
                  <Heading size="md">Solte os arquivos aqui</Heading>
                  <Text fontSize="xs" textAlign="center">
                    Solte os arquivos desejados ou clique aqui para fazer a seleção.
                  </Text>
                </VStack>
              </FileUploader>
            ) : (
              <VStack align="stretch" spacing={2} divider={<StackDivider />}>
                {droppedFiles.map((droppedFile) => (
                  <HStack key={droppedFile.name} py="10px" justify="space-between">
                    <HStack>
                      <FileImage
                        type={droppedFile.type}
                        src={droppedFile.location}
                        title={droppedFile.name}
                        ext={droppedFile.ext}
                        width="50px"
                        height="50px"
                        boxSize="40px"
                      />
                      <VStack align="stretch" spacing={0}>
                        <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
                          {droppedFile.name}
                        </Text>
                        <Text fontSize="xs">{formatBytes(droppedFile.size)}</Text>
                      </VStack>
                    </HStack>
                    {uploadedFiles[droppedFile.name] ? (
                      <Tooltip label={uploadedFiles[droppedFile.name]?.message ?? ""}>
                        <IconButton
                          icon={<Icon as={uploadedFiles[droppedFile.name]._id ? MdCheck : MdError} />}
                          colorScheme={uploadedFiles[droppedFile.name]._id ? "green" : "red"}
                          size="xs"
                          isRound={true}
                        />
                      </Tooltip>
                    ) : (
                      <IconButton isLoading={true} size="xs" isRound={true} />
                    )}
                  </HStack>
                ))}
              </VStack>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Box flex="1">
              <Text fontSize="xs">
                {successUploadedFilesCount}/{droppedFiles.length} arquivos finalizados com sucesso
              </Text>
              {errorUploadedFilesCount > 0 && (
                <Text fontSize="xs" color="red.500">
                  {errorUploadedFilesCount} arquivos com falha
                </Text>
              )}
            </Box>
            <Button size="sm" colorScheme="main" isLoading={isLoading} onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
