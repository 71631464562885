import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Badge, Box, Center, HStack, Icon, IconButton, Spinner, StackDivider, Text, VStack } from "@chakra-ui/react";
import { MdOutlineWebhook, MdRefresh } from "react-icons/md";
import PaymentsDetailsContext from "./context";

const Webhooks = () => {
  const { formData, isLoadingData, refreshData } = useContext(PaymentsDetailsContext);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(formData.webhooks)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
      </HStack>

      <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(formData.webhooks, (item) => (
          <HStack key={item._id}>
            <Center w="40px" h="40px" borderRadius="full" bg="main.100">
              <Icon as={MdOutlineWebhook} color="main.500" />
            </Center>
            <Box flex="1">
              <Badge textTransform="none">{item.event}</Badge>
              <Text fontSize="xs">Recebido em {moment(item.createdAt).format("DD/MM/YYYY")}</Text>
            </Box>
          </HStack>
        ))}
      </VStack>

      {isLoadingData ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(formData.webhooks) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdOutlineWebhook} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum webhook recebido
              </Text>
              <Text fontSize="sm">Este pagamento ainda não possui webhooks recebidos.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Webhooks;
