import React, { useState, useEffect, useMemo, useCallback, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { useCustomToast } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import InvoicesDetailsContext from "./context";
import { BiSupport } from "react-icons/bi";

const SentToCollectionAgency = () => {
  const { _id } = useParams();
  const { formData: parentFormData, refreshData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, preposition] = useMemo(
    () => (parentFormData.sentToCollectionAgency ? ["remover", "da"] : ["enviar", "para"]),
    [parentFormData.sentToCollectionAgency]
  );
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ _id, sentToCollectionAgency: !parentFormData.sentToCollectionAgency, _actionType: "all" });
  }, [_id, parentFormData.sentToCollectionAgency]);

  const handleSaveData = useCallback(async (data) => {
    try {
      setIsLoadingSaveData(true);
      await api.patch(`/private/invoices/${_id}/collection-agency`, data);
      refreshData();
      onClose();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        _actionType: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData, onClose);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      {parentFormData.isOverdue && (
        <PermissionedContainer required="invoices.sendToCollectionAgency">
          <Button size="sm" colorScheme="purple" leftIcon={<Icon as={BiSupport} />} onClick={onOpen}>
            {action} {preposition} cobrança terceirizada
          </Button>
        </PermissionedContainer>
      )}
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cobrança terceirizada</AlertDialogHeader>
          <AlertDialogBody>
            <Text mb={4}>
              Deseja {action} somente esta fatura {preposition} cobrança terceirizada, ou deseja aplicar esta ação a todas as faturas?
            </Text>
            <FormControl isRequired={true} isInvalid={formErrors._actionType}>
              <FormLabel fontSize="sm" mb="5px">
                Tipo ação
              </FormLabel>
              <Select
                value={formData._actionType || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, _actionType: target.value }))}
              >
                <option value="all">Todas as faturas</option>
                <option value="one">Somente esta fatura</option>
              </Select>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="purple" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              {action} {preposition} cobrança terceirizada
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default SentToCollectionAgency;
