import React, { useMemo, useState } from "react";
import { Button, Center, Heading, HStack, Icon, IconButton, Spinner } from "@chakra-ui/react";
import _ from "lodash";
import ObjectID from "bson-objectid";
import { Breadcrumb, PermissionedContainer } from "components";
import { useFetchData, useDocumentTitle } from "hooks";
import { Content } from "pages/Private/Container";
import { List } from "./list";
import { MdRefresh } from "react-icons/md";
import StatusesListContext from "./context";
import { StatusesDetails } from "./details";

export const StatusesList = () => {
  useDocumentTitle("Status");
  const [statuses, isLoadingStatuses, refreshStatuses] = useFetchData(
    useMemo(() => ({ path: "/private/statuses", params: { perPage: -1, sort: { createdAt: 1 } } }), [])
  );
  const items = useMemo(() => {
    const { parents, ...grouped } = _.groupBy(statuses?.data, (o) => o.parent || "parents");
    for (const key in grouped) {
      const index = parents.findIndex((o) => o._id === key);
      parents[index].items = _(grouped[key]).sortBy("ordination").value();
    }
    return _(parents).sortBy("ordination").value();
  }, [statuses?.data]);
  const [selected, setSelected] = useState();
  const droppableId = useMemo(() => ObjectID().toHexString(), []);

  return (
    <StatusesListContext.Provider value={{ statuses, isLoadingStatuses, refreshStatuses, selected, setSelected }}>
      <Content>
        <HStack justify="space-between">
          <Breadcrumb items={[{ label: "configurações" }, { to: "/statuses", label: "status" }]} />
          <HStack>
            <PermissionedContainer required="statuses.create">
              <Button size="sm" colorScheme="main" onClick={() => setSelected({ isActive: true })}>
                incluir cadastro
              </Button>
            </PermissionedContainer>
          </HStack>
        </HStack>

        <HStack my="15px" justifyContent="space-between">
          <Heading size="md">Status</Heading>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingStatuses} onClick={refreshStatuses} />
        </HStack>

        <List type="OUTER" {...{ droppableId, items }} />

        {isLoadingStatuses && (
          <Center p="40px">
            <Spinner />
          </Center>
        )}
      </Content>

      <StatusesDetails />
    </StatusesListContext.Provider>
  );
};
