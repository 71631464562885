import React, { useCallback, useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToken,
} from "@chakra-ui/react";
import { EventEmitter } from "lib";
import { Lottie } from "components";
import { MdLockOpen } from "react-icons/md";
import animationData from "assets/lottie/fingerprint.json";

export const InvalidAuthorization = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [main500] = useToken("colors", ["main.500"]);

  useEffect(() => {
    const listener = EventEmitter.addListener("onAuthorizationError", onOpen);
    return () => listener.remove();
  }, [onOpen]);

  const handleSignOut = useCallback(async () => {
    setIsLoading(true);
    setTimeout(() => {
      const auth = getAuth();
      signOut(auth);
      onClose();
    }, 1000);
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" justifyContent="center" alignItems="center" padding="30px">
          <Lottie replaceColors={[{ from: [8, 69, 221], to: main500 }]} animationData={animationData} style={{ width: 200 }} />
          <Heading size="lg">Autorização inválida</Heading>
          <Text fontSize="sm" textAlign="center" mb="20px">
            Aparentemente sua autorização está inválida. Por favor, realize um novo login para renovar suas credenciais. Se esta mensagem
            persistir, entre em contato com nosso suporte.
          </Text>
          <Button variant="outline" leftIcon={<Icon as={MdLockOpen} />} isLoading={isLoading} onClick={handleSignOut}>
            Realizar novo login
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
