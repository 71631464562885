import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { SyncSelect } from "components";
import { DocumentHistory } from "containers";
import { api, yup } from "lib";
import { messages } from "consts";
import { useCustomToast } from "hooks";
import { MdHistory } from "react-icons/md";
import StatusesListContext from "../context";
import StatusesDetailsContext from "./context";
import WhatsApp from "./whatsapp";
import Email from "./email";

export const colorSchemes = {
  gray: "Cinza",
  red: "Vermelho",
  orange: "Laranja",
  yellow: "Amarelo",
  green: "Verde",
  teal: "Verde-azulado",
  blue: "Azul",
  cyan: "Ciano",
  purple: "Roxo",
  pink: "Rosa",
};

export const StatusesDetails = () => {
  const { statuses, refreshStatuses, selected, setSelected } = useContext(StatusesListContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = selected ?? {};
    setFormData(formData);
  }, [selected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        data._id ? await api.patch("/private/statuses", [data]) : await api.put("/private/statuses", [data]);
        setSelected();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshStatuses();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [refreshStatuses, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const filter = formData.parent ? (o) => o.parent === formData.parent : (o) => !o.parent;
      const data = { ...formData, ordination: _.isString(formData._id) ? undefined : _(statuses?.data).filter(filter).size() };
      const shape = {
        title: yup.string().required(messages.error.required),
        colorScheme: yup.string().required(messages.error.required),
      };
      for (const path of ["incomingWhatsapp", "outgoingWhatsapp"]) {
        if (_.isObject(formData[path]))
          shape[path] = yup.object().shape({
            templateId: yup.string().required(messages.error.required),
          });
      }
      for (const path of ["incomingEmail", "outgoingEmail"]) {
        if (_.isObject(formData[path]))
          shape[path] = yup.object().shape({
            subject: yup.string(),
            body: yup.string().required(messages.error.required),
          });
      }
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [statuses?.data, formData, handleSaveData]);

  return (
    <StatusesDetailsContext.Provider value={{ formData, setFormData, formErrors }}>
      <Drawer size="md" isOpen={_.isObject(selected)} onClose={() => setSelected()}>
        <DrawerOverlay zIndex="1400" />
        <DrawerContent zIndex="1401">
          <DrawerHeader as={HStack}>
            <Box flex="1">
              <Heading size="md">Status</Heading>
              <Text fontSize="sm" fontWeight="light">
                {selected?._id ?? "Novo cadastro"}
              </Text>
            </Box>
            <HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontSize="sm" fontWeight="light" mb="0">
                  Ativo?
                </FormLabel>
                <Switch
                  colorScheme="main"
                  isChecked={formData.isActive}
                  onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
                />
              </FormControl>
            </HStack>
            {formData._id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.title}>
                  <FormLabel fontSize="sm">Título</FormLabel>
                  <Input
                    value={formData.title ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.title}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isRequired={true} isInvalid={formErrors.colorScheme}>
                  <FormLabel fontSize="sm">Esquema de cores</FormLabel>
                  <SyncSelect
                    value={{ value: formData.colorScheme, label: colorSchemes[formData.colorScheme] }}
                    placeholder="Selecione"
                    options={Object.entries(colorSchemes).map(([value, label]) => ({ value, label }))}
                    onChange={({ value }) => setFormData((state) => ({ ...state, colorScheme: value }))}
                    formatOptionLabel={({ value, label }) => (
                      <HStack>
                        <Box bg={`${value}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>{label}</Text>
                      </HStack>
                    )}
                  />
                  <FormErrorMessage>{formErrors.colorScheme}</FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={formErrors.description}>
                  <FormLabel fontSize="sm">Descrição</FormLabel>
                  <Textarea
                    value={formData.description ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.description}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            {formData.parent && (
              <VStack alignItems="stretch" spacing={4}>
                <WhatsApp title="WhatsApp | Entrada" path="incomingWhatsapp" />
                <WhatsApp title="WhatsApp | Saída" path="outgoingWhatsapp" />
                <Email title="E-mail | Entrada" path="incomingEmail" />
                <Email title="E-mail | Saída" path="outgoingEmail" />
              </VStack>
            )}
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack}>
            <Button size="sm" variant="ghost" onClick={() => setSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>

        {formData._id && (
          <DocumentHistory
            path={`/private/statuses/${formData._id}/history`}
            isOpen={isOpenDocumentHistory}
            onClose={onCloseDocumentHistory}
          />
        )}
      </Drawer>
    </StatusesDetailsContext.Provider>
  );
};
