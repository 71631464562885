export const currency = (value = 0, options = { precision: 2, compact: false }) => {
  const formatted = (value || 0).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: options.precision,
    maximumFractionDigits: options.precision,
  });
  if (options.compact) return formatted.replace(/\s/g, "").replaceAll(".", "");
  return formatted;
};

export const percent = (value = 0, options = { precision: 2, compact: false }) => {
  const formatted = (value || 0).toLocaleString("pt-BR", {
    style: "percent",
    minimumFractionDigits: options.precision,
    maximumFractionDigits: options.precision,
  });
  if (options.compact) return formatted.replaceAll(".", "");
  return formatted;
};

export const locale = (value = 0, options = { precision: 2, compact: false }) => {
  const formatted = (value || 0).toLocaleString("pt-BR", {
    minimumFractionDigits: options.precision,
    maximumFractionDigits: options.precision,
  });
  if (options.compact) return formatted.replaceAll(".", "");
  return formatted;
};
