import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import InputMask from "react-input-mask";
import { HStack, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

export const RangeDateInput = ({ defaultStartDate, defaultEndDate, onChange }) => {
  const [formData, setFormData] = useState({
    startDate: defaultStartDate ? moment(defaultStartDate).format("DD/MM/YYYY") : null,
    endDate: defaultEndDate ? moment(defaultEndDate).format("DD/MM/YYYY") : null,
  });

  useEffect(() => {
    const startDate = moment(formData.startDate, "DD/MM/YYYY");
    const endDate = moment(formData.endDate, "DD/MM/YYYY");
    onChange(startDate.isValid() ? startDate.startOf("day").toDate() : null, endDate.isValid() ? endDate.endOf("day").toDate() : null);
  }, [formData]);

  return (
    <HStack spacing="1px" w="100%">
      <InputGroup size="sm" variant="filled">
        <InputLeftElement fontSize="xs">de</InputLeftElement>
        <Input
          borderRightRadius="0"
          as={InputMask}
          mask="99/99/9999"
          value={formData.startDate ?? ""}
          onChange={({ target }) => setFormData((state) => ({ ...state, startDate: target.value }))}
        />
      </InputGroup>
      <InputGroup size="sm" variant="filled">
        <InputLeftElement fontSize="xs">até</InputLeftElement>
        <Input
          borderLeftRadius="0"
          as={InputMask}
          mask="99/99/9999"
          value={formData.endDate ?? ""}
          onChange={({ target }) => setFormData((state) => ({ ...state, endDate: target.value }))}
        />
      </InputGroup>
    </HStack>
  );
};
