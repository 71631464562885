import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DocumentHistory } from "containers";
import { useFetchData, useQueryParams, useWatcherZIndex } from "hooks";
import { MdHistory } from "react-icons/md";
import ApprovalsDetailsContext from "./context";
import General from "./general";
import Webhooks from "./webhooks";
import StatusActions from "./statusActions";
import Chunk from "./chunk";

const queryParamsKey = "approval_id";

export const ApprovalsDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/approvals/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const isAllowedChunk = useMemo(
    () => _.includes(["processing", "created", "authorized", "cancelled", "failed"], formData.status) === false,
    [formData.status]
  );

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  return (
    <ApprovalsDetailsContext.Provider value={{ formData, isLoadingData, refreshData }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <HStack flex="1">
              <Text>Aprovação</Text>
              {isLoadingData ? <Spinner size="sm" /> : <Text>#{formData.nid || "-"}</Text>}
            </HStack>
            {isAllowedChunk && <Chunk />}
            <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Tabs variant="solid-rounded" size="sm" isFitted colorScheme="main">
              <TabList>
                <Tab>dados gerais</Tab>
                <Tab>webhooks</Tab>
              </TabList>
              <TabPanels>
                <TabPanel py="20px" px="0">
                  <General />
                </TabPanel>
                <TabPanel py="20px" px="0">
                  <Webhooks />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <Divider />
          <DrawerFooter as={HStack}>
            <StatusActions />
            <Box flex="1" />
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <DocumentHistory path={`/private/approvals/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
    </ApprovalsDetailsContext.Provider>
  );
};
