import React, { Fragment, memo } from "react";
import {
  Box,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { StatusBadge } from "components";
import { MdHistory } from "react-icons/md";

const Status = memo(({ contract }) => {
  return (
    <Fragment>
      <StatusBadge schema="contracts" status={contract.status} />
      <Popover>
        <PopoverTrigger>
          <IconButton size="xs" variant="outline" icon={<Icon as={MdHistory} />} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize="sm">Mudanças de status</PopoverHeader>
          <PopoverBody as={VStack} divider={<StackDivider />} alignItems="stretch">
            {_.map(contract?.stages, (item) => (
              <Box key={item._id}>
                <StatusBadge schema="contracts" status={item.status} />
                <Text fontSize="xs">{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Fragment>
  );
});

export default Status;
