import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { InputCurrency } from "components";
import { HiOutlineCalculator } from "react-icons/hi";

const getDefaultMonths = () => {
  const response = {};
  for (let i = 1; i <= 12; i++) response["month".concat(i)] = 0;
  return response;
};

export const ElectricityAverageCalculator = ({ data, onChange, isDisabled }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [formData, setFormData] = useState(getDefaultMonths());
  const average = useMemo(() => {
    const values = Object.values(formData).filter((v) => v > 0);
    return _.sum(values) / _.size(values) || 0;
  }, [formData]);

  useEffect(() => {
    setFormData(data ?? getDefaultMonths());
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    onChange(formData, average);
    onClose();
  }, [formData, average]);

  return (
    <>
      <IconButton size="sm" icon={<Icon as={HiOutlineCalculator} />} onClick={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Text fontSize="md">Calculadora de média</Text>
          </ModalHeader>
          <Divider />
          <ModalBody as={VStack} divider={<StackDivider />} alignItems="stretch">
            {Object.keys(formData).map((key, index) => (
              <HStack key={index.toString()}>
                <Text fontSize="xs" whiteSpace="nowrap">
                  Mês {index + 1}
                </Text>
                <Input
                  size="xs"
                  precision="0"
                  as={InputCurrency}
                  value={formData[key] || 0}
                  onChange={(value) => setFormData((state) => ({ ...state, [key]: value }))}
                />
                <Text fontSize="xs">kWh</Text>
              </HStack>
            ))}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Text flex="1" fontWeight="semibold">
              {average?.toLocaleString() || 0}kWh
            </Text>
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
            {!isDisabled && (
              <Button size="sm" colorScheme="main" onClick={handleSubmit}>
                finalizar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
