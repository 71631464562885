import { createContext, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { api } from "lib";
import Advanced from "./advanced";
import Simplified from "./simplified";
import CustomersDetailsContext from "../context";

export const GeneralContext = createContext();

let loadCustomersTimeout = null;

const General = () => {
  const { _id } = useParams();
  const { isAdvancedMode } = useContext(CustomersDetailsContext);

  const handleLoadCustomers = useCallback(
    (search, cb) => {
      clearTimeout(loadCustomersTimeout);
      loadCustomersTimeout = setTimeout(async () => {
        const query = { _id: { $ne: _id }, isActive: true };
        const response = await api.post("/private/customers", { search, query, perPage: -1, isAutocomplete: true });
        cb(response?.data ?? []);
      }, 1000);
    },
    [_id]
  );

  return (
    <GeneralContext.Provider value={{ handleLoadCustomers }}>{isAdvancedMode ? <Advanced /> : <Simplified />}</GeneralContext.Provider>
  );
};

export default General;
