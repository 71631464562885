import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ObjectID from "bson-objectid";
import InputMask from "react-input-mask";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Breadcrumb, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdAdd, MdChevronLeft, MdHistory, MdOutlineInsertPageBreak } from "react-icons/md";
import { Content } from "pages/Private/Container";
import Page from "./page";
import InvoicesTemplatesDetailsContext from "./context";

export const InvoiceTemplatesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar template de fatura" : "Nova template de fatura");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/invoice-templates/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: false };
    if (formData.expiresAt) formData.expiresAt = moment(formData.expiresAt).format("DD/MM/YYYY");
    if (formData._id && _.size(formData.pages) === 0) formData.pages = [{ _id: ObjectID().toHexString(), link: "" }];
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/invoice-templates/${_id}`, data) : await api.put("/private/invoice-templates", data);
        navigate(`/invoice-templates/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        expiresAt: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = { ...formData, expiresAt: moment(formData.expiresAt, "DD/MM/YYYY").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setFormData((state) => {
      const pages = [...state.pages];
      const [removed] = pages.splice(result.source.index, 1);
      pages.splice(result.destination.index, 0, removed);
      return { ...state, pages };
    });
  }, []);

  const handleAddPage = useCallback(() => {
    setFormData((state) => {
      const pages = [...state.pages];
      pages.push({ _id: ObjectID().toHexString(), link: pages.length.toString() });
      return { ...state, pages };
    });
  }, []);

  return (
    <InvoicesTemplatesDetailsContext.Provider value={{ formData, setFormData }}>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/invoice-templates")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/invoice-templates", label: "templates de faturas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Template de fatura</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors.nid}>
              <FormLabel fontSize="sm">NID</FormLabel>
              <Input value={formData.nid ?? ""} isDisabled={true} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={7}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isRequired={true} isInvalid={formErrors.expiresAt}>
              <FormLabel fontSize="sm">Data de expiração</FormLabel>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.expiresAt ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, expiresAt: target.value }))}
              />
              <FormErrorMessage>{formErrors.expiresAt}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        {_id ? (
          <VStack>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <VStack {...provided.droppableProps} ref={provided.innerRef} align="stretch">
                    {_.map(formData.pages, (item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided, snapshot) => <Page {...{ provided, snapshot, item }} />}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </DragDropContext>

            <PermissionedContainer required="invoiceTemplates.addExtraPages">
              <Button variant="outline" leftIcon={<Icon as={MdAdd} />} onClick={handleAddPage}>
                adicionar página
              </Button>
            </PermissionedContainer>
          </VStack>
        ) : (
          <VStack spacing={0}>
            <Icon as={MdOutlineInsertPageBreak} boxSize="80px" mb={4} />
            <Heading size="md">Nenhuma página adicionada</Heading>
            <Text fontSize="sm">Antes de adicionar páginas você deve salvar o documento atual.</Text>
          </VStack>
        )}
      </Content>

      <PermissionedContainer required={"invoiceTemplates.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/invoice-templates")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory
          path={`/private/invoice-templates/${_id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </InvoicesTemplatesDetailsContext.Provider>
  );
};
