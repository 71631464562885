import React, { memo, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { masks } from "lib";
import { usePermissioned } from "hooks";
import { TbExternalLink } from "react-icons/tb";
import { GoLaw } from "react-icons/go";
import { AppContext } from "AppProvider";
import { PrivateContext } from "pages/Private";

const EconomicGroup = memo(({ data }) => {
  const { currentUser } = useContext(PrivateContext);
  const isAllowedCustomersRead = usePermissioned("customers.read");

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="xs" colorScheme="blue">
          Grupo econômico
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Grupo econômico</PopoverHeader>
        <PopoverBody as={VStack} divider={<StackDivider />} alignItems="stretch">
          {_.map(data, (item) => {
            const isAllowed = isAllowedCustomersRead || item.customer.createdBy === currentUser._id;
            return (
              <HStack key={item._id} py="10px">
                <Center bg="blue.100" w="35px" h="35px" borderRadius="full">
                  <Icon as={GoLaw} color="blue.500" />
                </Center>
                <Box>
                  <Text fontWeight="semibold" fontSize="sm">
                    {item.customer.nid} • {item.customer.name}
                  </Text>
                  {isAllowed && <Text fontSize="xs">{masks.document(item.customer.document)}</Text>}
                </Box>
                <Box flex="1" />
                {isAllowed && (
                  <IconButton
                    as={RouterLink}
                    to={`/customers/edit/${item.customer._id}`}
                    target="_blank"
                    size="xs"
                    variant="outline"
                    icon={<Icon as={TbExternalLink} />}
                  />
                )}
              </HStack>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

export default EconomicGroup;
