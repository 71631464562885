import React, { useCallback, useContext } from "react";
import { getAuth, signOut } from "firebase/auth";
import {
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { Logo, Portal } from "components";
import { MdArrowDropDown, MdMenu, MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { HiVariable } from "react-icons/hi";
import { IoLockClosedOutline, IoLogOutOutline } from "react-icons/io5";
import { PrivateContext } from "../index";
import { ContainerContext } from "./index";
import ContractVariables from "./contractVariables";
import PasswordChange from "./passwordChange";

const Toolbar = () => {
  const { currentUser } = useContext(PrivateContext);
  const { isMobileView, onOpenSidebar } = useContext(ContainerContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen: isOpenContractVariables, onOpen: onOpenContractVariables, onClose: onCloseContractVariables } = useDisclosure();
  const { isOpen: isOpenPasswordChange, onOpen: onOpenPasswordChange, onClose: onClosePasswordChange } = useDisclosure();

  const handleSignOut = useCallback(() => {
    const auth = getAuth();
    signOut(auth);
  }, []);

  // useEffect(() => {
  //   const version = process.env.REACT_APP_VERSION.split(".").pop();
  //   if (parseInt(version) <= 177) handleSignOut();
  // }, [handleSignOut]);

  return (
    <>
      <HStack h="50px" px="10px" spacing={2} _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }}>
        <HStack>
          {isMobileView && <IconButton variant="ghost" isRound icon={<Icon as={MdMenu} />} onClick={onOpenSidebar} />}
          <Logo width={70} />
        </HStack>
        <Box flex="1" />
        <Tooltip label={`Tema ${colorMode === "light" ? "escuro" : "claro"}`}>
          <IconButton
            variant="ghost"
            icon={<Icon as={colorMode === "light" ? MdOutlineLightMode : MdOutlineDarkMode} />}
            onClick={toggleColorMode}
            isRound
          />
        </Tooltip>
        <Box>
          <Menu placement="bottom">
            <MenuButton
              as={Button}
              px={2}
              leftIcon={<Avatar size="sm" />}
              rightIcon={<Icon as={MdArrowDropDown} />}
              variant="ghost"
              textAlign="left"
              borderRadius="full"
            >
              <Text fontSize="xs">{currentUser.name}</Text>
              <Text fontSize="x-small" fontWeight="normal">
                {currentUser.email}
              </Text>
            </MenuButton>
            <Portal>
              <MenuList>
                <Box textAlign="center" p="10px">
                  <Avatar size="lg" marginBottom="10px" />
                  <Text fontWeight="bold" maxW="250px" noOfLines={1}>
                    {currentUser.name}
                  </Text>
                  {currentUser.email && <Text fontSize="xs">{currentUser.email}</Text>}
                  <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
                </Box>
                <MenuItem onClick={onOpenContractVariables}>
                  <Icon as={HiVariable} marginRight="10px" />
                  <Text fontSize="xs">Variáveis dos contratos</Text>
                </MenuItem>
                <MenuItem onClick={onOpenPasswordChange}>
                  <Icon as={IoLockClosedOutline} marginRight="10px" />
                  <Text fontSize="xs">Alterar senha</Text>
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleSignOut}>
                  <Icon as={IoLogOutOutline} marginRight="10px" />
                  <Text fontSize="xs">Sair da minha conta</Text>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Box>
      </HStack>
      <ContractVariables isOpen={isOpenContractVariables} onClose={onCloseContractVariables} />
      <PasswordChange isOpen={isOpenPasswordChange} onClose={onClosePasswordChange} />
    </>
  );
};

export default Toolbar;
