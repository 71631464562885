import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Button, Icon } from "@chakra-ui/react";
import { currency, translator } from "lib";
import { CustomBadge, StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="approvals" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "dueDate",
    title: "Vencimento",
    isVisible: true,
    formatter: ({ dueDate }) => (dueDate ? moment(dueDate).format("DD/MM/YYYY") : "-"),
  },
  {
    accessor: "amount",
    title: "Valor",
    isVisible: true,
    render: ({ amount }) => currency(amount),
    exporter: ({ amount }) => currency(amount, { compact: true }),
    renderFoot: ({ amount }) => currency(amount),
  },
  {
    accessor: "paymentsCount",
    title: "Pagamentos",
    isVisible: true,
    isSortable: false,
    render: ({ _id, paymentsCount }) => (
      <Button
        size="xs"
        variant="outline"
        rightIcon={<Icon as={TbExternalLink} />}
        as={RouterLink}
        to={`/payments?filter_approval_id=${_id}`}
      >
        {paymentsCount.toLocaleString()} registros
      </Button>
    ),
    exporter: ({ paymentsCount }) => paymentsCount.toLocaleString(),
  },
  {
    accessor: "validatedBy",
    title: "Validado por",
    isVisible: true,
    formatter: ({ validatedBy }) => validatedBy?.name || "-",
  },
  {
    accessor: "validatedAt",
    title: "Validado em",
    isVisible: true,
    formatter: ({ validatedAt }) => (validatedAt ? moment(validatedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "invalidatedBy",
    title: "Invalidado por",
    isVisible: true,
    formatter: ({ invalidatedBy }) => invalidatedBy?.name || "-",
  },
  {
    accessor: "invalidatedAt",
    title: "Invalidado em",
    isVisible: true,
    formatter: ({ invalidatedAt }) => (invalidatedAt ? moment(invalidatedAt).format("DD/MM/YYYY [às] HH:mm") : "-"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm"),
  },
];

export default defaultColumns;
