import React, { memo, useMemo } from "react";
import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { HiBolt } from "react-icons/hi2";
import { HiOutlineDocumentText } from "react-icons/hi";
import { StatusBadge } from "components";

const Contracts = memo(({ contracts }) => {
  const isPending = useMemo(() => _.find(contracts, (o) => !o.isClosed), [contracts]);

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton size="xs" borderRadius="full" colorScheme={isPending ? "orange" : "green"} icon={<Icon as={HiOutlineDocumentText} />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Contratos</PopoverHeader>
        <PopoverBody as={VStack} alignItems="stretch">
          {_.map(contracts, (item) => (
            <HStack key={item._id}>
              <Center bg="main.100" w="35px" h="35px" borderRadius="full">
                <Icon as={HiBolt} color="main.500" />
              </Center>
              <Box flex="1">
                <Text fontWeight="semibold">{_.map(item.consumerUnits, "cemigInstallationNumber").join(", ")}</Text>
                <StatusBadge schema="contracts" status={item.status} />
              </Box>
            </HStack>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

export default Contracts;
