import React, { useMemo, useRef, useCallback, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import fileDownload from "js-file-download";
import { api } from "lib";
import { Breadcrumb, ExportCsv, Paginator, PermissionedContainer, Portal, TableDrawer, TableEmpty } from "components";
import { useFetchData, useCacheState, useClipboard, useCustomToast, useDocumentTitle, useTable } from "hooks";
import { MdMoreHoriz, MdOutlineDownload, MdRefresh, MdSearch } from "react-icons/md";
import { BiCog } from "react-icons/bi";
import { FiClipboard, FiEdit } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import InvoiceCapturesContext from "./context";
import Queue from "./queue";
import Upload from "./upload";

export const InvoiceCapturesList = () => {
  useDocumentTitle("Capturas de faturas");
  const location = useLocation();
  const navigate = useNavigate();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "InvoiceCapturesList.sort", defaultValue: { createdAt: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "InvoiceCapturesList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "InvoiceCapturesList.perPage", defaultValue: 100 }), []));
  const [search, setSearch] = useCacheState(useMemo(() => ({ key: "InvoiceCapturesList.search", defaultValue: "" }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(() => {
      const query = { status: { $nin: ["waiting", "in_progress"] } };
      return { path: "/private/invoice-captures", params: { query, sort, page, perPage, search } };
    }, [sort, page, perPage, search])
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 4 }), [location.pathname])
  );
  const [isDownloading, setIsDownloading] = useState({});
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();
  const toast = useCustomToast();

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    setSearch(value);
    setPage(0);
  }, []);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleDownloadData = useCallback(async (item) => {
    try {
      setIsDownloading((state) => ({ ...state, [item._id]: true }));
      const data = await api({
        method: "post",
        url: `/private/files/${item._id}/object`,
        responseType: "blob",
      });
      fileDownload(data, `${item.title}.${item.ext}`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading((state) => ({ ...state, [item._id]: false }));
    }
  }, []);

  return (
    <InvoiceCapturesContext.Provider value={{ refreshData, timestampData }}>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "faturas" }, { to: "/invoice-captures", label: "capturas de faturas" }]} />
            <SlideFade in={true} offsetY="-20px">
              <HStack>
                <Upload />
                <PermissionedContainer required={["invoiceCaptures.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="invoiceCaptures.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar capturas de faturas</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>

          <Queue />

          <Heading my="15px" size="md">
            Capturas de faturas
          </Heading>
          <HStack mb="30px">
            <form onSubmit={handleSearch}>
              <InputGroup width="xs">
                <Input variant="filled" placeholder="Pesquisar..." defaultValue={search} />
                <InputRightElement>
                  <IconButton type="submit" size="sm" variant="ghost" icon={<Icon as={MdSearch} />} isLoading={isLoadingData} />
                </InputRightElement>
              </InputGroup>
            </form>
            <HStack flex="1" justify="flex-end">
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
              <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={() => tableDrawerRef.current.open()} />
            </HStack>
          </HStack>
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                <Th>#</Th>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr
                  key={item._id}
                  cursor="pointer"
                  _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                  _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                  onDoubleClick={() => navigate(`details/${item._id}`)}
                >
                  <Td>
                    <Box>
                      <Menu placement="right-start">
                        <MenuButton
                          as={IconButton}
                          size="xs"
                          colorScheme="main"
                          icon={<Icon as={MdMoreHoriz} />}
                          isLoading={isDownloading[item.file._id]}
                        />
                        <MenuList>
                          <MenuGroup title={item.name} pb="5px">
                            <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                              copiar código
                            </MenuItem>
                            <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`details/${item._id}`}>
                              detalhamento
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem icon={<Icon as={MdOutlineDownload} />} onClick={() => handleDownloadData(item.file)}>
                              baixar arquivo
                            </MenuItem>
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Td>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item)}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="capturas_de_faturas"
        onFetchData={fetchAllPages}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />
    </InvoiceCapturesContext.Provider>
  );
};
