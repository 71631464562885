import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Box, Button, FormControl, FormLabel, Grid, GridItem, HStack, Icon, IconButton, Input, useColorModeValue } from "@chakra-ui/react";
import { api, setOneOrMany } from "lib";
import { AsyncSelect, RangeDateInput } from "components";
import { useCacheState, useNewTabState, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadPowerPlantsTimeout;

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? {},
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          referenceDateStart: data.referenceDateStart && moment(data.referenceDateStart).toDate(),
          referenceDateEnd: data.referenceDateEnd && moment(data.referenceDateEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const mapObjectId = (data) => _.map(data, ({ _id }) => ["@ObjectId", _id]);
    setOneOrMany("referenceCode", query.referenceCode, response);
    
    setOneOrMany(`installationNumber`, query.installationNumber, response);
    const instNum = _.get(response, "installationNumber");
    if (_.isString(instNum)) _.set(response, "installationNumber", { $regex: instNum.concat("$") });

    if (query.referenceDateStart) _.set(response, `referenceDate.$gte`, ["@ISODate", query.referenceDateStart]);
    if (query.referenceDateEnd) _.set(response, `referenceDate.$lte`, ["@ISODate", query.referenceDateEnd]);
    if (query.powerPlant?.length) _.set(response, "powerPlant.$in", mapObjectId(query.powerPlant));
    if (query.createdAtStart) _.set(response, "createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "createdAt.$lte", ["@ISODate", query.createdAtEnd]);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadPowerPlants = useCallback((search, cb) => {
    clearTimeout(loadPowerPlantsTimeout);
    loadPowerPlantsTimeout = setTimeout(async () => {
      const response = await api.post("/private/power-plants", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <HStack spacing={4} wrap={{ base: "wrap", lg: "nowrap" }} direction={{ base: "column", lg: "row" }} mb={8}>
        <HStack w={{ base: "100%", lg: "auto" }}>
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme={isFiltering ? "main" : "gray"}
            variant="outline"
            rightIcon={<Icon as={HiOutlineFilter} />}
            onClick={() => setIsOpen((state) => !state)}
          >
            filtros
          </Button>
          {isFiltering && (
            <Button flex={{ base: "1", lg: "none" }} variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
              limpar filtros
            </Button>
          )}
        </HStack>
        <Box minW={{ base: "full", lg: "xs" }}>
          <Input
            placeholder="N° de instalação"
            value={formData.installationNumber ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
          />
        </Box>
        <HStack flex="1" w={{ base: "100%", lg: "auto" }} justifyContent="flex-end">
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme="main"
            rightIcon={<Icon as={MdSearch} />}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            aplicar
          </Button>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
          <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cód. referência
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.referenceCode ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, referenceCode: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Usina
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.powerPlant ?? []}
                  defaultOptions
                  loadOptions={handleLoadPowerPlants}
                  placeholder="Selecione"
                  onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Data de referência
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.referenceDateStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.referenceDateStart}
                    defaultEndDate={formData.referenceDateEnd}
                    onChange={(referenceDateStart, referenceDateEnd) =>
                      setFormData((state) => ({ ...state, referenceDateStart, referenceDateEnd }))
                    }
                  />
                  {formData.referenceDateStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          referenceDateStart: null,
                          referenceDateEnd: null,
                          referenceDateStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
