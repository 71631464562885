import React, { useMemo } from "react";
import _ from "lodash";
import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useFetchData, useClipboard } from "hooks";
import { MdRefresh } from "react-icons/md";
import { FiClipboard } from "react-icons/fi";

const ContractVariables = ({ isOpen, onClose }) => {
  const [variables, isLoadingVariables, refreshVariables] = useFetchData(
    useMemo(() => ({ path: "/private/contract-templates/variables", options: { isEnabled: isOpen } }), [isOpen])
  );
  const copyToClipboard = useClipboard();

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Variáveis disponíveis nos contratos</Text>
          <IconButton
            size="sm"
            variant="outline"
            icon={<Icon as={MdRefresh} />}
            isLoading={isLoadingVariables}
            onClick={refreshVariables}
          />
        </ModalHeader>
        <Divider />
        <ModalBody>
          <Grid templateColumns="repeat(12, 1fr)" gap={2}>
            {_.map(variables, (item) => (
              <GridItem key={item} colSpan={{ base: 12, lg: 4 }}>
                <Button
                  size="sm"
                  variant="outline"
                  w="100%"
                  leftIcon={<Icon as={FiClipboard} />}
                  onClick={() => copyToClipboard(`[${item}]`)}
                >
                  <Text flex="1" textAlign="left">
                    {`[${item}]`}
                  </Text>
                </Button>
              </GridItem>
            ))}
          </Grid>
          {isLoadingVariables && (
            <Center p="40px">
              <Spinner />
            </Center>
          )}
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContractVariables;
