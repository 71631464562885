import React, { useCallback, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "hooks";
import { messages, firebaseAuthErrors } from "consts";
import * as yup from "yup";
import { MdKeyboardArrowRight, MdMail } from "react-icons/md";

const __DEV__ = process.env.NODE_ENV === "development";

export const PasswordReset = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(__DEV__ ? { email: "marcotulio.magalhaes@gmail.com" } : {});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const handleSignIn = useCallback(
    async ({ email }) => {
      try {
        setIsLoading(true);
        const auth = getAuth();
        await sendPasswordResetEmail(auth, email);
        toast({ description: messages.success.passwordResetEmail, status: "success", isClosable: true });
        navigate("/sign-in", { replace: true });
      } catch (error) {
        if (error.isHandled) return;
        const description = firebaseAuthErrors[error.code] ?? error.message;
        toast({ description, status: "error", duration: 5000, isClosable: true });
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          email: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSignIn(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleSignIn, formData]
  );

  return (
    <Box p={8}>
      <VStack mb={4}>
        <Heading size="lg">Esqueceu a senha?</Heading>
        <Text fontSize="sm" textAlign="center">
          Informe seu e-mail no campo abaixo para que possamos enviar o link de alteração de senha.
        </Text>
      </VStack>
      <form onSubmit={handleSubmit}>
        <VStack align="stretch" spacing="20px">
          <FormControl isRequired={true} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={MdMail} />
              </InputLeftAddon>
              <Input
                value={formData.email ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                autoFocus={true}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
          <HStack justifyContent="flex-end">
            <Button as={RouterLink} to={-1} variant="ghost">
              Cancelar
            </Button>
            <Button type="submit" colorScheme="main" rightIcon={<Icon as={MdKeyboardArrowRight} />} isLoading={isLoading}>
              Enviar
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};
