import React, { useState, useEffect, useContext, Fragment, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { CheckboxBody, CheckboxProvider, PermissionedContainer } from "components";
import { MdErrorOutline, MdCheckCircleOutline, MdSearch, MdRefresh } from "react-icons/md";
import InvoicesDetailsContext from "./context";
import { useFetchData, useSearch } from "hooks";
import { messages } from "consts";
import { yup } from "lib";

const Analyze = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { isLoadingSaveData, handleSaveData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [searchText, setSearchText] = useState("");
  const [_reasons, isLoadingReasons, refreshReasons] = useFetchData(useMemo(() => ({ path: "/private/invoice-invalidation-reasons" }), []));
  const reasons = useSearch(useMemo(() => ({ keys: ["title"], data: _reasons?.data || [], searchText }), [_reasons?.data, searchText]));
  const [checkeds, setCheckeds] = useState([]);

  useEffect(() => {
    setFormData({ _id });
    setFormErrors({});
    setCheckeds([]);
  }, [_id, isOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = { ...formData, invalidatedReasons: _.map(checkeds, (o) => o._id) };
      const shape = {
        status: yup.string().required(messages.error.required),
        invalidatedReasons: yup.array().when("status", {
          is: "invalidated",
          then: yup.array().min(1, messages.error.required),
        }),
        invalidatedComments: yup.string().when(["status"], (status) => {
          const hasOthers = _.find(checkeds, (o) => o.title.toLowerCase().search("outros") === 0);
          if (status === "invalidated" && hasOthers) return yup.string().required(messages.error.required);
        }),
      };
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data, onClose);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, checkeds, handleSaveData]);

  return (
    <AlertDialog size="4xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          <Heading size="sm">Enviar análise</Heading>
          <Text fontSize="xs" fontWeight="light">
            Selecione o status para qual a fatura será movida ao enviar a análise.
          </Text>
        </AlertDialogHeader>
        <AlertDialogBody>
          <HStack>
            <PermissionedContainer required="invoices.validate">
              <Button
                h="auto"
                p="20px"
                textAlign="left"
                colorScheme={formData.status === "validated" ? "purple" : "gray"}
                onClick={() => setFormData((state) => ({ ...state, status: "validated" }))}
              >
                <HStack>
                  <Center bg="blackAlpha.200" w="30px" h="30px" borderRadius="full">
                    <Icon as={MdCheckCircleOutline} />
                  </Center>
                  <Box flex="1">
                    <Heading size="xs">Fatura válida</Heading>
                    <Text fontSize="xs" fontWeight="normal" whiteSpace="normal">
                      A fatura atende a todos os requisitos e possui os cálculos corretos.
                    </Text>
                  </Box>
                </HStack>
              </Button>
            </PermissionedContainer>
            <PermissionedContainer required="invoices.invalidate">
              <Button
                h="auto"
                p="20px"
                textAlign="left"
                colorScheme={formData.status === "invalidated" ? "red" : "gray"}
                onClick={() => setFormData((state) => ({ ...state, status: "invalidated" }))}
              >
                <HStack>
                  <Center bg="blackAlpha.200" w="30px" h="30px" borderRadius="full">
                    <Icon as={MdErrorOutline} />
                  </Center>
                  <Box flex="1">
                    <Heading size="xs">Fatura inválida</Heading>
                    <Text fontSize="xs" fontWeight="normal" whiteSpace="normal">
                      A fatura não atende a todos os requisitos ou não possui os cálculos corretos.
                    </Text>
                  </Box>
                </HStack>
              </Button>
            </PermissionedContainer>
          </HStack>

          {formData.status === "invalidated" && (
            <Fragment>
              <Divider my={4} />

              <HStack>
                <Box flex="1">
                  <Heading size="sm">Motivos para invalidação</Heading>
                  <Text fontSize="xs">Selecione um ou mais motivos para invalidação desta fatura.</Text>
                  {formErrors.invalidatedReasons && (
                    <Text color="red.500" fontSize="sm">
                      {formErrors.invalidatedReasons}
                    </Text>
                  )}
                </Box>
                <Box>
                  <InputGroup>
                    <Input
                      variant="filled"
                      placeholder="Pesquisar motivos..."
                      value={searchText}
                      onChange={({ target }) => setSearchText(target.value)}
                    />
                    <InputRightElement>
                      <Icon as={MdSearch} />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingReasons} onClick={refreshReasons} />
              </HStack>

              <CheckboxProvider values={checkeds} onChange={setCheckeds}>
                <VStack alignItems="stretch" divider={<StackDivider />} my={4}>
                  {_.map(reasons, (item) => (
                    <HStack py="5px" spacing={4}>
                      <CheckboxBody value={item} />
                      <Box flex="1">
                        <Text fontSize="sm" fontWeight="semibold">
                          {item.title}
                        </Text>
                        {_.size(item.description) >= 1 && <Text fontSize="xs">{item.description}</Text>}
                      </Box>
                    </HStack>
                  ))}
                </VStack>
              </CheckboxProvider>

              <FormControl isInvalid={formErrors.invalidatedComments}>
                <FormLabel fontSize="sm" mb="5px">
                  Outras observações
                </FormLabel>
                <Textarea
                  value={formData.invalidatedComments}
                  onChange={({ target }) => setFormData((state) => ({ ...state, invalidatedComments: target.value }))}
                />
                <FormErrorMessage>{formErrors.invalidatedComments}</FormErrorMessage>
              </FormControl>
            </Fragment>
          )}
        </AlertDialogBody>
        <AlertDialogFooter as={HStack} justify="flex-end">
          <Button size="sm" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="green" isLoading={isLoadingSaveData} isDisabled={!formData.status} onClick={handleSubmit}>
            enviar análise
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Analyze;
