import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import InputMask from "react-input-mask";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SlideFade,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AsyncSelect, Breadcrumb, InputCurrency, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { api, yup } from "lib";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

let loadPowerPlantsTimeout;

export const PowerPlantGenerationsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar geração da usina" : "Nova geração da usina");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/power-plant-generations/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {
      compensatedOffPeakPowerQttInKwh: 0,
      compensatedOnPeakPowerQttInKwh: 0,
      injectedOffPeakPowerQttInKwh: 0,
      injectedOnPeakPowerQttInKwh: 0,
      transferredPowerQttInKwh: 0,
      currentGenerationOffPeakBalanceInKwh: 0,
      currentGenerationOnPeakBalanceInKwh: 0,
      currentGenerationBalanceInKwh: 0,
    };
    if (formData.referenceDate) formData.referenceDate = moment(formData.referenceDate).format("MM/YYYY");
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    setFormData((state) => {
      const currentBalance = state.currentGenerationOffPeakBalanceInKwh + state.currentGenerationOnPeakBalanceInKwh;
      const injected = state.injectedOffPeakPowerQttInKwh + state.injectedOnPeakPowerQttInKwh;
      const compensated = state.compensatedOffPeakPowerQttInKwh + state.compensatedOnPeakPowerQttInKwh;
      return { ...state, currentGenerationBalanceInKwh: currentBalance, transferredPowerQttInKwh: injected - compensated };
    });
  }, [
    formData.currentGenerationOffPeakBalanceInKwh,
    formData.currentGenerationOnPeakBalanceInKwh,
    formData.injectedOffPeakPowerQttInKwh,
    formData.injectedOnPeakPowerQttInKwh,
    formData.compensatedOffPeakPowerQttInKwh,
    formData.compensatedOnPeakPowerQttInKwh,
  ]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id
          ? await api.patch(`/private/power-plant-generations/${_id}`, data)
          : await api.put("/private/power-plant-generations", data);
        navigate(`/power-plant-generations/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        ...formData,
        powerPlant: formData.powerPlant?._id,
        referenceDate: moment(formData.referenceDate, "MM/YYYY").toDate(),
      };
      const schema = yup.object().shape({
        powerPlant: yup.string().required(messages.error.required),
        referenceDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        compensatedOffPeakPowerQttInKwh: yup.number().required(messages.error.required),
        compensatedOnPeakPowerQttInKwh: yup.number().required(messages.error.required),
        injectedOffPeakPowerQttInKwh: yup.number().required(messages.error.required),
        injectedOnPeakPowerQttInKwh: yup.number().required(messages.error.required),
        transferredPowerQttInKwh: yup.number().required(messages.error.required),
        currentGenerationOffPeakBalanceInKwh: yup.number().required(messages.error.required),
        currentGenerationOnPeakBalanceInKwh: yup.number().required(messages.error.required),
        currentGenerationBalanceInKwh: yup.number().required(messages.error.required),
      });
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleLoadPowerPlants = useCallback((search, cb) => {
    clearTimeout(loadPowerPlantsTimeout);
    loadPowerPlantsTimeout = setTimeout(async () => {
      const query = { isActive: true };
      const response = await api.post("/private/power-plants", { search, query, perPage: -1, sort: { name: 1 }, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/power-plant-generations")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/power-plant-generations", label: "usinas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Geração da usina</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.powerPlant}>
              <FormLabel fontSize="sm">Usina</FormLabel>
              <AsyncSelect
                value={formData.powerPlant ?? {}}
                defaultOptions
                loadOptions={handleLoadPowerPlants}
                placeholder="Selecione a usina"
                onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
                getOptionValue={({ _id }) => _id}
                formatOptionLabel={({ name }) => name}
              />
              <FormErrorMessage>{formErrors.powerPlant}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <FormControl isRequired={true} isInvalid={formErrors.referenceDate}>
              <FormLabel fontSize="sm">Referência</FormLabel>
              <Input
                as={InputMask}
                mask="99/9999"
                value={formData.referenceDate ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, referenceDate: target.value }))}
              />
              <FormErrorMessage>{formErrors.referenceDate}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.compensatedOffPeakPowerQttInKwh}>
              <FormLabel fontSize="sm">Energia compensada HFP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.compensatedOffPeakPowerQttInKwh ?? ""}
                  onChange={(compensatedOffPeakPowerQttInKwh) => setFormData((state) => ({ ...state, compensatedOffPeakPowerQttInKwh }))}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.compensatedOffPeakPowerQttInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.compensatedOnPeakPowerQttInKwh}>
              <FormLabel fontSize="sm">Energia compensada HP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.compensatedOnPeakPowerQttInKwh ?? ""}
                  onChange={(compensatedOnPeakPowerQttInKwh) => setFormData((state) => ({ ...state, compensatedOnPeakPowerQttInKwh }))}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.compensatedOnPeakPowerQttInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.injectedOffPeakPowerQttInKwh}>
              <FormLabel fontSize="sm">Energia injetada HFP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.injectedOffPeakPowerQttInKwh ?? ""}
                  onChange={(injectedOffPeakPowerQttInKwh) => setFormData((state) => ({ ...state, injectedOffPeakPowerQttInKwh }))}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.injectedOffPeakPowerQttInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <FormControl isRequired={true} isInvalid={formErrors.injectedOnPeakPowerQttInKwh}>
              <FormLabel fontSize="sm">Energia injetada HP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.injectedOnPeakPowerQttInKwh ?? ""}
                  onChange={(injectedOnPeakPowerQttInKwh) => setFormData((state) => ({ ...state, injectedOnPeakPowerQttInKwh }))}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.injectedOnPeakPowerQttInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 4 }}>
            <FormControl isRequired={true} isInvalid={formErrors.transferredPowerQttInKwh}>
              <FormLabel fontSize="sm">Energia transferida</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.transferredPowerQttInKwh ?? ""}
                  onChange={(transferredPowerQttInKwh) => setFormData((state) => ({ ...state, transferredPowerQttInKwh }))}
                  isDisabled={true}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.transferredPowerQttInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.currentGenerationOffPeakBalanceInKwh}>
              <FormLabel fontSize="sm">Saldo atual HFP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.currentGenerationOffPeakBalanceInKwh ?? ""}
                  onChange={(currentGenerationOffPeakBalanceInKwh) =>
                    setFormData((state) => ({ ...state, currentGenerationOffPeakBalanceInKwh }))
                  }
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.currentGenerationOffPeakBalanceInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.currentGenerationOnPeakBalanceInKwh}>
              <FormLabel fontSize="sm">Saldo atual HP</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.currentGenerationOnPeakBalanceInKwh ?? ""}
                  onChange={(currentGenerationOnPeakBalanceInKwh) =>
                    setFormData((state) => ({ ...state, currentGenerationOnPeakBalanceInKwh }))
                  }
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.currentGenerationOnPeakBalanceInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.currentGenerationBalanceInKwh}>
              <FormLabel fontSize="sm">Saldo atual total</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.currentGenerationBalanceInKwh ?? ""}
                  onChange={(currentGenerationBalanceInKwh) => setFormData((state) => ({ ...state, currentGenerationBalanceInKwh }))}
                  isDisabled={true}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.currentGenerationBalanceInKwh}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <PermissionedContainer required={"powerPlants.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/power-plant-generations")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory
          path={`/private/power-plant-generations/${_id}/history`}
          isOpen={isOpenDocumentHistory}
          onClose={onCloseDocumentHistory}
        />
      )}
    </>
  );
};
