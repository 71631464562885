import { useContext, useEffect, useState } from "react";
import { api } from "lib";
import { SocketContext } from "SocketProvider";
import { PrivateContext } from "pages/Private";
import { usePermissioned } from "hooks";

const useCounters = () => {
  const { currentUser } = useContext(PrivateContext);
  const { socket } = useContext(SocketContext);
  const [counters, setCounters] = useState({});
  const isAllowedReadAlerts = usePermissioned("alerts.read");

  useEffect(() => {
    if (socket) {
      const listeners = {
        "@counters_alerts": {
          isAllowed: isAllowedReadAlerts,
          fetchData: async () => {
            const params = { query: { alert: { status: "pending" } }, isCount: true };
            const alerts = await api.post("/private/alerts", params);
            setCounters((state) => ({ ...state, alerts }));
          },
        },
      };
      for (const key of Object.keys(listeners)) {
        if (!listeners[key].isAllowed) continue;
        listeners[key].fetchData();
        socket.on(key, listeners[key].fetchData);
      }
      return () => {
        for (const key of Object.keys(listeners)) {
          if (!listeners[key].isAllowed) continue;
          socket.removeListener(key);
        }
      };
    }
  }, [socket, currentUser._id, isAllowedReadAlerts]);

  return counters;
};

export default useCounters;
