import React, { useCallback, useContext, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { EventEmitter, api } from "lib";
import { messages } from "consts";
import Context from "./context";

export const Dialog = () => {
  const { charge, isOpenCancelDialog, onCloseCancelDialog } = useContext(Context);
  const [isLoadingCancelData, setIsLoadingCancelData] = useState(false);
  const toast = useCustomToast();

  const handleCancelData = useCallback(async () => {
    try {
      setIsLoadingCancelData(true);
      await api.delete(`/private/invoices/${charge.invoice._id}/charges?charge=${charge._id}`);
      toast({ description: messages.success.requestCancel, status: "success", isClosable: true });
      EventEmitter.emit("charges.refresh");
      onCloseCancelDialog();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingCancelData(false);
    }
  }, [charge.invoice._id]);

  return (
    <AlertDialog isOpen={isOpenCancelDialog} onClose={onCloseCancelDialog} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent onDoubleClick={(e) => e.stopPropagation()}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Cancelar cobrança
          </AlertDialogHeader>
          <AlertDialogBody>Deseja realmente cancelar esta cobrança?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseCancelDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" isLoading={isLoadingCancelData} onClick={handleCancelData}>
              continuar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Dialog;
