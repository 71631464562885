import React, { useContext, useState, useCallback, useEffect } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { InputCurrency, PermissionedContainer } from "components";
import { EventEmitter, api, yup } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { RiScissorsCutLine } from "react-icons/ri";
import ApprovalsDetailsContext from "./context";

const Chunk = () => {
  const { formData: parentFormData, refreshData } = useContext(ApprovalsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ size: 20 });
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/approvals/${parentFormData._id}/chunk/${data.size}`);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        EventEmitter.emit("approvals.refresh");
        onClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [parentFormData._id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        size: yup.number().typeError(messages.error.required).required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <PermissionedContainer required="approvals.update">
        <Button size="sm" variant="outline" leftIcon={<Icon as={RiScissorsCutLine} />} onClick={onOpen}>
          dividir aprovação
        </Button>
      </PermissionedContainer>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text mb={4}>Quantos pagamentos cada uma das novas aprovações deverá ter?</Text>
            <FormControl isRequired={true} isInvalid={formErrors.size}>
              {/* <FormLabel fontSize="sm">Dia de vencimento da fatura</FormLabel> */}
              <Input
                as={InputCurrency}
                precision="0"
                value={formData.size ?? ""}
                onChange={(size) => setFormData((state) => ({ ...state, size }))}
              />
              <FormErrorMessage>{formErrors.size}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onClose}>cancelar</Button>
            <Button colorScheme="green" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Chunk;
