import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import InputMask from "react-input-mask";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { api, yup } from "lib";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import InvoicesListContext from "./context";
import { MdCheck, MdChecklist, MdError } from "react-icons/md";

const ProgressButton = ({ data = {} }) => {
  const colorScheme = useMemo(() => {
    if (!data.response) return "gray";
    if (data.response === "OK") return "green";
    return "red";
  }, [data.response]);

  return (
    <Tooltip label={data.response?.message} isDisabled={colorScheme !== "red"}>
      <IconButton
        size="xs"
        colorScheme={colorScheme}
        icon={<Icon as={colorScheme === "green" ? MdCheck : MdError} />}
        isLoading={data.isLoading}
      />
    </Tooltip>
  );
};

const Create = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const { checkeds, setCheckeds, refreshData } = useContext(InvoicesListContext);
  const [formData, setFormData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [progressData, setProgressData] = useState({});
  const isStarted = useMemo(() => Object.keys(progressData).length >= 1, [progressData]);
  const isInProgress = useMemo(() => {
    const mapped = Object.values(progressData).map((o) => o.isLoading);
    return mapped.includes(true);
  }, [progressData]);
  const toast = useCustomToast();

  useEffect(() => {
    if (isOpen) {
      const mapped = _.map(checkeds, (checked) => ({
        _id: checked._id,
        nid: checked.nid,
        dueDate: moment(checked.dueDate).format("DD/MM/YYYY"),
      }));
      const sorted = _.sortBy(mapped, (o) => o.nid);
      setFormData(sorted);
    } else setFormData([]);
    setFormErrors({});
    setProgressData({});
  }, [isOpen, checkeds]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        for (const item of data) {
          setProgressData((state) => ({ ...state, [item._id]: { isLoading: true } }));
          const response = await api.put(`/private/${item._id}/charges`, { dueDate: item.dueDate }).catch((error) => error);
          setProgressData((state) => ({ ...state, [item._id]: { isLoading: false, response } }));
        }
      } catch (error) {
        toast({ description: error.message, status: "error", isStarted: true });
      } finally {
        refreshData();
      }
    },
    [_id, refreshData, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.array().of(
        yup.object().shape({
          dueDate: yup
            .date()
            .typeError(messages.error.invalidDate)
            .required(messages.error.required)
            .min(moment().toDate(), messages.error.minDueDate),
        })
      );
      const data = _.map(formData, (item) => ({ ...item, dueDate: moment(item.dueDate, "DD/MM/YYYY").toDate() }));
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleClose = useCallback(() => {
    if (isInProgress) return;
    onClose();
    setCheckeds([]);
  }, [onClose, isInProgress]);

  const handleReplicateDueDate = useCallback(() => {
    setFormData((state) => {
      const tmp = [...state];
      const [{ dueDate }] = tmp;
      return tmp.map((o) => ({ ...o, dueDate }));
    });
  }, []);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
      closeOnEsc={isInProgress}
      closeOnOverlayClick={isInProgress}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Emitir cobranças</AlertDialogHeader>
        <AlertDialogBody as={VStack} alignItems="stretch" spacing={4}>
          <Text>Deseja realmente emitir cobranças para as faturas selecionadas?</Text>
          {_.map(formData, (item, index) => (
            <HStack key={item._id}>
              <FormControl isRequired={true} isInvalid={formErrors[index]?.dueDate}>
                <FormLabel fontSize="xs" mb="4px">
                  Vencimento
                </FormLabel>
                <InputGroup size="sm">
                  <InputLeftAddon>
                    <Text fontSize="xs">NID {item.nid}</Text>
                  </InputLeftAddon>
                  <Input
                    as={InputMask}
                    mask="99/99/9999"
                    value={item.dueDate ?? ""}
                    onChange={({ target }) =>
                      setFormData((state) => {
                        const tmp = [...state];
                        tmp[index].dueDate = target.value;
                        return tmp;
                      })
                    }
                    isDisabled={isStarted}
                  />
                  <InputRightElement as={HStack} spacing="5px" w="auto" pr="5px">
                    {index === 0 && (
                      <Tooltip label="Replicar esta data em todas as emissões.">
                        <IconButton size="xs" variant="outline" icon={<Icon as={MdChecklist} />} onClick={handleReplicateDueDate} />
                      </Tooltip>
                    )}
                    {progressData[item._id] && <ProgressButton data={progressData[item._id]} />}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors[index]?.dueDate}</FormErrorMessage>
              </FormControl>
            </HStack>
          ))}
        </AlertDialogBody>
        <AlertDialogFooter as={HStack} justify="flex-end">
          <Button size="sm" variant="outline" onClick={handleClose} isLoading={isInProgress}>
            fechar
          </Button>
          {isStarted === false && (
            <Button size="sm" colorScheme="main" onClick={handleSubmit}>
              confirmar
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Create;
