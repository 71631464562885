import moment from "moment";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { currency, translator } from "lib";
import { StatusBadge } from "components";
import { TbExternalLink } from "react-icons/tb";

const defaultColumns = [
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <StatusBadge schema="webhooks" status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "event",
    title: "Evento",
    isVisible: true,
    formatter: ({ event }) => event,
  },
  {
    accessor: "attemptsCount",
    title: "Tentativas",
    isVisible: true,
    formatter: ({ attemptsCount }) => attemptsCount || 0,
  },
  {
    accessor: "webhookId",
    title: "ID do webhook",
    isVisible: false,
    formatter: ({ webhookId }) => webhookId,
  },
  {
    accessor: "data.bankSlipId",
    title: "ID do boleto",
    isVisible: true,
    formatter: ({ data }) => data?.bankSlipId ?? "-",
  },
  {
    accessor: "charge.nid",
    title: "Cobrança",
    isVisible: true,
    render: ({ charge }, { setQueryParams }) =>
      charge?.nid ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => setQueryParams((state) => ({ ...state, charge_id: charge._id }))}
        >
          <HStack>
            <StatusBadge schema="charges" status={charge.status} />
            <Text>#{charge.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ charge }) => charge?.nid ?? "-",
  },
  {
    accessor: "data.paymentId",
    title: "ID do pagamento",
    isVisible: true,
    formatter: ({ data }) => data?.paymentId ?? "-",
  },
  {
    accessor: "payment.nid",
    title: "Pagamento",
    isVisible: true,
    render: ({ payment }, { setQueryParams }) =>
      payment?.nid ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => setQueryParams((state) => ({ ...state, payment_id: payment._id }))}
        >
          <HStack>
            <StatusBadge schema="payments" status={payment.status} />
            <Text>#{payment.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ payment }) => payment?.nid ?? "-",
  },
  {
    accessor: "data.approvalId",
    title: "ID da aprovação",
    isVisible: true,
    formatter: ({ data }) => data?.approvalId ?? "-",
  },
  {
    accessor: "approval.nid",
    title: "Aprovação",
    isVisible: true,
    render: ({ approval }, { setQueryParams }) =>
      approval?.nid ? (
        <Button
          size="xs"
          variant="outline"
          rightIcon={<Icon as={TbExternalLink} />}
          onClick={() => setQueryParams((state) => ({ ...state, approval_id: approval._id }))}
        >
          <HStack>
            <StatusBadge schema="approvals" status={approval.status} />
            <Text>#{approval.nid}</Text>
          </HStack>
        </Button>
      ) : (
        "-"
      ),
    exporter: ({ approval }) => approval?.nid ?? "-",
  },
  {
    accessor: "data.amount",
    title: "Valor",
    isVisible: true,
    formatter: ({ data }) => currency(data?.amount),
  },
  {
    accessor: "clientIp",
    title: "Client IP",
    isVisible: true,
    formatter: ({ clientIp }) => clientIp ?? "-",
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY [às] HH:mm:ss"),
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY [às] HH:mm:ss"),
  },
];

export default defaultColumns;
