import React, { useMemo, useRef, useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  PermissionedContainer,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { EventEmitter, api } from "lib";
import { useFetchData, useCacheState, useClipboard, useDocumentTitle, usePermissioned, useQueryParams, useTable } from "hooks";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbFileExport } from "react-icons/tb";
import { MdMoreHoriz } from "react-icons/md";
import { BiListUl } from "react-icons/bi";
import { FiClipboard } from "react-icons/fi";
import defaultColumns from "./defaultColumns";
import paymentsDefaultColumns from "pages/Private/Payments/list/defaultColumns";
import Filters from "./filters";

export const ApprovalsList = () => {
  useDocumentTitle("Aprovações");
  const location = useLocation();
  const { setQueryParams } = useQueryParams();
  const [query, setQuery] = useState();
  const [sort, setSort] = useCacheState(useMemo(() => ({ key: "ApprovalsList.sort", defaultValue: { createdAt: -1 } }), []));
  const [page, setPage] = useCacheState(useMemo(() => ({ key: "ApprovalsList.page", defaultValue: 0 }), []));
  const [perPage, setPerPage] = useCacheState(useMemo(() => ({ key: "ApprovalsList.perPage", defaultValue: 100 }), []));
  const [search, setSearch] = useCacheState(useMemo(() => ({ key: "ApprovalsList.search", defaultValue: "" }), []));
  const [data, isLoadingData, refreshData, errorData, timestampData, fetchAllPages] = useFetchData(
    useMemo(
      () => ({
        path: "/private/approvals",
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 7 }), [location.pathname])
  );
  const [checkeds, setCheckeds] = useState([]);
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const { isOpen: isOpenPaymentsExportData, onOpen: onOpenPaymentsExportData, onClose: onClosePaymentsExportData } = useDisclosure();
  const isAllowedExportSensitiveData = usePermissioned("payments.exportSensitiveData.*");
  const copyToClipboard = useClipboard();
  const tableDrawerRef = useRef();

  useEffect(() => {
    const listener = EventEmitter.addListener("approvals.refresh", refreshData);
    return () => listener.remove();
  }, [refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleExportPaymentsData = useCallback(async () => {
    const approvals = await fetchAllPages();
    return await api.fetchAllPages(`/private/payments`, {
      query: { approval: { _id: { $in: _.map(approvals, "_id") } } },
      sort: { nid: 1 },
    });
  }, [fetchAllPages]);

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "pagadoria" }, { to: "/approvals", label: "aprovações" }]} />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <PermissionedContainer required={["approvals.export", "payments.export"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="approvals.export">
                            <MenuItem onClick={onOpenExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar aprovações</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                          <PermissionedContainer required="payments.export">
                            <MenuItem onClick={onOpenPaymentsExportData}>
                              <HStack>
                                <Icon as={TbFileExport} />
                                <Text>exportar pagamentos</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Aprovações
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider key={timestampData} values={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr
                    key={item._id}
                    cursor="pointer"
                    _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
                    _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
                    onDoubleClick={() => setQueryParams((params) => ({ ...params, approval_id: item._id }))}
                  >
                    <Td>
                      <HStack>
                        <CheckboxBody value={item} />
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.referenceNumber} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                <PermissionedContainer required="approvals.read">
                                  <MenuItem
                                    icon={<Icon as={BiListUl} />}
                                    onClick={() => setQueryParams((params) => ({ ...params, approval_id: item._id }))}
                                  >
                                    detalhes
                                  </MenuItem>
                                </PermissionedContainer>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { setQueryParams })}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
              <Tfoot _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} fontWeight="bold">
                <Tr>
                  <Td></Td>
                  {cells.map(({ accessor, renderFoot }) => (
                    <Td key={accessor}>{renderFoot?.(data?.footer ?? {})}</Td>
                  ))}
                </Tr>
              </Tfoot>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="aprovacoes"
        onFetchData={fetchAllPages}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />

      <ExportCsv
        filename="pagamentos"
        onFetchData={handleExportPaymentsData}
        columns={paymentsDefaultColumns}
        isOpen={isOpenPaymentsExportData}
        onClose={onClosePaymentsExportData}
        isAllowedExportSensitiveData={isAllowedExportSensitiveData}
      />
    </>
  );
};
