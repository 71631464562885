import moment from "moment";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    formatter: ({ title }) => title,
  },
  {
    accessor: "powerPlant.name",
    title: "Usina",
    isVisible: true,
    formatter: ({ powerPlant }) => powerPlant?.name || "-",
  },
  {
    accessor: "anchorInstallationNumber",
    title: "Âncora",
    isVisible: true,
    formatter: ({ anchorInstallationNumber }) => anchorInstallationNumber,
  },
  {
    accessor: "sentAt",
    title: "Data de envio",
    isVisible: true,
    formatter: ({ sentAt }) => moment(sentAt).format("DD/MM/YYYY"),
  },
  {
    accessor: "consumerUnitsCount",
    title: "UCs",
    isVisible: true,
    formatter: ({ consumerUnitsCount }) => consumerUnitsCount?.toLocaleString() || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
