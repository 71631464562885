import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import BrazilPhoneInput from "react-telefone-brasileiro";
import { useConfirmDialog, useCustomToast } from "hooks";
import { api, EventEmitter, yup } from "lib";
import { messages } from "consts";
import { TbArchive } from "react-icons/tb";
import MessagesDetailsContext from "./context";

const Resend = () => {
  const { _id, formData: parentFormData, refreshData } = useContext(MessagesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAllowedResend = useMemo(
    () => _.includes(["waiting", "sending", "sent", "delivery"], parentFormData.status) === false && parentFormData.isNewest,
    [parentFormData.status, parentFormData.isNewest]
  );
  const toast = useCustomToast();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    setFormData({ status: "waiting", channel: parentFormData.channel, contactTarget: parentFormData.contactTarget });
    setFormErrors({});
  }, [isOpen, parentFormData.contactTarget]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/messages/${_id}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshData();
        EventEmitter.emit("messages.refresh");
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        contactTarget: yup.string().when("channel", {
          is: "email",
          then: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          otherwise: yup.string().required(messages.error.required),
        }),
      });
      const data = { ...formData };
      await schema.validate(data, { abortEarly: false });
      if (parentFormData.contactTarget !== data.contactTarget) data._actionCustomerContactTarget = await confirmAction();
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [parentFormData, formData, handleSaveData, confirmAction]);

  return (
    <Fragment>
      {isAllowedResend && (
        <Button size="sm" variant="outline" leftIcon={<Icon as={TbArchive} />} onClick={onOpen}>
          enviar novamente
        </Button>
      )}
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>Reenvio</Text>
            <Text fontSize="md" fontWeight="light">
              Deseja realmente enviar esta mensagem novamente?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.contactTarget}>
              <FormLabel fontSize="sm" mb="5px">
                Destinatário
              </FormLabel>
              <Input
                as={formData.channel === "whatsapp" ? BrazilPhoneInput : Input}
                value={formData.contactTarget ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, contactTarget: target.value }))}
                temDDD
                separaDDD
              />
              <FormErrorMessage>{formErrors.contactTarget}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              continuar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ConfirmDialog
        size="xl"
        title="Atenção"
        description="Você está alterando o destinatário da mensagem. Deseja também alterar o cadastro do cliente para o novo destinatário?"
        buttons={[
          { value: "none", text: "não alterar", flex: "1" },
          { value: "add", text: "adicionar destinatário", variant: "outline", colorScheme: "blue", flex: "1" },
          { value: "update", text: "substituir destinatário", colorScheme: "blue", flex: "1" },
        ]}
      />
    </Fragment>
  );
};

export default Resend;
