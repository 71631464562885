import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, translator, setOneOrMany } from "lib";
import { AsyncSelect, SyncSelect, RangeDateInput } from "components";
import { alertTopics, statuses } from "consts";
import { useCacheState, useNewTabState, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

const topics = Object.entries(alertTopics).map(([value, label]) => ({ value, label }));

let loadUsersTimeout = {};

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const newTabState = useNewTabState();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: newTabState ?? location.state ?? { status: statuses.alerts.filter((o) => _.includes(["pending"], o.value)) },
        useCached: _.isObject(newTabState) === false && _.isObject(location.state) === false,
        _v: 4,
        processor: (data) => ({
          ...data,
          solvedAtStart: data.solvedAtStart && moment(data.solvedAtStart).toDate(),
          solvedAtEnd: data.solvedAtEnd && moment(data.solvedAtEnd).toDate(),
          archivedAtStart: data.archivedAtStart && moment(data.archivedAtStart).toDate(),
          archivedAtEnd: data.archivedAtEnd && moment(data.archivedAtEnd).toDate(),
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state, newTabState]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      [location.pathname]
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);

    if (query.status?.length) _.set(response, "alert.status.$in", _.map(query.status, "value"));
    if (query.classification?.length) _.set(response, "alert.classification.$in", _.map(query.classification, "value"));
    setOneOrMany("alert.referenceCode", query.referenceCode, response);

    setOneOrMany("alert.installationNumber", query.installationNumber, response);
    const instNum = _.get(response, "alert.installationNumber");
    if (_.isString(instNum)) _.set(response, "alert.installationNumber", { $regex: instNum.concat("$") });

    if (query.topic?.length) _.set(response, "alert.topic.$in", _.map(query.topic, "value"));
    if (query.solvedAtStart) _.set(response, "alert.solvedAt.$gte", ["@ISODate", query.solvedAtStart]);
    if (query.solvedAtEnd) _.set(response, "alert.solvedAt.$lte", ["@ISODate", query.solvedAtEnd]);
    if (query.solvedBy?.length) _.set(response, "alert.solvedBy.$in", toObjectId(query.solvedBy));
    if (query.archivedAtStart) _.set(response, "alert.archivedAt.$gte", ["@ISODate", query.archivedAtStart]);
    if (query.archivedAtEnd) _.set(response, "alert.archivedAt.$lte", ["@ISODate", query.archivedAtEnd]);
    if (query.archivedBy?.length) _.set(response, "alert.archivedBy.$in", toObjectId(query.archivedBy));
    if (query.createdAtStart) _.set(response, "alert.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "alert.createdAt.$lte", ["@ISODate", query.createdAtEnd]);

    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadUsers = useCallback((key, search, cb) => {
    clearTimeout(loadUsersTimeout[key]);
    loadUsersTimeout[key] = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true, withSystem: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <HStack spacing={4} wrap={{ base: "wrap", lg: "nowrap" }} direction={{ base: "column", lg: "row" }} mb={8}>
        <HStack w={{ base: "100%", lg: "auto" }}>
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme={isFiltering ? "main" : "gray"}
            variant="outline"
            rightIcon={<Icon as={HiOutlineFilter} />}
            onClick={() => setIsOpen((state) => !state)}
          >
            filtros
          </Button>
          {isFiltering && (
            <Button flex={{ base: "1", lg: "none" }} variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
              limpar filtros
            </Button>
          )}
        </HStack>
        <Box minW={{ base: "full", lg: "xs" }}>
          <Input
            placeholder="N° de instalação"
            value={formData.installationNumber ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, installationNumber: target.value }))}
          />
        </Box>
        <HStack flex="1" w={{ base: "100%", lg: "auto" }} justifyContent="flex-end">
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme="main"
            rightIcon={<Icon as={MdSearch} />}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            aplicar
          </Button>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
          <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.alerts}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classificação
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.classification ?? []}
                  placeholder="Selecione"
                  options={statuses.alertClassifications}
                  onChange={(classification) => setFormData((state) => ({ ...state, classification }))}
                  formatOptionLabel={({ color, value, label }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{label || translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tópico
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  withSelectAll
                  value={formData.topic ?? []}
                  placeholder="Selecione"
                  options={topics}
                  onChange={(topic) => setFormData((state) => ({ ...state, topic }))}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cod. referência
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.referenceCode ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, referenceCode: target.value }))}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criada em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Solucionado por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.solvedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("solvedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(solvedBy) => setFormData((state) => ({ ...state, solvedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Solucionado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.solvedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.solvedAtStart}
                    defaultEndDate={formData.solvedAtEnd}
                    onChange={(solvedAtStart, solvedAtEnd) => setFormData((state) => ({ ...state, solvedAtStart, solvedAtEnd }))}
                  />
                  {formData.solvedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          solvedAtStart: null,
                          solvedAtEnd: null,
                          solvedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivada por
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.archivedBy ?? []}
                  defaultOptions
                  loadOptions={(search, cb) => handleLoadUsers("archivedBy", search, cb)}
                  placeholder="Selecione"
                  onChange={(archivedBy) => setFormData((state) => ({ ...state, archivedBy }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Arquivado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.archivedAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.archivedAtStart}
                    defaultEndDate={formData.archivedAtEnd}
                    onChange={(archivedAtStart, archivedAtEnd) => setFormData((state) => ({ ...state, archivedAtStart, archivedAtEnd }))}
                  />
                  {formData.archivedAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          archivedAtStart: null,
                          archivedAtEnd: null,
                          archivedAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
