import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react";

export const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState();
  const [formData, setFormData] = useState({});
  const [params, setParams] = useState({});

  useEffect(() => {
    if (!isOpen) {
      setParams({});
      setFormData({});
    }
  }, [isOpen]);

  const confirmAction = useCallback((params = {}) => {
    return new Promise((resolve) => {
      setIsOpen(true);
      setParams(params);
      setResolvePromise(() => resolve);
    });
  }, []);

  const handleResolve = useCallback(
    (type, value) => {
      setIsOpen(false);
      if (type === "submit") resolvePromise(formData);
      else resolvePromise(value);
    },
    [resolvePromise, formData]
  );

  const ConfirmDialog = useCallback(
    ({
      size = "sm",
      title = "Atenção",
      description,
      buttons = [
        { value: false, text: "não" },
        { value: true, text: "sim", colorScheme: "yellow" },
      ],
      children,
    }) => (
      <AlertDialog size={size} isOpen={isOpen} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>{title}</Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>{description}</Text>
            {children?.({ formData, setFormData, params })}
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            {buttons.map(({ type, value, text, ...props }) => (
              <Button size="sm" {...props} onClick={handleResolve.bind(this, type, value)}>
                {text}
              </Button>
            ))}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    ),
    [isOpen, formData, setFormData]
  );

  return { confirmAction, ConfirmDialog };
};
