import { Fragment, memo, useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import ObjectID from "bson-objectid";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FileUpload } from "containers";
import { LuImage, LuLink2, LuTrash } from "react-icons/lu";
import InvoicesTemplatesDetailsContext from "./context";

const portal = document.createElement("div");
document.body.appendChild(portal);

const Page = memo(({ provided, snapshot, item }) => {
  const { _id } = useParams();
  const { setFormData } = useContext(InvoicesTemplatesDetailsContext);
  const { isOpen: isOpenImageUpload, onOpen: onOpenImageUpload, onClose: onCloseImageUpload } = useDisclosure();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const defaultBg = useColorModeValue("gray.50", "gray.700");
  const draggingBg = useColorModeValue("gray.200", "gray.900");

  const handleDeletePage = useCallback(() => {
    setFormData((state) => {
      const pages = [...state.pages];
      const index = pages.findIndex((o) => o._id === item._id);
      pages.splice(index, 1);
      if (pages.length === 0) pages.push({ _id: ObjectID().toHexString(), link: "" });
      return { ...state, pages };
    });
  }, [item._id]);

  const handleUpload = useCallback(
    ([file]) => {
      setFormData((state) => {
        const pages = [...state.pages];
        const index = pages.findIndex((o) => o._id === item._id);
        pages[index].file = file;
        return { ...state, pages };
      });
    },
    [item._id]
  );

  const handleChangeLink = useCallback(
    ({ target }) => {
      setFormData((state) => {
        const pages = [...state.pages];
        const index = pages.findIndex((o) => o._id === item._id);
        pages[index].link = target.value;
        return { ...state, pages };
      });
    },
    [item._id]
  );

  const child = (
    <Fragment>
      <HStack
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        role="group"
        w="248px"
        h="350px"
        borderWidth="1px"
        borderRadius="md"
        position="relative"
        backgroundColor={snapshot.isDragging ? draggingBg : defaultBg}
      >
        <VStack position="absolute" top="5px" w="100%">
          <HStack
            spacing={1}
            transition="200ms"
            opacity="0"
            _groupHover={{ opacity: "1" }}
            bg="white"
            borderWidth="1px"
            p="5px"
            borderRadius="lg"
          >
            <Popover>
              <Tooltip label="Link da página" shouldWrapChildren>
                <PopoverTrigger>
                  <IconButton size="xs" variant="outline" icon={<Icon as={LuLink2} />} />
                </PopoverTrigger>
              </Tooltip>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  <Text fontSize="sm" fontWeight="semibold">
                    Link da página
                  </Text>
                  <Text fontSize="xs">Para remover o link da página basta deixar o campo abaixo vazio.</Text>
                </PopoverHeader>
                <PopoverBody>
                  <Input value={item.link} onChange={handleChangeLink} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Tooltip label="Alterar imagem. O tamanho recomendado é de 1240px de altura por 1754px de largura.">
              <IconButton size="xs" variant="outline" icon={<Icon as={LuImage} />} onClick={onOpenImageUpload} />
            </Tooltip>
            <Tooltip label="Excluir página">
              <IconButton size="xs" variant="outline" icon={<Icon as={LuTrash} />} onClick={onOpenDeleteDialog} />
            </Tooltip>
          </HStack>
        </VStack>
        {item.file && <Image w="248px" h="350px" borderRadius="md" src={item.file.location} alt={item.file.title} />}
      </HStack>

      <FileUpload
        ownerRef="InvoiceTemplate"
        owner={_id}
        isOpen={isOpenImageUpload}
        onClose={onCloseImageUpload}
        isMultiple={false}
        onChange={handleUpload}
      />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir esta página?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onCloseDeleteDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" onClick={handleDeletePage}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );

  if (!snapshot.isDragging) return child;
  return ReactDOM.createPortal(child, portal);
});

export default Page;
