import React, { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BoxData, Breadcrumb, InputCurrency, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle, useArrayItemHandlers } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

const ranges = [
  { edge: "0_30", label: "Energia até 30" },
  { edge: "31_50", label: "Energia de 31 a 50" },
  { edge: "51_100", label: "Energia de 51 a 100" },
  { edge: "101_220", label: "Energia de 101 a 220" },
  { edge: "221_Infinity", label: "Energia acima de 221" },
];

export const TariffBasesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar base tarifária" : "Nova base tarifária");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/tariff-bases/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { handleChangeArrayItem } = useArrayItemHandlers(setFormData);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = (() => {
      if (data)
        return {
          ...data,
          ranges: _.map(data.ranges, (range) => ({
            ...range,
            label: ranges.find((o) => o.edge === range.edge).label,
          })),
        };
      return {
        ranges: ranges.map(({ edge, label }) => ({ edge, label, powerPricePerKwh: 0 })),
        isActive: true,
      };
    })();
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/tariff-bases/${_id}`, data) : await api.put("/private/tariff-bases", data);
        navigate(`/tariff-bases/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        ranges: yup.array().of(
          yup.object().shape({
            edge: yup.string().required(messages.error.required),
            powerPricePerKwh: yup.number().required(messages.error.required),
          })
        ),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/tariff-bases")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/tariff-bases", label: "bases tarifárias" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Base tarifária</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={2}>
            <BoxData label="NID" value={formData.nid || "-"} />
          </GridItem>
          <GridItem colSpan={10}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          {_.map(formData.ranges, (range, index) => (
            <Fragment key={range.edge}>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <BoxData label="Intervalo" value={range.label} />
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 6 }}>
                <FormControl isRequired={true} isInvalid={formErrors[`ranges.${index}.powerPricePerKwh`]}>
                  <FormLabel fontSize="sm">Tarifa</FormLabel>
                  <InputGroup>
                    <InputLeftAddon fontSize="xs">R$</InputLeftAddon>
                    <Input
                      as={InputCurrency}
                      precision="8"
                      value={range.powerPricePerKwh ?? ""}
                      onChange={(powerPricePerKwh) => handleChangeArrayItem("ranges", index, { powerPricePerKwh })}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formErrors[`ranges.${index}.powerPricePerKwh`]}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Fragment>
          ))}
        </Grid>
      </Content>

      <PermissionedContainer required={"tariffGroups.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            {!formData.isLocked && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate("/tariff-bases")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/private/tariff-bases/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </>
  );
};
