import _ from "lodash";
import moment from "moment";

const getCo2ReductionInKg = (powerQttInKwh) => {
  const reductionFactorCo2PerKwh = 0.3785;
  return powerQttInKwh * reductionFactorCo2PerKwh;
};

export const reprocessEletricPower = (tmp) => {
  tmp.co2ReductionInKg = 0;
  tmp.economyAmount = 0;
  for (const item of tmp.items) {
    if (item.type !== "eletric_power") continue;
    if (_.isNumber(item.basePowerQttInKwh) === false) item.basePowerQttInKwh = 0;
    item.powerQttInKwh = _.sum(_.map(item.powerQttAdjustments, (o) => o.powerQttInKwh)) + item.basePowerQttInKwh;
    item.amount = item.powerQttInKwh * item.powerPricePerKwh;
    tmp.economyAmount += item.amount * tmp.discountPercentage;
    tmp.co2ReductionInKg += getCo2ReductionInKg(item.powerQttInKwh);
  }
};

export const reprocessAmounts = (state) => {
  const sum = (filter) => _(state.items).filter(filter).map("amount").sum();
  state.discountableAmount = sum((o) => o.type === "eletric_power");
  state.nonDiscountableAmount = sum((o) => o.type !== "eletric_power");

  state.baseAmount = state.discountableAmount + state.nonDiscountableAmount;
  state.amount = state.baseAmount - state.economyAmount;
  state.receivableAmount = state.isUnified ? state.amount + state.distributor.current.amount : state.amount;

  state.agreedDiscount.economyAmount = state.discountableAmount * state.agreedDiscount.percentage;
  state.agreedDiscount.amount = state.baseAmount - state.agreedDiscount.economyAmount;

  if (state.promotionDiscount) {
    state.promotionDiscount.economyAmount = state.discountableAmount * state.promotionDiscount.percentage;
    state.promotionDiscount.amount = state.baseAmount - state.promotionDiscount.economyAmount;
  }
};

export const reprocessReferenceCode = (state) => {
  state.referenceCode = [
    moment(state.referenceDate, "MMM/YYYY").format("YYYY-MM"),
    state.consumerUnit.cemigInstallationNumber,
    state.type === "manual" ? "00" : "01",
  ].join("-");
};
