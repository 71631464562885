import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, setOneOrMany } from "lib";
import { AsyncSelect, RangeDateInput } from "components";
import { useCacheState, useStickyState } from "hooks";
import { MdClose, MdSearch, MdRefresh } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadUserGroupsTimeout;

const Filters = ({ onQuery, onSearch, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? { isActive: "yes" },
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    setOneOrMany("nid", query.nid, response, (v) => parseInt(v));
    if (query.isActive?.length) _.set(response, "isActive", query.isActive === "yes");
    if (query.userGroup?.length) _.set(response, "userGroup.$in", _.map(query.userGroup, "_id"));
    if (query.createdAtStart) _.set(response, "createdAt.$gte", query.createdAtStart);
    if (query.createdAtEnd) _.set(response, "createdAt.$lte", query.createdAtEnd);
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadUserGroups = useCallback((search, cb) => {
    clearTimeout(loadUserGroupsTimeout);
    loadUserGroupsTimeout = setTimeout(async () => {
      const response = await api.post("/private/user-groups", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, title }) => ({ _id, title })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    onSearch(value);
    onPage(0);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <form onSubmit={handleSearch}>
            <InputGroup width="100%">
              <Input variant="filled" placeholder="Pesquisar..." />
              <InputRightElement>
                <IconButton type="submit" size="sm" variant="ghost" icon={<Icon as={MdSearch} />} isLoading={isLoading} />
              </InputRightElement>
            </InputGroup>
          </form>
        </GridItem>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 8 }}>
          <HStack>
            <Button
              colorScheme={isFiltering ? "main" : "gray"}
              variant="outline"
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
            <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Ativo?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isActive ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isActive: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Grupo de usuário
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.userGroup ?? []}
                  defaultOptions
                  loadOptions={handleLoadUserGroups}
                  placeholder="Selecione"
                  onChange={(userGroup) => setFormData((state) => ({ ...state, userGroup }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <HStack>
                  <RangeDateInput
                    key={formData.createdAtStartTimestamp}
                    propsConfigs={{ inputProps: { size: "sm", variant: "filled" } }}
                    defaultStartDate={formData.createdAtStart}
                    defaultEndDate={formData.createdAtEnd}
                    onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                  />
                  {formData.createdAtStart && (
                    <IconButton
                      size="sm"
                      icon={<Icon as={MdClose} />}
                      onClick={() =>
                        setFormData((state) => ({
                          ...state,
                          createdAtStart: null,
                          createdAtEnd: null,
                          createdAtStartTimestamp: Date.now().toString(),
                        }))
                      }
                    />
                  )}
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
