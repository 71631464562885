import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { MdCheck, MdClose, MdVisibility } from "react-icons/md";

const Signers = ({ signers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <Button size="xs" variant="outline" leftIcon={<Icon as={MdVisibility} />} onClick={onOpen}>
        visualizar assinantes ({_.size(signers)})
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assinantes ({_.size(signers)})</ModalHeader>
          <Divider />
          <ModalBody>
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>E-mail/WhatsApp</Th>
                  <Th>Entregue?</Th>
                  <Th>Testemunha?</Th>
                  <Th>Assinado?</Th>
                  <Th>Assinado em</Th>
                </Tr>
              </Thead>
              <Tbody>
                {_.map(signers, (signer) => (
                  <Tr>
                    <Td>
                      {signer.isSigned ? (
                        <IconButton icon={<Icon as={MdCheck} />} colorScheme="green" size="xs" isRound />
                      ) : (
                        <IconButton icon={<Icon as={MdCheck} />} variant="outline" size="xs" isRound />
                      )}
                    </Td>
                    <Td>{signer?.whatsAppNumber ?? signer?.email ?? "-"}</Td>
                    <Td>{signer?.isDelivered ? "Sim" : "Não"}</Td>
                    <Td>{signer?.isCustomer ? "Não" : "Sim"}</Td>
                    <Td>{signer?.isSigned ? "Sim" : "Não"}</Td>
                    <Td>{signer?.signedAt ? moment(signer.signedAt).format("DD/MM/YYYY [às] HH:mm") : "-"}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Signers;
