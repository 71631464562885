import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BoxData, Breadcrumb, InputCurrency, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { api, translator, yup } from "lib";
import { messages } from "consts";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const TariffGroupsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar grupo tarifário" : "Nova grupo tarifário");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/tariff-groups/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { modality: "fixed_tariff", isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/tariff-groups/${_id}`, data) : await api.put("/private/tariff-groups", data);
        navigate(`/tariff-groups/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        modality: yup.string().required(messages.error.required),
        powerPricePerKwh: yup.number().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/tariff-groups")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/tariff-groups", label: "grupos tarifários" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Grupo tarifário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={2}>
            <BoxData label="NID" value={formData.nid || "-"} />
          </GridItem>
          <GridItem colSpan={7}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <BoxData label="Modalidade" value={translator(formData.modality)} />
          </GridItem>

          {formData.modality === "fixed_tariff" && (
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.powerPricePerKwh}>
                <FormLabel fontSize="sm">Valor da tarifa fixa</FormLabel>
                <InputGroup>
                  <InputLeftAddon fontSize="sm">R$</InputLeftAddon>
                  <Input
                    as={InputCurrency}
                    precision="8"
                    value={formData.powerPricePerKwh ?? ""}
                    onChange={(powerPricePerKwh) => setFormData((state) => ({ ...state, powerPricePerKwh }))}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.powerPricePerKwh}</FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Content>

      <PermissionedContainer required={"tariffGroups.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            {!formData.isLocked && (
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
            )}
            <Button size="sm" variant="ghost" onClick={() => navigate("/tariff-groups")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/private/tariff-groups/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </>
  );
};
