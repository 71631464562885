import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Text, Tooltip, IconButton, Icon, HStack, Grid, GridItem, useDimensions } from "@chakra-ui/react";
import { MdFirstPage, MdLastPage, MdChevronLeft, MdChevronRight } from "react-icons/md";
import { InputCurrency } from "components";

export const Paginator = ({ isLoading, page = 0, size, perPage, onPaginate, isCentered }) => {
  const lastPage = useMemo(() => {
    if (perPage === -1) return 1;
    return Math.ceil(size / Math.abs(perPage));
  }, [perPage, size]);
  const displayingUpTo = useMemo(() => {
    let value = (page + 1) * perPage;
    if (value > size) return size;
    return value;
  }, [perPage, page, size]);
  const [formData, setFormData] = useState({});
  const elementRef = useRef();
  const dimensions = useDimensions(elementRef);
  const lg = useMemo(() => (dimensions?.contentBox.width > 600 ? 6 : 12), [dimensions?.contentBox.width]);

  useEffect(() => {
    setFormData({ page: page + 1 });
  }, [page]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (formData.page <= 0 || formData.page > lastPage) return;
      onPaginate(formData.page - 1);
    },
    [onPaginate, formData.page, lastPage]
  );

  return (
    <Grid ref={elementRef} templateColumns="repeat(12,1fr)" gap={2}>
      <GridItem colSpan={{ base: 12, lg }}>
        <Text fontSize="sm" textAlign={{ base: "center", lg: isCentered ? "center" : "left" }}>
          Exibindo {perPage === -1 ? size : `${page * perPage + 1} a ${displayingUpTo}`} de {size} resultados
        </Text>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg }}>
        <HStack justifyContent={{ base: "center", lg: "flex-end" }}>
          <Tooltip label="Primeira">
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdFirstPage} />}
              isDisabled={isLoading || page === 0 || !size}
              onClick={() => onPaginate(0)}
            />
          </Tooltip>
          <Tooltip label="Anterior">
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdChevronLeft} />}
              isDisabled={isLoading || page === 0 || !size}
              onClick={() => onPaginate(page - 1)}
            />
          </Tooltip>

          <form onSubmit={handleSubmit}>
            <InputCurrency
              value={formData.page}
              precision="0"
              size="sm"
              w="80px"
              fontSize="sm"
              textAlign="center"
              onChange={(page) => setFormData({ page })}
            />
          </form>

          <Text fontSize="sm" whiteSpace="nowrap">
            de {lastPage || 0}
          </Text>

          <Tooltip label="Próxima">
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdChevronRight} />}
              isDisabled={isLoading || page + 1 === lastPage || !size}
              onClick={() => onPaginate(page + 1)}
            />
          </Tooltip>
          <Tooltip label="Última">
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={MdLastPage} />}
              isDisabled={isLoading || page + 1 === lastPage || !size}
              onClick={() => onPaginate(lastPage - 1)}
            />
          </Tooltip>
        </HStack>
      </GridItem>
    </Grid>
  );
};
