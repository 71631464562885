import moment from "moment";
import { StatusBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    formatter: ({ title }) => title,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    formatter: ({ status }) => <StatusBadge {...status} />,
  },
  {
    accessor: "substatus",
    title: "Substatus",
    isVisible: true,
    formatter: ({ substatus }) => <StatusBadge {...substatus} />,
  },
  {
    accessor: "consumerUnitsCount",
    title: "UCs",
    isVisible: true,
    formatter: ({ consumerUnitsCount }) => consumerUnitsCount?.toLocaleString() || "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
