import React, { useCallback, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AsyncSelect, SyncSelect, InputCurrency, RangeDateInput, RangeInput } from "components";
import { useCacheState, useStickyState, usePermissioned, useFetchData } from "hooks";
import { api, setOneOrMany, translator } from "lib";
import { loyaltyTimesInMonths, installationTypes, statuses } from "consts";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";
import { BiCog } from "react-icons/bi";

let loadCustomersTimeout, loadUsersTimeout, loadPowerPlantsTimeout, loadSegmentsTimeout, loadContractTemplates;

const customerTypes = [
  { value: "pf", label: "Pessoa física" },
  { value: "pj", label: "Pessoa jurídica" },
];
const customerClasses = ["residencial", "comercial", "rural", "industrial"];
const voltages = ["high", "low", "low_white"];

const Filters = ({ onQuery, onRefresh, onPage, isLoading, onTableDrawer }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? {},
        useCached: _.isObject(location.state) === false,
        _v: 1,
        processor: (data) => ({
          ...data,
          createdAtStart: data.createdAtStart && moment(data.createdAtStart).toDate(),
          createdAtEnd: data.createdAtEnd && moment(data.createdAtEnd).toDate(),
          contractSendedAtStart: data.contractSendedAtStart && moment(data.contractSendedAtStart).toDate(),
          contractSendedAtEnd: data.contractSendedAtEnd && moment(data.contractSendedAtEnd).toDate(),
          contractFinishedAtStart: data.contractFinishedAtStart && moment(data.contractFinishedAtStart).toDate(),
          contractFinishedAtEnd: data.contractFinishedAtEnd && moment(data.contractFinishedAtEnd).toDate(),
          contractProcessedAtStart: data.contractProcessedAtStart && moment(data.contractProcessedAtStart).toDate(),
          contractProcessedAtEnd: data.contractProcessedAtEnd && moment(data.contractProcessedAtEnd).toDate(),
        }),
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const isAllowedCustomersRead = usePermissioned("customers.read");
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );
  const [consumerUnitStatuses, isLoadingConsumerUnitStatuses, refreshConsumerUnitStatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", parent: { $exists: false }, isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const [consumerUnitSubstatuses, isLoadingConsumerUnitSubstatuses, refreshConsumerUnitSubstatuses] = useFetchData(
    useMemo(
      () => ({
        path: "/private/statuses",
        params: {
          query: { moduleName: "ConsumerUnit", parent: { $exists: true }, isActive: true },
          sort: { ordination: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const [paymentProfiles, isLoadingPaymentProfiles, refreshPaymentProfiles] = useFetchData(
    useMemo(
      () => ({
        path: "/private/payment-profiles",
        params: { query: { isActive: true }, sort: { title: 1 }, perPage: -1, isAutocomplete: true },
      }),
      []
    )
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);

    setOneOrMany("consumerUnit.cemigInstallationNumber", query.cemigInstallationNumber, response);
    const instNum = _.get(response, "consumerUnit.cemigInstallationNumber");
    if (_.isString(instNum)) _.set(response, "consumerUnit.cemigInstallationNumber", { $regex: instNum.concat("$") });

    if (query.status?.length) _.set(response, "consumerUnit.status.$in", toObjectId(query.status));
    if (query.substatus?.length) _.set(response, "consumerUnit.substatus.$in", toObjectId(query.substatus));
    if (query.segment?.length) _.set(response, "consumerUnit.segment.$in", toObjectId(query.segment));
    if (query.powerPlant?.length) _.set(response, "consumerUnit.powerPlant.$in", toObjectId(query.powerPlant));
    if (query.isPortability?.length) _.set(response, "consumerUnit.isPortability", query.isPortability === "yes");
    if (query.isChangeOwnershipNecessary?.length)
      _.set(response, "consumerUnit.isChangeOwnershipNecessary", query.isChangeOwnershipNecessary === "yes");
    if (query.installationType?.length) _.set(response, "consumerUnit.installationType.$in", query.installationType);
    if (query.customerClass?.length) _.set(response, "consumerUnit.customerClass.$in", query.customerClass);
    if (query.voltage?.length) _.set(response, "consumerUnit.voltage.$in", query.voltage);
    if (query.loyaltyTimeInMonths?.length) _.set(response, "consumerUnit.loyaltyTimeInMonths.$in", query.loyaltyTimeInMonths);
    if (query.electricityBillAmountStart) _.set(response, "consumerUnit.electricityBillAmount.$gte", query.electricityBillAmountStart);
    if (query.electricityBillAmountEnd) _.set(response, "consumerUnit.electricityBillAmount.$lte", query.electricityBillAmountEnd);
    if (query.electricityAverageSpentInKwhStart)
      _.set(response, "consumerUnit.electricityAverageSpentInKwh.$gte", query.electricityAverageSpentInKwhStart);
    if (query.electricityAverageSpentInKwhEnd)
      _.set(response, "consumerUnit.electricityAverageSpentInKwh.$lte", query.electricityAverageSpentInKwhEnd);
    if (query.invoiceDueDayStart) _.set(response, "consumerUnit.invoiceDueDay.$gte", query.invoiceDueDayStart);
    if (query.invoiceDueDayEnd) _.set(response, "consumerUnit.invoiceDueDay.$lte", query.invoiceDueDayEnd);
    if (query.approximateReadingDayStart) _.set(response, "consumerUnit.approximateReadingDay.$gte", query.approximateReadingDayStart);
    if (query.approximateReadingDayEnd) _.set(response, "consumerUnit.approximateReadingDay.$lte", query.approximateReadingDayEnd);
    if (query.currentGenerationBalanceInKwhStart)
      _.set(response, "consumerUnit.currentGenerationBalanceInKwh.$gte", query.currentGenerationBalanceInKwhStart);
    if (query.currentGenerationBalanceInKwhEnd)
      _.set(response, "consumerUnit.currentGenerationBalanceInKwh.$lte", query.currentGenerationBalanceInKwhEnd);
    if (query.agreedDiscountStart) _.set(response, "consumerUnit.agreedDiscount.$gte", query.agreedDiscountStart);
    if (query.agreedDiscountEnd) _.set(response, "consumerUnit.agreedDiscount.$lte", query.agreedDiscountEnd);
    if (query.createdAtStart) _.set(response, "consumerUnit.createdAt.$gte", ["@ISODate", query.createdAtStart]);
    if (query.createdAtEnd) _.set(response, "consumerUnit.createdAt.$lte", ["@ISODate", query.createdAtEnd]);
    if (query.contractExists?.length) _.set(response, "consumerUnit.contract.$exists", query.contractExists === "yes");
    if (query.isLowIncome?.length) _.set(response, "consumerUnit.isLowIncome", query.isLowIncome === "yes");
    if (query.isBlockedCreateCharge?.length) _.set(response, "consumerUnit.isBlockedCreateCharge", query.isBlockedCreateCharge === "yes");
    if (query.withComments?.length) {
      if (query.withComments === "yes") _.set(response, "consumerUnit.comments", { $exists: true, $ne: "" });
      else _.set(response, "consumerUnit.$or", [{ comments: { $exists: false } }, { comments: { $eq: "" } }]);
    }
    if (query.paymentProfile?.length) _.set(response, "consumerUnit.paymentProfile.$in", toObjectId(query.paymentProfile));

    if (query.customerId?.length) _.set(response, "customer._id.$in", toObjectId(query.customerId));
    if (query.customerType?.length) _.set(response, "customer.type.$in", _.map(query.customerType, "value"));
    if (query.customerConsultant?.length) _.set(response, "customer.consultant.$in", toObjectId(query.customerConsultant));

    if (query.contractStatus?.length) _.set(response, "contract.status.$in", _.map(query.contractStatus, "value"));
    if (query.contractSendedAtStart) _.set(response, "contract.sendedAt.$gte", ["@ISODate", query.contractSendedAtStart]);
    if (query.contractSendedAtEnd) _.set(response, "contract.sendedAt.$lte", ["@ISODate", query.contractSendedAtEnd]);
    if (query.contractFinishedAtStart) _.set(response, "contract.finishedAt.$gte", ["@ISODate", query.contractFinishedAtStart]);
    if (query.contractFinishedAtEnd) _.set(response, "contract.finishedAt.$lte", ["@ISODate", query.contractFinishedAtEnd]);
    if (query.contractTemplate?.length) _.set(response, "contract.template.$in", _.map(query.contractTemplate, "uid"));

    if (query.contractProcessedAtStart || query.contractProcessedAtEnd)
      _.set(response, "contract.$or", [
        { sendedAt: { $gte: ["@ISODate", query.contractProcessedAtStart], $lte: ["@ISODate", query.contractProcessedAtEnd] } },
        { finishedAt: { $gte: ["@ISODate", query.contractProcessedAtStart], $lte: ["@ISODate", query.contractProcessedAtEnd] } },
      ]);

    if (_.has(response, "contract")) _.set(response, "contract.parent", { $exists: false });
    setIsFiltering(Object.keys(response).length > 0);

    onQuery(response);
    onPage(0);
  }, [onQuery, onPage, query, location.state]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const response = await api.post("/private/customers", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/private/users", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadPowerPlants = useCallback((search, cb) => {
    clearTimeout(loadPowerPlantsTimeout);
    loadPowerPlantsTimeout = setTimeout(async () => {
      const response = await api.post("/private/power-plants", { search, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, name }) => ({ _id, name })));
    }, 1000);
  }, []);

  const handleLoadSegments = useCallback((search, cb) => {
    clearTimeout(loadSegmentsTimeout);
    loadSegmentsTimeout = setTimeout(async () => {
      const response = await api.post("/private/segments", { search, sort: { title: 1 }, perPage: 20, isAutocomplete: true });
      cb(_.map(response?.data, ({ _id, title }) => ({ _id, title })));
    }, 1000);
  }, []);

  const handleLoadContractTemplates = useCallback((search, cb) => {
    clearTimeout(loadContractTemplates);
    loadContractTemplates = setTimeout(async () => {
      const response = await api.post("/private/contract-templates/_", {
        search,
        query: { isAdditive: false },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(_.map(response?.data, ({ uid, name }) => ({ uid, name })));
    }, 1000);
  }, []);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <HStack spacing={4} wrap={{ base: "wrap", lg: "nowrap" }} direction={{ base: "column", lg: "row" }} mb={8}>
        <HStack w={{ base: "100%", lg: "auto" }}>
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme={isFiltering ? "main" : "gray"}
            variant="outline"
            rightIcon={<Icon as={HiOutlineFilter} />}
            onClick={() => setIsOpen((state) => !state)}
          >
            filtros
          </Button>
          {isFiltering && (
            <Button flex={{ base: "1", lg: "none" }} variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
              limpar filtros
            </Button>
          )}
        </HStack>
        <Box minW={{ base: "full", lg: "xs" }}>
          <Input
            placeholder="N° de instalação"
            value={formData.cemigInstallationNumber ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, cemigInstallationNumber: target.value }))}
          />
        </Box>
        <Box minW={{ base: "full", lg: "xs" }}>
          <AsyncSelect
            isMulti
            value={formData.customerId ?? []}
            defaultOptions
            loadOptions={handleLoadCustomers}
            placeholder="Cliente"
            onChange={(customerId) => setFormData((state) => ({ ...state, customerId }))}
            getOptionValue={({ _id }) => _id}
            formatOptionLabel={({ name }) => name}
            isClearable={true}
          />
        </Box>
        <HStack flex="1" w={{ base: "100%", lg: "auto" }} justifyContent="flex-end">
          <Button
            flex={{ base: "1", lg: "none" }}
            colorScheme="main"
            rightIcon={<Icon as={MdSearch} />}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            aplicar
          </Button>
          <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoading} onClick={onRefresh} />
          <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={onTableDrawer} />
        </HStack>
      </HStack>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            {isAllowedCustomersRead && (
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl>
                  <FormLabel fontSize="xs" mb="5px">
                    Consultor
                  </FormLabel>
                  <AsyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    value={formData.customerConsultant ?? []}
                    defaultOptions
                    loadOptions={handleLoadUsers}
                    placeholder="Selecione"
                    onChange={(customerConsultant) => setFormData((state) => ({ ...state, customerConsultant }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                </FormControl>
              </GridItem>
            )}
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status da UC
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.status ?? []}
                    options={consumerUnitStatuses?.data || []}
                    placeholder="Selecione"
                    onChange={(status) => setFormData((state) => ({ ...state, status }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ colorScheme, title, label }) => (
                      <HStack>
                        <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>{label || title}</Text>
                      </HStack>
                    )}
                    isClearable={true}
                  />
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingConsumerUnitStatuses}
                    onClick={refreshConsumerUnitStatuses}
                  />
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Substatus da UC
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.substatus ?? []}
                    options={consumerUnitSubstatuses?.data || []}
                    placeholder="Selecione"
                    onChange={(substatus) => setFormData((state) => ({ ...state, substatus }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ colorScheme, title, label }) => (
                      <HStack>
                        <Box bg={`${colorScheme}.500`} w="10px" h="10px" borderRadius="full" />
                        <Text>{label || title}</Text>
                      </HStack>
                    )}
                    isClearable={true}
                  />
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingConsumerUnitSubstatuses}
                    onClick={refreshConsumerUnitSubstatuses}
                  />
                </HStack>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de pessoa
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.customerType, (o) => ({ value: o.value, label: o.label }))}
                  placeholder="Selecione"
                  options={_.map(customerTypes, (o) => ({ value: o.value, label: o.label }))}
                  onChange={(selecteds) => setFormData((state) => ({ ...state, customerType: selecteds }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Dia de vencimento da fatura
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.invoiceDueDayStart}
                  defaultEndValue={formData.invoiceDueDayEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, invoiceDueDayStart: startValue, invoiceDueDayEnd: endValue }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Valor da conta
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.electricityBillAmountStart}
                  defaultEndValue={formData.electricityBillAmountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      electricityBillAmountStart: startValue,
                      electricityBillAmountEnd: endValue,
                    }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">R$</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Média do contrato
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.electricityAverageSpentInKwhStart}
                  defaultEndValue={formData.electricityAverageSpentInKwhEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      electricityAverageSpentInKwhStart: startValue,
                      electricityAverageSpentInKwhEnd: endValue,
                    }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">kWh</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Saldo atual de geração
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.currentGenerationBalanceInKwhStart}
                  defaultEndValue={formData.currentGenerationBalanceInKwhEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({
                      ...state,
                      currentGenerationBalanceInKwhStart: startValue,
                      currentGenerationBalanceInKwhEnd: endValue,
                    }))
                  }
                  InputRightElement={<InputRightElement fontSize="xs">kWh</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Segmento
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.segment ?? []}
                  defaultOptions
                  loadOptions={handleLoadSegments}
                  placeholder="Selecione"
                  onChange={(segment) => setFormData((state) => ({ ...state, segment }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tipo de instalação
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.installationType, (value) => ({ value, label: value.toUpperCase() }))}
                  placeholder="Selecione"
                  options={_.map(installationTypes, (value) => ({ value, label: value.toUpperCase() }))}
                  onChange={(installationType) =>
                    setFormData((state) => ({ ...state, installationType: _.map(installationType, ({ value }) => value) }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classe do cliente
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.customerClass, (value) => ({ value, label: value.toUpperCase() }))}
                  placeholder="Selecione"
                  options={_.map(customerClasses, (value) => ({ value, label: value.toUpperCase() }))}
                  onChange={(customerClass) =>
                    setFormData((state) => ({ ...state, customerClass: _.map(customerClass, ({ value }) => value) }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Dia aprox. da leitura
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  precision="0"
                  defaultStartValue={formData.approximateReadingDayStart}
                  defaultEndValue={formData.approximateReadingDayEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, approximateReadingDayStart: startValue, approximateReadingDayEnd: endValue }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Desconto acordado
                </FormLabel>
                <RangeInput
                  as={InputCurrency}
                  defaultStartValue={formData.agreedDiscountStart}
                  defaultEndValue={formData.agreedDiscountEnd}
                  onChange={({ startValue, endValue }) =>
                    setFormData((state) => ({ ...state, agreedDiscountStart: startValue, agreedDiscountEnd: endValue }))
                  }
                  isPercentage={true}
                  InputRightElement={<InputRightElement fontSize="xs">%</InputRightElement>}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Troca de titularidade?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isChangeOwnershipNecessary ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isChangeOwnershipNecessary: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Usina
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.powerPlant ?? []}
                  defaultOptions
                  loadOptions={handleLoadPowerPlants}
                  placeholder="Selecione"
                  onChange={(powerPlant) => setFormData((state) => ({ ...state, powerPlant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tensão
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.voltage, (value) => ({ value, label: translator(value) }))}
                  placeholder="Selecione"
                  options={_.map(voltages, (value) => ({ value, label: translator(value) }))}
                  onChange={(voltage) => setFormData((state) => ({ ...state, voltage: _.map(voltage, ({ value }) => value) }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Tempo de fidelidade
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={_.map(formData.loyaltyTimeInMonths, (value) => ({ value, label: value.concat(" meses") }))}
                  placeholder="Selecione"
                  options={_.map(loyaltyTimesInMonths, (value) => ({ value, label: value.concat(" meses") }))}
                  onChange={(selecteds) => setFormData((state) => ({ ...state, loyaltyTimeInMonths: _.map(selecteds, (o) => o.value) }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Portabilidade?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isPortability ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isPortability: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Possui observações?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.withComments ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, withComments: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Criado em
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.createdAtStart}
                  defaultEndDate={formData.createdAtEnd}
                  onChange={(createdAtStart, createdAtEnd) => setFormData((state) => ({ ...state, createdAtStart, createdAtEnd }))}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Baixa renda?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isLowIncome ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isLowIncome: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Bloqueio de cobranças?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isBlockedCreateCharge ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isBlockedCreateCharge: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Perfil de pagamento
                </FormLabel>
                <HStack>
                  <SyncSelect
                    size="sm"
                    variant="filled"
                    isMulti
                    withSelectAll
                    value={formData.paymentProfile ?? []}
                    options={paymentProfiles?.data || []}
                    placeholder="Selecione"
                    onChange={(paymentProfile) => setFormData((state) => ({ ...state, paymentProfile }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ title }) => title}
                    isClearable={true}
                  />
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingPaymentProfiles}
                    onClick={refreshPaymentProfiles}
                  />
                </HStack>
              </FormControl>
            </GridItem>
          </Grid>

          <Divider my={8} />

          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Possui contrato?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.contractExists ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, contractExists: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status do contrato
                </FormLabel>
                <SyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.contractStatus ?? []}
                  placeholder="Selecione"
                  options={statuses.contracts}
                  onChange={(contractStatus) => setFormData((state) => ({ ...state, contractStatus }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Contrato enviado em
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.contractSendedAtStart}
                  defaultEndDate={formData.contractSendedAtEnd}
                  onChange={(contractSendedAtStart, contractSendedAtEnd) =>
                    setFormData((state) => ({ ...state, contractSendedAtStart, contractSendedAtEnd }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Contrato finalizado em
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.contractFinishedAtStart}
                  defaultEndDate={formData.contractFinishedAtEnd}
                  onChange={(contractFinishedAtStart, contractFinishedAtEnd) =>
                    setFormData((state) => ({ ...state, contractFinishedAtStart, contractFinishedAtEnd }))
                  }
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Contrato processado em (enviado ou finalizado)
                </FormLabel>
                <RangeDateInput
                  defaultStartDate={formData.contractProcessedAtStart}
                  defaultEndDate={formData.contractProcessedAtEnd}
                  onChange={(contractProcessedAtStart, contractProcessedAtEnd) =>
                    setFormData((state) => ({ ...state, contractProcessedAtStart, contractProcessedAtEnd }))
                  }
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Template do contrato
                </FormLabel>
                <AsyncSelect
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.contractTemplate ?? []}
                  defaultOptions
                  loadOptions={handleLoadContractTemplates}
                  placeholder="Selecione"
                  onChange={(contractTemplate) => setFormData((state) => ({ ...state, contractTemplate }))}
                  getOptionValue={({ uid }) => uid}
                  formatOptionLabel={({ name }) => name}
                  isClearable={true}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
