import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PermissionedContainer, StatusBadge } from "components";
import { useQueryParams, useClipboard, useFetchData } from "hooks";
import { EventEmitter, currency } from "lib";
import { MdAttachMoney, MdMoreHoriz, MdRefresh } from "react-icons/md";
import { FiClipboard } from "react-icons/fi";
import { BiListUl } from "react-icons/bi";
import { TbExternalLink } from "react-icons/tb";
import InvoicesDetailsContext from "../context";

const Payments = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading, refreshData } = useContext(InvoicesDetailsContext);
  const { setQueryParams } = useQueryParams();
  const copyToClipboard = useClipboard();
  const [payments, isLoadingPayments, refreshPayments] = useFetchData(
    useMemo(
      () => ({
        path: "/private/payments",
        params: { query: { invoice: { _id: ["@ObjectId", _id] } }, sort: { createdAt: -1 } },
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, payments: _.size(payments?.data) }));
  }, [payments?.data, isLoadingPayments]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, payments: isLoadingPayments }));
  }, [isLoadingPayments]);

  useEffect(() => {
    const listener = EventEmitter.addListener("payments.refresh", () => {
      refreshPayments();
      refreshData();
    });
    return () => listener.remove();
  }, [refreshData, refreshPayments]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(payments?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingPayments} onClick={refreshPayments} />
      </HStack>

      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Status</Th>
            <Th>Cliente</Th>
            <Th>Data do último evento</Th>
            <Th>Data do agendamento</Th>
            <Th>Valor</Th>
            <Th>Aprovação</Th>
            <Th>Criada por</Th>
            <Th>Criada em</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(payments?.data, (item) => (
            <Tr
              key={item._id}
              cursor="pointer"
              _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.700" } }}
              _active={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}
              onDoubleClick={() => setQueryParams((params) => ({ ...params, payment_id: item._id }), { replace: true })}
            >
              <Td>
                <Menu placement="right-start">
                  <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                  <MenuList>
                    <MenuGroup title={item.referenceNumber} pb="5px">
                      <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                        copiar código
                      </MenuItem>
                      <PermissionedContainer required="payments.read">
                        <MenuItem
                          icon={<Icon as={BiListUl} />}
                          onClick={() => setQueryParams((params) => ({ ...params, payment_id: item._id }), { replace: true })}
                        >
                          detalhes
                        </MenuItem>
                      </PermissionedContainer>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Td>
              <Td>
                <StatusBadge schema="payments" status={item.status} />
              </Td>
              <Td>{item.customer?.name}</Td>
              <Td>{moment(item.updatedAt).format("DD/MM/YYYY [às] HH:mm")}</Td>
              <Td>{item.scheduledDate ? moment(item.scheduledDate).format("DD/MM/YYYY") : "-"}</Td>
              <Td>{currency(item.amount)}</Td>
              <Td>
                <Button
                  size="xs"
                  variant="outline"
                  rightIcon={<Icon as={TbExternalLink} />}
                  onClick={() => setQueryParams((params) => ({ ...params, approval_id: item.approval._id }))}
                >
                  #{item.approval.nid}
                </Button>
              </Td>
              <Td>{item.createdBy.name}</Td>
              <Td>{moment(item.createdAt).format("DD/MM/YYYY")}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {isLoadingPayments ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(payments?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdAttachMoney} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum pagamento adicionado
              </Text>
              <Text fontSize="sm">Esta fatura ainda não possui pagamentos adicionados.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Payments;
