import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import { HStack, Icon, IconButton, Text } from "@chakra-ui/react";
import { MessagesList } from "containers";
import { MdRefresh } from "react-icons/md";
import MessagesDetailsContext from "./context";
import { EventEmitter } from "lib";
import { useFetchData } from "hooks";

const Messages = () => {
  const { _id, formData, setCounters, setIsLoading } = useContext(MessagesDetailsContext);
  const [messages, isLoadingMessages, refreshMessages] = useFetchData(
    useMemo(() => {
      const query = { message: { topic: formData.topic, consumerUnit: formData.consumerUnit?._id } };
      if (formData.topic?.includes("bankSlip")) query.message = { invoice: formData.invoice?._id };
      return {
        path: "/private/messages",
        params: { query, sort: { createdAt: -1 } },
        options: { isEnabled: _.isString(formData.topic) },
      };
    }, [formData.topic, formData.consumerUnit?._id, formData.invoice?._id])
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, messages: _.size(messages?.data) }));
  }, [messages?.data]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, messages: isLoadingMessages }));
  }, [isLoadingMessages]);

  useEffect(() => {
    const listener = EventEmitter.addListener("messages.refresh", refreshMessages);
    return () => listener.remove();
  }, [_id, refreshMessages]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(messages?.data)} registros
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingMessages} onClick={refreshMessages} />
      </HStack>

      <MessagesList data={messages?.data} isLoading={isLoadingMessages} highlighted={formData._id} />
    </>
  );
};

export default Messages;
