const iconNames = [
  "BsFiletypeAac",
  "BsFiletypeAi",
  "BsFiletypeBmp",
  "BsFiletypeCs",
  "BsFiletypeCss",
  "BsFiletypeCsv",
  "BsFiletypeDoc",
  "BsFiletypeDocx",
  "BsFiletypeExe",
  "BsFiletypeGif",
  "BsFiletypeHeic",
  "BsFiletypeHtml",
  "BsFiletypeJava",
  "BsFiletypeJpg",
  "BsFiletypeJs",
  "BsFiletypeJson",
  "BsFiletypeJsx",
  "BsFiletypeKey",
  "BsFiletypeM4P",
  "BsFiletypeMd",
  "BsFiletypeMdx",
  "BsFiletypeMov",
  "BsFiletypeMp3",
  "BsFiletypeMp4",
  "BsFiletypeOtf",
  "BsFiletypePdf",
  "BsFiletypePhp",
  "BsFiletypePng",
  "BsFiletypePpt",
  "BsFiletypePptx",
  "BsFiletypePsd",
  "BsFiletypePy",
  "BsFiletypeRaw",
  "BsFiletypeRb",
  "BsFiletypeSass",
  "BsFiletypeScss",
  "BsFiletypeSh",
  "BsFiletypeSql",
  "BsFiletypeSvg",
  "BsFiletypeTiff",
  "BsFiletypeTsx",
  "BsFiletypeTtf",
  "BsFiletypeTxt",
  "BsFiletypeWav",
  "BsFiletypeWoff",
  "BsFiletypeXls",
  "BsFiletypeXlsx",
  "BsFiletypeXml",
  "BsFiletypeYml",
];

const icons = {
  zip: require("react-icons/bs").BsFileEarmarkZip,
  rar: require("react-icons/bs").BsFileEarmarkZip,
};
iconNames.forEach((iconName) => {
  icons[iconName.replace("BsFiletype", "").toLowerCase()] = require("react-icons/bs")[iconName];
});

export default icons;
