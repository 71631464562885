import { Box, Spinner } from "@chakra-ui/react";
import { AsyncSelect as ReactAsyncSelct, Select as ReactSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";

export const AsyncSelect = (props) => {
  return (
    <Box flex="1">
      <ReactAsyncSelct
        loadingMessage={() => <Spinner size="xs" />}
        noOptionsMessage={() => "Nenhum registro encontrado"}
        selectedOptionStyle="check"
        selectedOptionColorScheme="main"
        {...props}
      />
    </Box>
  );
};

export const SyncSelect = ({ options, onChange, withSelectAll, ...props }) => {
  const _options = useMemo(() => {
    if (withSelectAll) return [{ value: "select-all", label: "Todos" }, ...(options || [])];
    return options;
  }, [withSelectAll, options]);

  const handleChange = useCallback(
    (value) => {
      if (props.isMulti && withSelectAll) {
        const index = value.findIndex((o) => o.value === "select-all");
        if (index !== -1) return onChange(options);
      }
      onChange(value);
    },
    [props.isMulti, withSelectAll, options]
  );

  return (
    <Box flex="1">
      <ReactSelect
        loadingMessage={() => <Spinner size="xs" />}
        noOptionsMessage={() => "Nenhum registro encontrado"}
        selectedOptionStyle="check"
        selectedOptionColorScheme="main"
        options={_options}
        onChange={handleChange}
        {...props}
      />
    </Box>
  );
};
