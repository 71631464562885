import React, { useCallback, useContext, useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Address, AsyncSelect, BoxData, EmailAutocomplete, PhoneInput, SyncSelect } from "components";
import { useClipboard, useFetchData, usePermissioned } from "hooks";
import { personTypes } from "consts";
import CustomersDetailsContext from "../context";
import { GeneralContext } from ".";
import BillingContact from "./billingContact";
import { ContactVerify } from "..";
import { api } from "lib";
import { FiClipboard } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";

let loadUsersTimeout = null;

const Simplified = () => {
  const { _id } = useParams();
  const isAllowedCustomersRead = usePermissioned("customers.read");
  const { formData, setFormData, formErrors, isEditDisabled } = useContext(CustomersDetailsContext);
  const { handleLoadCustomers } = useContext(GeneralContext);
  const copyToClipboard = useClipboard();
  const [exhibitions, isLoadingExhibitions, refreshExhibitions] = useFetchData(
    useMemo(
      () => ({
        path: "/private/exhibitions",
        params: {
          query: { $or: [{ isActive: true }, { _id: formData.exhibition }] },
          sort: { title: 1 },
          isAutocomplete: true,
        },
        options: { isEnabled: _.isString(formData.name) },
      }),
      [formData.name, formData.exhibition]
    )
  );

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const query = { isActive: true };
      const response = await api.post("/private/users", { search, query, perPage: -1, sort: { name: 1 }, isAutocomplete: true });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={true} isInvalid={formErrors.consultant}>
            {isAllowedCustomersRead ? (
              <>
                <FormLabel fontSize="sm">Consultor</FormLabel>
                <AsyncSelect
                  value={formData.consultant ?? {}}
                  defaultOptions
                  loadOptions={handleLoadUsers}
                  placeholder="Selecione o consultor"
                  onChange={(consultant) => setFormData((state) => ({ ...state, consultant }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ name }) => name}
                  isDisabled={isEditDisabled("consultant")}
                />
              </>
            ) : (
              <BoxData label="Consultor" value={formData.consultant?.name ?? "-"} />
            )}
            <FormErrorMessage>{formErrors.consultant}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo de pessoa</FormLabel>
            <Select
              value={formData.type ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}
              isDisabled={isEditDisabled("type")}
            >
              {!_id && <option value="">--Selecione</option>}
              <option value="pf">Pessoa física</option>
              <option value="pj">Pessoa jurídica</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>

        {formData.type && (
          <>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isRequired={true} isInvalid={formErrors.name}>
                <FormLabel fontSize="sm">{personTypes[formData.type]?.name}</FormLabel>
                <Input
                  value={formData.name ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                  isDisabled={isEditDisabled("name")}
                />
                <FormErrorMessage>{formErrors.name}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isRequired={true} isInvalid={formErrors.document}>
                <FormLabel fontSize="sm">{personTypes[formData.type]?.document}</FormLabel>
                <Input
                  as={InputMask}
                  mask={personTypes[formData.type]?.documentMask}
                  value={formData.document ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
                  isDisabled={isEditDisabled("document")}
                />
                <FormErrorMessage>{formErrors.document}</FormErrorMessage>
              </FormControl>
            </GridItem>

            {formData.type === "pf" && (
              <>
                <GridItem colSpan={{ base: 12, lg: 4 }}>
                  <FormControl isInvalid={formErrors.rg}>
                    <FormLabel fontSize="sm">RG</FormLabel>
                    <Input
                      value={formData.rg ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, rg: target.value }))}
                      isDisabled={isEditDisabled("rg")}
                    />
                    <FormErrorMessage>{formErrors.rg}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 4 }}>
                  <FormControl isRequired={true} isInvalid={formErrors.birth}>
                    <FormLabel fontSize="sm">Data de nascimento</FormLabel>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      value={formData.birth ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, birth: target.value }))}
                      isDisabled={isEditDisabled("birth")}
                    />
                    <FormErrorMessage>{formErrors.birth}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </>
            )}

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isInvalid={formErrors.exhibition}>
                <FormLabel fontSize="sm">Evento</FormLabel>
                <HStack>
                  <SyncSelect
                    placeholder="Selecione"
                    options={exhibitions?.data || []}
                    value={formData.exhibition || {}}
                    onChange={(exhibition) => setFormData((state) => ({ ...state, exhibition }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ title }) => title}
                    isClearable={true}
                    isDisabled={isEditDisabled("exhibition")}
                  />
                  <IconButton
                    variant="outline"
                    icon={<Icon as={MdRefresh} />}
                    isLoading={isLoadingExhibitions}
                    onClick={refreshExhibitions}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.exhibition}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isRequired={true} isInvalid={formErrors.phone1}>
                <HStack mb="0.3em">
                  <Tooltip label="Copiar telefone 1.">
                    <IconButton
                      size="xs"
                      variant="outline"
                      icon={<Icon as={FiClipboard} />}
                      onClick={() => copyToClipboard(formData.phone1)}
                    />
                  </Tooltip>
                  <FormLabel fontSize="sm" mb="0">
                    Telefone 1
                  </FormLabel>
                </HStack>
                <HStack>
                  <PhoneInput
                    phone={formData.phone1}
                    category={formData.phone1Category}
                    onChangePhone={(phone1) => setFormData((state) => ({ ...state, phone1 }))}
                    onChangeCategory={(phone1Category) => setFormData((state) => ({ ...state, phone1Category }))}
                    isDisabled={isEditDisabled("phone1")}
                  />
                  <ContactVerify
                    type="phone"
                    value={formData.phone1}
                    isVerified={formData.phone1Verified}
                    isDisabled={isEditDisabled("phone1")}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.phone1}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl isRequired={true} isInvalid={formErrors.email}>
                <HStack mb="0.3em">
                  <Tooltip label="Copiar e-mail.">
                    <IconButton
                      size="xs"
                      variant="outline"
                      icon={<Icon as={FiClipboard} />}
                      onClick={() => copyToClipboard(formData.email)}
                    />
                  </Tooltip>
                  <FormLabel fontSize="sm" mb="0">
                    E-mail
                  </FormLabel>
                </HStack>
                <HStack>
                  <EmailAutocomplete
                    value={formData.email ?? ""}
                    onChange={(email) => setFormData((state) => ({ ...state, email }))}
                    isInvalid={formErrors.email}
                    isDisabled={isEditDisabled("email")}
                  />
                  <ContactVerify
                    type="email"
                    value={formData.email}
                    isVerified={formData.emailVerified}
                    isDisabled={isEditDisabled("email")}
                  />
                </HStack>
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: isAllowedCustomersRead ? 4 : 6 }}>
              <FormControl isRequired={true} isInvalid={formErrors.cemigCustomerNumber}>
                <FormLabel fontSize="sm">Número do cliente na CEMIG</FormLabel>
                <Input
                  value={formData.cemigCustomerNumber ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, cemigCustomerNumber: target.value }))}
                  isDisabled={isEditDisabled("cemigCustomerNumber")}
                />
                <FormErrorMessage>{formErrors.cemigCustomerNumber}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: isAllowedCustomersRead ? 4 : 6 }}>
              <FormControl isRequired={true} isInvalid={formErrors.earlyWarning}>
                <FormLabel fontSize="sm">Aviso prévio</FormLabel>
                <Select
                  value={formData.earlyWarning ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, earlyWarning: target.value }))}
                  isDisabled={isEditDisabled("earlyWarning")}
                >
                  <option value="90">90 dias</option>
                  <option value="120">120 dias</option>
                  <option value="180">180 dias</option>
                </Select>
                <FormErrorMessage>{formErrors.earlyWarning}</FormErrorMessage>
              </FormControl>
            </GridItem>

            {isAllowedCustomersRead && (
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <FormControl isInvalid={formErrors.indicatedBy}>
                  <FormLabel fontSize="sm">Indicador por</FormLabel>
                  <AsyncSelect
                    value={formData.indicatedBy ?? {}}
                    defaultOptions
                    loadOptions={handleLoadCustomers}
                    placeholder="Selecione o cliente"
                    onChange={(indicatedBy) => setFormData((state) => ({ ...state, indicatedBy }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                    isDisabled={isEditDisabled("indicatedBy")}
                  />
                  <FormErrorMessage>{formErrors.indicatedBy}</FormErrorMessage>
                </FormControl>
              </GridItem>
            )}
          </>
        )}
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Endereço
      </Heading>
      <Address
        data={formData.address}
        errors={formErrors.address}
        isRequired={true}
        onChange={(address) => setFormData((state) => ({ ...state, address: { ...state.address, ...address } }))}
        isDisabled={isEditDisabled("address")}
      />

      <Divider my={8} />

      <BillingContact />
    </>
  );
};

export default Simplified;
