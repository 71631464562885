import moment from "moment";
import _ from "lodash";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "expiresAt",
    title: "Data de expiração",
    isVisible: true,
    formatter: ({ expiresAt }) => moment(expiresAt).format("DD/MM/YYYY"),
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
