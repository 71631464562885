import { useCallback } from "react";
import _ from "lodash";

export const useArrayItemHandlers = (setFormData) => {
  const handleChangeArrayItem = useCallback(
    (path, index, obj) => {
      setFormData((state) => {
        const tmp = { ...state };
        const arr = _.get(tmp, path) || [];
        arr[index] = { ...arr[index], ...obj };
        return tmp;
      });
    },
    [setFormData]
  );

  const handleAddArrayItem = useCallback(
    (path, obj = {}) => {
      setFormData((state) => {
        const tmp = { ...state };
        const arr = _.get(tmp, path) || [];
        arr.push(obj);
        _.set(tmp, path, arr);
        return tmp;
      });
    },
    [setFormData]
  );

  const handleDeleteArrayItem = useCallback(
    (path, index) => {
      setFormData((state) => {
        const tmp = { ...state };
        const arr = _.get(tmp, path) || [];
        arr.splice(index, 1);
        _.set(tmp, path, arr);
        return tmp;
      });
    },
    [setFormData]
  );

  return { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem };
};
