import React from "react";
import { useColorModeValue } from "@chakra-ui/react";

export const Logo = ({ width = 50 }) => {
  const fill = useColorModeValue("#002f1f", "#ffffff");
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.771 122.826" {...{ width }}>
        <g transform="translate(-24.96 -68.187)">
          <path
            d="M24.96,126.247c0,18.422,13.574,32.117,32.843,32.117,16,0,24.482-9.817,24.482-9.817l-4.726-8.6s-7.515,8.242-18.907,8.242c-12.726,0-21.694-9.575-21.694-22.058,0-12.6,8.968-21.815,21.33-21.815a24.841,24.841,0,0,1,16.968,7.029l5.575-8.241S73.2,94.13,57.8,94.13C38.534,94.13,24.96,108.068,24.96,126.247Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            d="M93.57,140.185c0,15.391,9.817,17.209,16.482,17.209a33.107,33.107,0,0,0,3.879-.242V146.729a18.758,18.758,0,0,1-2.061.121c-3.636,0-6.544-1.333-6.544-8.726V71.346H93.57Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path d="M124.972,83.344H136.85v-12H124.972v12Zm.121,73.565H136.85V95.584H125.093Z" fill={fill} fillRule="evenodd" />
          <path
            d="M234.01,156.91h11.756V128.065h8.847l18.422,28.844h13.695l-22.663-34.056v-.242l20.361-27.027H271.1l-16.725,22.543h-8.6V71.345H234.01Z"
            fill={fill}
            fillRule="evenodd"
          />
          <path
            d="M233.749,186.887c0,3.494,2.228,3.906,3.741,3.906a7.484,7.484,0,0,0,.88-.055v-2.366a4.168,4.168,0,0,1-.467.028c-.825,0-1.486-.3-1.486-1.981V171.261h-2.668v15.626Zm7.128-12.9h2.7v-2.724h-2.7v2.724Zm.028,16.7h2.668v-13.92H240.9v13.92Zm9.851,0h3.081l5.145-13.92h-2.806l-3.385,9.409c-.274.77-.467,1.9-.495,1.9h-.054c-.028,0-.193-1.128-.468-1.9l-3.384-9.409h-2.833l5.2,13.92Zm10.209,0h2.669v-5.694a8.45,8.45,0,0,1,.33-2.5,4.223,4.223,0,0,1,3.906-3.274,3.814,3.814,0,0,1,.77.083v-2.641a4.469,4.469,0,0,0-5.089,3.577H263.5a10,10,0,0,0,.055-1.046v-2.421h-2.586v13.92Zm8.971-6.96a7.089,7.089,0,0,0,7.374,7.29,8.286,8.286,0,0,0,5.391-1.98l-1.1-1.954a6.635,6.635,0,0,1-4.127,1.624,4.549,4.549,0,0,1-4.786-4.6h10.151s.082-.8.082-1.155c0-3.6-2.09-6.52-6.024-6.52-3.962,0-6.961,2.861-6.961,7.29Zm2.834-1.623a3.989,3.989,0,0,1,4.072-3.549c1.788,0,3.273,1.156,3.356,3.549Z"
            fill="#33a437"
            fillRule="evenodd"
          />
          <path
            d="M191.606,131.191l5.863,5.862L172.7,165.9l-9.94-9.939,28.846-24.769Zm21.567-63L210.287,106.1H202l-2.883-37.909ZM172.7,79.128l24.766,28.846-5.863,5.863L162.76,89.071l9.943-9.943Zm29.3,59.8h8.291l2.882,37.91H199.118L202,138.931Zm-12.271-20.563,0,8.291-37.913,2.883v-14.06Z"
            fill="#33a437"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export const LogoShort = ({ width = 50 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.943 217.699" {...{ width }}>
      <path
        d="M231.544,194.426l11.748,11.746-49.63,57.8-19.917-19.915,57.8-49.63ZM274.759,68.191l-5.783,75.959h-16.6l-5.777-75.959Zm-81.1,21.915,49.624,57.8-11.748,11.748-57.793-49.624,19.923-19.923Zm58.709,119.823h16.613l5.775,75.961H246.6l5.775-75.955Zm-24.588-41.2v16.613l-75.967,5.777V162.943Z"
        transform="translate(-151.816 -68.191)"
        fill="#33a437"
      />
    </svg>
  );
};
