import React, { useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { masks } from "lib";
import { MdBlock, MdWhatsapp } from "react-icons/md";
import { LuMailCheck, LuMailWarning } from "react-icons/lu";
import { VscSend } from "react-icons/vsc";
import { HiOutlineMail } from "react-icons/hi";
import { TbHandClick } from "react-icons/tb";
import { BiMessageSquareDetail } from "react-icons/bi";
import { messageTopics } from "consts";
import { ErrViewer } from "components";

const modes = {
  "bank-slip": "Fatura detalhada",
  "digitable-line": "Linha digitável",
  "pix-info-emv": "Pix Copia e Cola",
};

const MessageStatus = ({ status, errs }) => {
  if (_.size(errs) >= 1)
    return (
      <Popover placement="left" trigger="hover">
        <PopoverTrigger>
          <IconButton size="sm" isRound={true} colorScheme="red" icon={<Icon as={MdBlock} />} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody as={VStack} alignItems="stretch">
            {_.map(errs, (err) => (
              <ErrViewer key={err._id} err={err} />
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  switch (status) {
    case "waiting":
    case "sending":
      return (
        <Tooltip label="A mensagem está sendo enviada ao destinatário." placement="left">
          <Center bg="gray.100" w="32px" h="32px" borderRadius="full">
            <Spinner size="sm" />
          </Center>
        </Tooltip>
      );
    case "sent":
      return (
        <Tooltip label="A mensagem foi enviada ao destinatário. No entanto o recebimento ainda não foi confirmado." placement="left">
          <Center bg="gray.100" w="32px" h="32px" borderRadius="full">
            <Icon as={LuMailCheck} />
          </Center>
        </Tooltip>
      );
    case "delivery":
      return (
        <Tooltip label="A mensagem foi entregue com sucesso ao destinatário." placement="left">
          <Center bg="green.100" w="32px" h="32px" borderRadius="full">
            <Icon as={LuMailCheck} color="green.500" />
          </Center>
        </Tooltip>
      );
    default:
      return (
        <Tooltip label="A mensagem não foi entregue ao destinatário." placement="left">
          <Center bg="red.100" w="32px" h="32px" borderRadius="full">
            <Icon as={LuMailWarning} color="red.500" />
          </Center>
        </Tooltip>
      );
  }
};

const Interactions = ({ interactions }) => {
  return (
    <Popover placement="left">
      <PopoverTrigger>
        <IconButton size="sm" isRound={true} variant="outline" icon={<Icon as={TbHandClick} />} isDisabled={_.size(interactions) === 0} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Text fontSize="sm" fontWeight="semibold">
            Interações do cliente ({_.size(interactions)})
          </Text>
          <Text fontSize="xs">Lista de interações com a mensagem enviada.</Text>
        </PopoverHeader>
        <PopoverBody as={VStack} maxH="200px" overflowY="auto" alignItems="stretch" divider={<StackDivider />}>
          {_.map(interactions, (item) => (
            <Box key={item._id}>
              <Text fontSize="xs" fontWeight="semibold">
                {modes[item.mode] ?? item.mode}
              </Text>
              <Text fontSize="xs">
                Interação feita em {moment(item.createdAt).format("DD/MM/YYYY")} às {moment(item.createdAt).format("HH:mm")}
              </Text>
            </Box>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const Message = ({ item, isHighlighted, borderColor, borderWidth, ...props }) => {
  const [icon, colorScheme, contactTarget] = useMemo(() => {
    if (item.channel === "email") return [HiOutlineMail, "blue", item.contactTarget];
    return [MdWhatsapp, "green", masks.phone(item.contactTarget)];
  }, [item.contactTarget, item.channel]);

  return (
    <Box>
      {isHighlighted && (
        <HStack>
          <Box bg="purple.500" px="10px" py="2px" borderTopRadius="md">
            <Text fontSize="x-small" fontWeight="semibold" color="white">
              Mensagem atual
            </Text>
          </Box>
        </HStack>
      )}
      <HStack
        {...props}
        p="15px"
        borderRadius="lg"
        borderTopLeftRadius={isHighlighted ? "0" : "lg"}
        borderColor={isHighlighted ? "purple.500" : borderColor}
        borderWidth={isHighlighted ? "2px" : borderWidth}
      >
        <Center w="32px" h="50px" borderRadius="xl" bg={colorScheme.concat(".100")}>
          <Icon as={icon} color={colorScheme.concat(".500")} />
        </Center>
        <Box flex="1">
          <Text fontSize="x-small">{messageTopics[item.topic]}</Text>
          <Text fontSize="xs">
            {item.contactName || "Desconhecido"} • <strong>{contactTarget}</strong>
          </Text>
          <Text fontSize="x-small">
            Enviado em {moment(item.createdAt).format("DD/MM/YYYY")} às {moment(item.createdAt).format("HH:mm")} por {item.createdBy.name}
          </Text>
        </Box>
        <Interactions {...item} />
        <MessageStatus {...item} />
      </HStack>
    </Box>
  );
};

export const MessagesList = ({ data, isLoading, highlighted }) => {
  const pools = useMemo(() => _.groupBy(data, (o) => o.pool), [data]);

  return (
    <>
      <VStack alignItems="stretch" spacing={2}>
        {_.map(pools, (messages) => {
          const [item] = messages;
          if (messages.length === 1) return <Message borderWidth="1px" {...{ item }} isHighlighted={item._id === highlighted} />;
          return (
            <Box borderWidth="1px" borderRadius="lg">
              <HStack p="15px">
                <Center w="32px" h="32px" borderRadius="full" _light={{ bg: "gray.100" }}>
                  <Icon as={BiMessageSquareDetail} />
                </Center>
                <Box>
                  <Text fontSize="xs" fontWeight="semibold">
                    Pool de mensagens
                  </Text>
                  <Text fontSize="x-small">{item.pool}</Text>
                </Box>
              </HStack>
              <Divider />
              <VStack alignItems="stretch" p="10px" spacing={2}>
                {_.map(messages, (item) => (
                  <Message _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }} {...{ item }} isHighlighted={item._id === highlighted} />
                ))}
              </VStack>
            </Box>
          );
        })}
      </VStack>

      {isLoading ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={VscSend} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum envio realizado
              </Text>
              <Text fontSize="sm">Ainda não foram realizados envios desta fatura/cobrança.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};
