import React, { useMemo, useState, useCallback } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Box, Center, Heading, HStack, Icon, IconButton, Link, StackDivider, Text, useDisclosure, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useFetchData, useCustomToast, usePermissioned } from "hooks";
import { MdClose, MdKeyboardArrowDown, MdKeyboardArrowUp, MdRefresh } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import { messages } from "consts";
import { api } from "lib";

const UnifiedInvoiceChangeAlert = () => {
  const isAllowedCustomersRead = usePermissioned("customers.read");
  const [data, isLoadingData, refreshData] = useFetchData(
    useMemo(
      () => ({
        path: "/private/consumer-units",
        params: { query: { consumerUnit: { isUnifiedInvoiceChanged: true } }, sort: { nid: 1 }, perPage: -1 },
        options: { isEnabled: isAllowedCustomersRead },
      }),
      [isAllowedCustomersRead]
    )
  );
  const { isOpen, onToggle } = useDisclosure();
  const [isLoadingDismissData, setIsLoadingDismissData] = useState({});
  const toast = useCustomToast();

  const handleDismissData = useCallback(
    async ({ _id, customer }) => {
      try {
        setIsLoadingDismissData((state) => ({ ...state, [_id]: true }));
        await api.patch(`/private/customers/${customer._id}/consumer-units/${_id}`, { isUnifiedInvoiceChanged: false });
        refreshData();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingDismissData((state) => ({ ...state, [_id]: false }));
      }
    },
    [refreshData, toast]
  );

  return (
    _.size(data?.data) >= 1 && (
      <Box _light={{ bg: "red.100" }} _dark={{ bg: "gray.900" }} p="20px" borderRadius="lg" my={4}>
        <HStack>
          <Center w="30px" h="30px" borderRadius="full" bg="red.500">
            <Icon as={BiMailSend} color="white" />
          </Center>
          <Box flex="1">
            <Heading size="xs">Faturas unificadas modificadas ({_.size(data?.data)})</Heading>
            <Text fontSize="xs">Algumas UCs foram modificadas para "Fatura separada" durante a importação de faturas da CEMIG.</Text>
          </Box>
          <IconButton
            size="xs"
            variant="outline"
            colorScheme="red"
            icon={<Icon as={isOpen ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
            onClick={onToggle}
          />
          <IconButton
            size="xs"
            variant="outline"
            colorScheme="red"
            icon={<Icon as={MdRefresh} />}
            isLoading={isLoadingData}
            onClick={refreshData}
          />
        </HStack>
        {isOpen && (
          <VStack alignItems="stretch" divider={<StackDivider borderColor="blackAlpha.100" />} spacing={4} mt={4}>
            {_.map(data?.data, (consumerUnit) => (
              <Box key={consumerUnit._id}>
                <HStack>
                  <Box flex="1">
                    <Text fontSize="xs">
                      N° instalação:{" "}
                      <Link as={RouterLink} to={`/customers/edit/${consumerUnit.customer._id}/#consumer-units`} target="_blank">
                        <strong>{consumerUnit?.cemigInstallationNumber}</strong>
                      </Link>
                    </Text>
                    <Text fontSize="xs">Cliente: {consumerUnit.customer?.name}</Text>
                    {consumerUnit.isUnifiedInvoiceChangedAt && (
                      <Text fontSize="xs">
                        Data da modificação: {moment(consumerUnit.isUnifiedInvoiceChangedAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                      </Text>
                    )}
                  </Box>
                  <IconButton
                    size="xs"
                    colorScheme="red"
                    icon={<Icon as={MdClose} />}
                    isLoading={isLoadingDismissData[consumerUnit._id]}
                    onClick={() => handleDismissData(consumerUnit)}
                  />
                </HStack>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    )
  );
};

export default UnifiedInvoiceChangeAlert;
