export const document = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    if (value.length === 14) return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } catch (error) {
    return "-";
  }
};

export const phone = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    if (value.length === 11) return value.replace(/(\d{2})(\d{5})(\d{4,})/, "($1) $2-$3");
    return value.replace(/(\d{2})(\d{4})(\d{4,})/, "($1) $2-$3");
  } catch (error) {
    return "-";
  }
};

export const zipCode = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{5})(\d{3})/, "$1-$2");
  } catch (error) {
    return "-";
  }
};

export const digitableLine = (value) => {
  try {
    // 99999.99999 99999.999999 99999.999999 9 99999999999999
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/, "$1.$2 $3.$4 $5.$6 $7 $8");
  } catch (error) {
    return "-";
  }
};

export const obfuscate = (value = "", noOfLasts = 4) => {
  try {
    return "*".repeat(value.length - noOfLasts) + value.slice(-noOfLasts);
  } catch (error) {
    return value;
  }
};

export const obfuscateEmail = function (email = "") {
  return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
};
