import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFetchData, useQueryParams, useWatcherZIndex } from "hooks";
import { CustomTab } from "components";
import { MdRefresh } from "react-icons/md";
import { BiListUl } from "react-icons/bi";
import { VscSend } from "react-icons/vsc";
import MessagesDetailsContext from "./context";
import General from "./general";
import Messages from "./messages";
import Archive from "./archive";
import Resend from "./resend";

const queryParamsKey = "message_id";

export const MessagesDetails = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const _id = useMemo(() => queryParams[queryParamsKey], [queryParams[queryParamsKey]]);
  const zIndex = useWatcherZIndex(queryParamsKey);
  const [counters, setCounters] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/messages/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  useEffect(() => {
    if (_.isString(_id)) onOpen();
    else onClose();
  }, [_id]);

  const handleClose = useCallback(() => {
    delete queryParams[queryParamsKey];
    setQueryParams(queryParams, { replace: true });
  }, [queryParams]);

  return (
    <MessagesDetailsContext.Provider value={{ _id, formData, isLoadingData, refreshData, setCounters, setIsLoading }}>
      <Drawer isOpen={isOpen} size="md" placement="right" blockScrollOnMount={false} onClose={handleClose}>
        <DrawerOverlay zIndex={zIndex.overlay} />
        <DrawerContent zIndex={zIndex.content}>
          <DrawerHeader as={HStack}>
            <Text flex="1">Mensagem</Text>
            <Resend />
            <Archive />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          </DrawerHeader>
          <DrawerBody>
            <Tabs variant="solid-rounded" size="sm" isFitted colorScheme="main">
              <TabList>
                <CustomTab icon={<Icon as={BiListUl} />} title="dados gerais" />
                <CustomTab icon={<Icon as={VscSend} />} title="mensagens" isLoading={isLoading.messages} count={counters.messages} />
              </TabList>
              <TabPanels>
                <TabPanel py="20px" px="0">
                  <General />
                </TabPanel>
                <TabPanel py="20px" px="0">
                  <Messages />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <Button size="sm" variant="outline" onClick={handleClose}>
              fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </MessagesDetailsContext.Provider>
  );
};
