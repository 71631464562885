import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Divider,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { locale, percent } from "lib";
import InvoicesDetailsContext from "../context";
import { HiOutlineClipboardList } from "react-icons/hi";

const ElectricityBalance = () => {
  const { formData } = useContext(InvoicesDetailsContext);

  return (
    <Popover size="lg">
      <PopoverTrigger>
        <IconButton icon={<Icon as={HiOutlineClipboardList} />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody p="10px">
          <Box borderRadius="lg" borderWidth="1px" p="10px" mb={2}>
            <Text fontSize="xs" mb={1}>
              Saldo atual
            </Text>
            <VStack alignItems="stretch" spacing={1}>
              <HStack>
                <Text fontSize="xs">Referência</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {formData.consumerUnit?.currentElectricityBalance?.referenceDate
                    ? moment(formData.consumerUnit?.currentElectricityBalance?.referenceDate).format("MMM/YYYY").toUpperCase()
                    : "-"}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Saldo de energia</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {locale(formData.consumerUnit?.currentElectricityBalance?.currentPowerQttBalanceInKwh || 0, { precision: 0 })} kWh
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Quota</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {percent(formData.consumerUnit?.currentElectricityBalance?.quota, { precision: 5 })}
                </Text>
              </HStack>
            </VStack>
          </Box>

          <Box borderRadius="lg" borderWidth="1px" p="10px" mb={2}>
            <Text fontSize="xs" mb={1}>
              Saldo de outra titularidade
            </Text>
            <VStack alignItems="stretch" spacing={1}>
              <HStack>
                <Text fontSize="xs">Referência</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {formData.consumerUnit?.otherElectricityBalance?.referenceDate
                    ? moment(formData.consumerUnit?.otherElectricityBalance?.referenceDate).format("MMM/YYYY").toUpperCase()
                    : "-"}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Saldo de energia</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {locale(formData.consumerUnit?.otherElectricityBalance?.currentPowerQttBalanceInKwh || 0, { precision: 0 })} kWh
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Quota</Text>
                <Divider flex="1" />
                <Text fontSize="xs" fontWeight="semibold">
                  {percent(formData.consumerUnit?.otherElectricityBalance?.quota, { precision: 5 })}
                </Text>
              </HStack>
            </VStack>
          </Box>

          <Box borderRadius="lg" borderWidth="1px" p="10px" mb={2}>
            <Text fontSize="xs" mb={1}>
              Últimos consumos
            </Text>
            <VStack align="stretch" spacing={1}>
              {_.map(
                formData.consumerUnit?.lastsElectricityConsumes,
                (item, index) =>
                  index <= 3 && (
                    <HStack>
                      <Text fontSize="x-small">{moment(item.referenceDate).format("MM/YYYY")}</Text>
                      <Divider flex="1" />
                      <Text fontSize="x-small" fontWeight="bold">
                        {locale(item.consumedPowerQttInKwh || 0, { precision: 0 })} kWh
                      </Text>
                    </HStack>
                  )
              )}
            </VStack>
          </Box>

          <HStack borderRadius="lg" borderWidth="1px" p="10px">
            <Text fontSize="xs">Méd. últ. {_.size(formData.consumerUnit?.lastsElectricityConsumes)} consumos</Text>
            <Divider flex="1" />
            <Text fontSize="xs" fontWeight="semibold">
              {locale(formData.consumerUnit?.avgLastsElectricityConsumes || 0, { precision: 0 })} kWh
            </Text>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ElectricityBalance;
