import React, { Fragment, useContext } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { MdClose, MdInfoOutline } from "react-icons/md";
import InvoicesDetailsContext from "./context";

const InvalidatedReasons = () => {
  const { formData } = useContext(InvoicesDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <IconButton size="xs" icon={<Icon as={MdInfoOutline} />} onClick={onOpen} />
      <Modal size="4xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Motivos</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody py="20px" as={VStack} spacing={4} alignItems="stretch">
            {_.size(formData.invalidatedReasons) >= 1 && (
              <VStack alignItems="stretch" divider={<StackDivider />}>
                {_.map(formData.invalidatedReasons, (item) => (
                  <Box key={item._id} py="5px">
                    <Text fontSize="sm" fontWeight="semibold">
                      {item.title}
                    </Text>
                    {_.size(item.description) >= 1 && <Text fontSize="xs">{item.description}</Text>}
                  </Box>
                ))}
              </VStack>
            )}
            {_.size(formData.invalidatedComments) >= 1 && (
              <Box p="20px" borderRadius="lg" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
                <Text fontSize="xs" fontWeight="semibold">
                  Outras observações
                </Text>
                <Text fontSize="sm">{formData.invalidatedComments}</Text>
              </Box>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default InvalidatedReasons;
