import React, { useCallback, useContext, useState, useEffect, Fragment, useMemo } from "react";
import ObjectID from "bson-objectid";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { currency, invoiceHelpers } from "lib";
import { InputCurrency, Portal } from "components";
import { messages } from "consts";
import * as yup from "yup";
import { MdAdd, MdDelete } from "react-icons/md";
import InvoicesDetailsContext from "../context";
import TraderEletricPower from "./eletricPower";
import { TbBolt } from "react-icons/tb";
import { getDefaultTraderEletricPower } from "..";

const Items = () => {
  const { formData, setFormData, isAnalyzing } = useContext(InvoicesDetailsContext);
  const { isOpen: isOpenAddItem, onOpen: onOpenAddItem, onClose: onCloseAddItem } = useDisclosure();

  const handleAddEletricPower = useCallback(() => {
    setFormData((state) => {
      const items = [...state.items];
      const index = _.findLastIndex(items, (o) => o.type === "eletric_power");
      if (index === -1) items.unshift({ ...getDefaultTraderEletricPower() });
      else items.splice(index + 1, 0, { ...getDefaultTraderEletricPower() });
      return { ...state, items };
    });
  }, []);

  const handleRemoveItem = useCallback((index) => {
    setFormData((state) => {
      const items = [...state.items];
      items.splice(index, 1);
      const tmp = { ...state, items };
      invoiceHelpers.reprocessAmounts(tmp);
      return tmp;
    });
  }, []);

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg" mb={4}>
        <HStack p="20px" justifyContent="space-between">
          <Box>
            <Heading size="sm">Valores Click</Heading>
            <Text fontSize="sm">
              Os valores da Click são calculados automaticamente com base nos valores da CEMIG, mas podem ser alterados manualmente se
              necessário.
            </Text>
          </Box>
          {isAnalyzing &&
            (formData.type === "automatic" ? (
              <Button size="sm" variant="outline" rightIcon={<Icon as={MdAdd} />} onClick={onOpenAddItem}>
                adicionar item
              </Button>
            ) : (
              <Box>
                <Menu>
                  <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdAdd} />}>
                    adicionar item
                  </MenuButton>
                  <Portal>
                    <MenuList fontSize="sm">
                      <MenuItem onClick={handleAddEletricPower}>
                        <HStack>
                          <Icon as={TbBolt} />
                          <Text>energia elétrica</Text>
                        </HStack>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={onOpenAddItem}>
                        <HStack>
                          <Icon as={MdAdd} />
                          <Text>outro</Text>
                        </HStack>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </Box>
            ))}
        </HStack>
        <Divider />
        <Table fontSize="sm">
          <Thead>
            <Tr>
              <Th>Descrição</Th>
              <Th textAlign="center">Quantidade</Th>
              <Th textAlign="center">Tarifa</Th>
              <Th textAlign="right">Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(formData.items, (item, index) => (
              <Fragment key={item._id}>
                {item.type === "eletric_power" ? (
                  <TraderEletricPower {...{ item, index }} onRemove={() => handleRemoveItem(index)} />
                ) : (
                  <Tr>
                    <Td>
                      <HStack>
                        <Button size="xs" variant="outline" rightIcon={<Icon as={MdDelete} />} onClick={() => handleRemoveItem(index)}>
                          remover
                        </Button>
                        <Text fontSize="sm">{item.description}</Text>
                      </HStack>
                    </Td>
                    <Td textAlign="center">{item.powerQttInKwh ? `${item.powerQttInKwh?.toLocaleString()} kWh` : "-"}</Td>
                    <Td textAlign="center">{item.powerPricePerKwh ? currency(item.powerPricePerKwh, { precision: 8 }) : "-"}</Td>
                    <Td textAlign="right" whiteSpace="nowrap">
                      {item.amount && currency(item.amount)}
                    </Td>
                  </Tr>
                )}
              </Fragment>
            ))}
          </Tbody>
          <Tfoot>
            <Tr fontWeight="semibold">
              <Td colSpan={3} borderBottomWidth="0">
                <Text>TOTAL</Text>
                {formData.traderCreditAmount > 0 && (
                  <Text fontWeight="bold" color="green.500">
                    CRÉDITO APLICADO
                  </Text>
                )}
              </Td>
              <Td textAlign="right" borderBottomWidth="0">
                <Text>{currency(formData.amount)}</Text>
                {formData.traderCreditAmount > 0 && (
                  <Text fontWeight="bold" whiteSpace="nowrap" color="main.500">
                    -{currency(formData.traderCreditAmount)}
                  </Text>
                )}
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Box>

      <AddItem isOpen={isOpenAddItem} onClose={onCloseAddItem} />
    </>
  );
};

const AddItem = ({ isOpen, onClose }) => {
  const { setFormData: setParentFormData } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [operation, setOperation] = useState(1);

  useEffect(() => {
    setFormData({ type: "others", description: "", amount: 0 });
  }, [isOpen]);

  const handleAddItem = useCallback((item) => {
    setParentFormData((state) => {
      const items = [...state.items];
      items.push(item);
      const tmp = { ...state, items };
      invoiceHelpers.reprocessAmounts(tmp);
      return tmp;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        description: yup.string().required(messages.error.required),
        amount: yup.number().required(messages.error.required),
      });
      const data = { ...formData, _id: ObjectID().toHexString(), amount: Math.abs(formData.amount) * operation };
      await schema.validate(data, { abortEarly: false });
      handleAddItem(data);
      onClose();
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, operation, handleAddItem, onClose]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar item manualmente</ModalHeader>
          <Divider />
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl>
                  <FormLabel fontSize="sm">Tipo</FormLabel>
                  <Select value={operation.toString()} onChange={({ target }) => setOperation(parseInt(target.value))}>
                    <option value="1">Acréscimo</option>
                    <option value="-1">Desconto</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.description}>
                  <FormLabel fontSize="sm">Descrição</FormLabel>
                  <Input
                    value={formData.description ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.description}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 12 }}>
                <FormControl isRequired={true} isInvalid={formErrors.amount}>
                  <FormLabel fontSize="sm">Total</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>R$</InputLeftAddon>
                    <Input
                      as={InputCurrency}
                      value={formData.amount ?? ""}
                      onChange={(amount) => setFormData((state) => ({ ...state, amount }))}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formErrors.amount}</FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleSubmit}>
              adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Items;
