import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { api, masks } from "lib";
import { AddressText } from "components";
import { phoneCategories, messages } from "consts";
import { useCustomToast } from "hooks";
import { MdEdit, MdOutlineDelete, MdRefresh } from "react-icons/md";
import { GoLaw } from "react-icons/go";
import Details from "./details";
import CustomersDetailsContext from "../context";
import EconomicGroup from "./economicGroup";

export const LegalRepresentativesContext = createContext();

const LegalRepresentatives = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading, legalRepresentatives, isLoadingLegalRepresentatives, refreshLegalRepresentatives } =
    useContext(CustomersDetailsContext);
  const [editSelected, setEditSelected] = useState();
  const [deleteSelected, setDeleteSelected] = useState();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setCounters((state) => ({ ...state, legalRepresentatives: legalRepresentatives?.size }));
  }, [legalRepresentatives?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, legalRepresentatives: isLoadingLegalRepresentatives }));
  }, [isLoadingLegalRepresentatives]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/private/customers/${_id}/legal-representatives/${deleteSelected._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshLegalRepresentatives();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      setDeleteSelected();
    }
  }, [toast, deleteSelected?._id, refreshLegalRepresentatives]);

  return (
    <LegalRepresentativesContext.Provider value={{ editSelected, setEditSelected, refreshLegalRepresentatives }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(legalRepresentatives?.data)} registros
        </Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingLegalRepresentatives}
          onClick={refreshLegalRepresentatives}
        />
        <Tooltip
          placement="left"
          isDisabled={_id}
          shouldWrapChildren
          label="Antes de adicionar observações você deve salvar o cliente atual."
        >
          <Button size="sm" colorScheme="main" isDisabled={!_id} onClick={() => setEditSelected({})}>
            incluir representante
          </Button>
        </Tooltip>
      </HStack>

      <VStack align="stretch">
        {_.map(legalRepresentatives?.data, (item) => (
          <Box key={item._id} borderWidth="1px" borderRadius="lg">
            <Grid templateColumns="repeat(12, 1fr)" gap={4} p="20px">
              <GridItem as={HStack} colSpan={{ base: 12, lg: 6 }}>
                <Center bg={item.isActive ? "blue.100" : "gray.100"} w="45px" h="45px" borderRadius="full">
                  <Icon as={GoLaw} color={item.isActive ? "blue.500" : "gray.500"} />
                </Center>
                <Box flex="1">
                  {_.size(item.economicGroup) > 1 && <EconomicGroup data={item.economicGroup} />}
                  <Heading size="md">{item.name}</Heading>
                  <AddressText address={item.address} />
                </Box>
              </GridItem>
              <GridItem as={HStack} justifyContent={{ base: "center", lg: "flex-end" }} colSpan={{ base: 12, lg: 6 }}>
                <Button size="sm" rightIcon={<Icon as={MdEdit} />} variant="outline" onClick={() => setEditSelected(item)}>
                  editar
                </Button>
                <Button size="sm" rightIcon={<Icon as={MdOutlineDelete} />} variant="outline" onClick={() => setDeleteSelected(item)}>
                  excluir
                </Button>
              </GridItem>
            </Grid>
            <Divider />
            <Box p="20px" opacity={item.isActive ? 1 : 0.5}>
              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs">CPF</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {masks.document(item.document)}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs">RG</Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    {item.rg ?? "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs">E-mail</Text>
                  <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
                    {item.email ?? "-"}
                  </Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }}>
                  <Text fontSize="xs">Telefone</Text>
                  <HStack>
                    <Icon as={phoneCategories.find((o) => o.value === item.phoneCategory)?.icon} />
                    <Text fontSize="sm" fontWeight="semibold">
                      {masks.phone(item.phone)}
                    </Text>
                  </HStack>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        ))}
      </VStack>

      {isLoadingLegalRepresentatives ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(legalRepresentatives?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={GoLaw} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum representante adicionado
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui representantes legais adicionados.</Text>
            </Box>
          </Center>
        )
      )}

      <Details />

      <AlertDialog isOpen={deleteSelected} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir a unidade consumidora selecionada?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => setDeleteSelected()}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" isLoading={isLoadingDeleteData} onClick={handleDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </LegalRepresentativesContext.Provider>
  );
};

export default LegalRepresentatives;
