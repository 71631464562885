import React, { useEffect, useState } from "react";
import _ from "lodash";
import { HStack, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

export const RangeInput = ({
  as,
  precision,
  InputRightElement,
  defaultStartValue,
  defaultEndValue,
  onChange,
  isPercentage,
  inputGroupProps = {},
}) => {
  const [formData, setFormData] = useState({ startValue: defaultStartValue, endValue: defaultEndValue });

  useEffect(() => {
    onChange(formData);
  }, [formData]);

  return (
    <HStack spacing="1px">
      <InputGroup size="sm" variant="filled" {...inputGroupProps}>
        <InputLeftElement fontSize="xs">de</InputLeftElement>
        <Input
          borderRightRadius="0"
          value={formData.startValue ?? ""}
          onChange={(startValue) => setFormData((state) => ({ ...state, startValue }))}
          {...{ as, precision, isPercentage }}
        />
        {InputRightElement}
      </InputGroup>
      <InputGroup size="sm" variant="filled" {...inputGroupProps}>
        <InputLeftElement fontSize="xs">até</InputLeftElement>
        <Input
          borderLeftRadius="0"
          value={formData.endValue ?? ""}
          onChange={(endValue) => setFormData((state) => ({ ...state, endValue }))}
          {...{ as, precision, isPercentage }}
        />
        {InputRightElement}
      </InputGroup>
    </HStack>
  );
};
