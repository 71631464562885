import React, { useCallback, memo, useState, useContext } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import fileDownload from "js-file-download";
import { api, formatBytes } from "lib";
import { FileImage } from "components";
import { useCustomToast } from "hooks";
import { MdCheck, MdClose, MdDelete, MdEdit, MdHistory, MdOutlineDownload } from "react-icons/md";
import FileListContext from "./context";

const FileItem = memo(({ path = "/private/files", item, isEditable, isDeletable }) => {
  const { setSelected, onChange } = useContext(FileListContext);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ title: item.title });
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { isOpen: isOpenDeleteData, onOpen: onOpenDeleteData, onClose: onCloseDeleteData } = useDisclosure();
  const toast = useCustomToast();

  const handleDownloadData = useCallback(async () => {
    try {
      setIsDownloading(true);
      const data = await api({
        method: "post",
        url: `${path}/${item._id}/object`,
        responseType: "blob",
      });
      fileDownload(data, `${formData.title}.${item.ext}`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  }, [path, item._id, item.ext, formData.title]);

  const handleDeleteData = useCallback(async () => {
    try {
      onCloseDeleteData();
      setIsLoadingDeleteData(true);
      await api.delete(`${path}/${item._id}`);
      onChange();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
    }
  }, [path, item._id, toast, onChange]);

  const handleSaveData = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.patch(`${path}/${item._id}`, formData);
      setIsEditing(false);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [path, formData]);

  const handleCancel = useCallback(() => {
    setFormData({ title: item.title });
    setIsEditing(false);
  }, [item.title]);

  return (
    <>
      <HStack py="10px" display={{ base: "block", lg: "flex" }} spacing="0">
        <HStack flex="1" mb={{ base: "10px", lg: "0" }}>
          <FileImage path={path} _id={item._id} type={item.type} title={item.title} location={item.thumb} ext={item.ext} boxSize="60px" />
          <Box flex="1">
            {isEditing ? (
              <HStack>
                <Input
                  w="300px"
                  size="xs"
                  value={formData.title ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                />
                <IconButton
                  size="xs"
                  colorScheme="green"
                  icon={<Icon as={MdCheck} />}
                  isLoading={isLoadingSaveData}
                  isDisabled={_.size(formData.title) === 0}
                  onClick={handleSaveData}
                />
                <IconButton size="xs" variant="outline" icon={<Icon as={MdClose} />} onClick={handleCancel} />
              </HStack>
            ) : (
              <HStack>
                <Text fontSize="sm" fontWeight="semibold">
                  {formData.title}
                </Text>
                {isEditable && <IconButton size="xs" variant="outline" icon={<Icon as={MdEdit} />} onClick={() => setIsEditing(true)} />}
              </HStack>
            )}
            <Text fontSize="xs">{item.type}</Text>
            <Text fontSize="xs">{formatBytes(item.size)}</Text>
          </Box>
        </HStack>
        <HStack>
          <Button
            flex={{ base: "1", lg: "none" }}
            size="sm"
            variant="outline"
            rightIcon={<Icon as={MdHistory} />}
            onClick={() => setSelected(item)}
          >
            histórico
          </Button>
          <Button
            flex={{ base: "1", lg: "none" }}
            size="sm"
            variant="outline"
            rightIcon={<Icon as={MdOutlineDownload} />}
            isLoading={isDownloading}
            onClick={handleDownloadData}
          >
            download
          </Button>
          {isDeletable && (
            <Button
              flex={{ base: "1", lg: "none" }}
              size="sm"
              variant="outline"
              rightIcon={<Icon as={MdDelete} />}
              isLoading={isLoadingDeleteData}
              onClick={onOpenDeleteData}
            >
              excluir
            </Button>
          )}
        </HStack>
      </HStack>
      <AlertDialog isOpen={isOpenDeleteData} onClose={onCloseDeleteData} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir o arquivo selecionado?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteData}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
});

export default FileItem;
