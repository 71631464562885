import * as yup from "yup";
import _ from "lodash";
import { messages } from "consts";

yup.addMethod(yup.string, "isValidName", function (errorMessage) {
  return this.test("is-valid-name", errorMessage, function (value) {
    const { path, createError } = this;
    try {
      const values = value.trim().split(" ");
      if (values.length < 2) throw new Error();
      return true;
    } catch (error) {
      return createError({ path, message: messages.error.invalidName });
    }
  });
});

yup.addMethod(yup.string, "isValidCemigNumber", function (requiredFirstDigit) {
  return this.test("is-valid-cemig-installation-number", null, function (value) {
    const { path, createError } = this;
    try {
      if (value.replace(/\D/g, "").length !== 10) throw new Error(messages.error.invalidCemigNumber);
      if (parseInt(value.charAt(0)) !== parseInt(requiredFirstDigit))
        throw new Error(`${messages.error.invalidCemigNumberFirstDigit} ${requiredFirstDigit}.`);
      return true;
    } catch (error) {
      return createError({ path, message: error.message });
    }
  });
});

yup.addMethod(yup.string, "isValidPhoneNumber", function () {
  return this.test("is-valid-phone-number", null, function (value = "") {
    const { path, createError } = this;
    try {
      const phoneNumber = value.replace(/\D/g, "");
      if (phoneNumber.length) {
        const phoneCategoryPath = this.path.split(".").pop().concat("Category");
        const phoneCategory = this.parent[phoneCategoryPath];
        const requiredPhoneLength = { cellphone: 11, whatsapp: phoneNumber.charAt(2) === "3" ? 10 : 11, phone: 10 };
        if (phoneNumber.length !== requiredPhoneLength[phoneCategory]) throw new Error(messages.error.invalidPhoneNumber);
      }
      return true;
    } catch (error) {
      return createError({ path, message: error.message });
    }
  });
});

export default yup;
