export const firebaseAuthErrors = {
  "auth/invalid-email": "O endereço de e-mail informado é inválido. Verifique e tente novamente.",
  "auth/user-disabled": "Esta conta foi desativada. Entre em contato com o suporte para mais informações.",
  "auth/user-not-found": "Nenhuma conta encontrada com este e-mail. Verifique as informações e tente novamente.",
  "auth/wrong-password": "A senha informada está incorreta. Verifique e tente novamente.",
  "auth/email-already-in-use": "O endereço de e-mail já está em uso por outra conta. Tente fazer login ou redefinir a senha.",
  "auth/operation-not-allowed": "Esta operação de login não está habilitada. Entre em contato com o suporte.",
  "auth/weak-password": "A senha informada é muito fraca. Escolha uma senha mais forte.",
  "auth/too-many-requests": "Muitas tentativas de login. Tente novamente mais tarde.",
  "auth/requires-recent-login": "Para realizar esta operação, faça login novamente e tente novamente.",
  "auth/invalid-credential": "As credenciais fornecidas são inválidas ou expiraram. Tente novamente.",
  "auth/account-exists-with-different-credential":
    "Já existe uma conta com o mesmo endereço de e-mail, mas com credenciais diferentes. Tente fazer login com um provedor diferente.",
  "auth/invalid-verification-code": "O código de verificação fornecido é inválido. Verifique e tente novamente.",
  "auth/invalid-verification-id": "O ID de verificação fornecido é inválido. Verifique e tente novamente.",
};
