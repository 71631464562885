import { useMemo } from "react";
import _ from "lodash";
import { usePermissioned } from "../../hooks";

export const PermissionedContainer = ({ required, children }) => {
  const isAllowed = usePermissioned(useMemo(() => required, [required]));
  if (_.isObject(isAllowed)) {
    if (Object.keys(isAllowed).length >= 1) return children;
    return null;
  }
  if (isAllowed) return children;
  return null;
};
