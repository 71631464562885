import React, { useState, useCallback } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import fileDownload from "js-file-download";
import { useCustomToast } from "hooks";
import { api, translator } from "lib";

const ExportBirthdays = ({ isOpen, onClose }) => {
  const [isLoadingExportBirthDays, setIsLaodingExportBirthDays] = useState(false);
  const [period, setPeriod] = useState("day");
  const toast = useCustomToast();

  const handleExportBirthDays = useCallback(async () => {
    try {
      setIsLaodingExportBirthDays(true);
      const response = await api.post(`/private/customers/birthdays/${period}`);
      fileDownload(response, `aniversariantes_${translator(period).toLowerCase()}_${new Date().toISOString()}.txt`);
      onClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLaodingExportBirthDays(false);
    }
  }, [period, onClose]);

  return (
    <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Exportar aniversariantes</ModalHeader>
        <ModalBody>
          <FormControl>
            <Select value={period ?? ""} onChange={({ target }) => setPeriod(target.value)}>
              <option value="day">Aniversariantes do dia</option>
              <option value="week">Aniversariantes da semana</option>
              <option value="month">Aniversariantes do mês</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" isLoading={isLoadingExportBirthDays} onClick={handleExportBirthDays}>
            exportar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExportBirthdays;
