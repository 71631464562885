import React, { useCallback, useContext, useState, useEffect, useMemo, Fragment } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { currency, percent, locale, invoiceHelpers } from "lib";
import { InputCurrency } from "components";
import { messages } from "consts";
import * as yup from "yup";
import { MdAdd, MdClose, MdDelete, MdEdit, MdOutlineModeEdit } from "react-icons/md";
import InvoicesDetailsContext from "../context";
import { TbBolt } from "react-icons/tb";
import { Editable } from ".";

const PowerPrice = ({ item, onChange }) => {
  const { isEditable } = useContext(InvoicesDetailsContext);

  return (
    <Editable
      buttonProps={{ size: "xs" }}
      value={<Text>{currency(item.powerPricePerKwh, { precision: 8 })}</Text>}
      EditableComponent={
        <InputGroup size="xs">
          <InputLeftAddon>R$</InputLeftAddon>
          <Input as={InputCurrency} precision="8" value={item.powerPricePerKwh} onChange={onChange} />
        </InputGroup>
      }
      isEditable={isEditable}
    />
  );
};

const Description = ({ item, onChange }) => {
  const { isEditable } = useContext(InvoicesDetailsContext);

  return (
    <Editable
      buttonProps={{ size: "xs" }}
      value={<Text flex="1">{item.description}</Text>}
      EditableComponent={<Input size="sm" value={item.description} onChange={onChange} />}
      isEditable={isEditable}
    />
  );
};

const TraderEletricPower = ({ item, index, onRemove }) => {
  const { formData, setFormData } = useContext(InvoicesDetailsContext);
  const { isOpen: isOpenPowerQttAdjustments, onOpen: onOpenPowerQttAdjustments, onClose: onClosePowerQttAdjustments } = useDisclosure();
  const lastEletricPowerIndex = useMemo(() => _.findLastIndex(formData.items, (o) => o.type === "eletric_power"), [formData.items]);

  const handleChange = useCallback(
    (key, value) => {
      setFormData((state) => {
        const tmp = { ...state };
        tmp.items[index][key] = value;
        invoiceHelpers.reprocessEletricPower(tmp);
        invoiceHelpers.reprocessAmounts(tmp);
        return tmp;
      });
    },
    [index]
  );

  return (
    <Fragment>
      <Tr>
        <Td>
          <HStack>
            {formData.type === "manual" && (
              <Button size="xs" variant="outline" rightIcon={<Icon as={MdDelete} />} onClick={onRemove}>
                remover
              </Button>
            )}
            <Description {...{ item, index }} onChange={({ target }) => handleChange("description", target.value)} />
          </HStack>
        </Td>
        <Td textAlign="center">
          <HStack justifyContent="center" spacing={1} mb={1}>
            {item.basePowerQttInKwh !== item.powerQttInKwh && (
              <Text textDecoration="line-through">{(item.basePowerQttInKwh || 0).toLocaleString()} kWh</Text>
            )}
            <Text>{(item.powerQttInKwh || 0).toLocaleString()} kWh</Text>
            <IconButton size="xs" icon={<Icon as={MdOutlineModeEdit} />} onClick={onOpenPowerQttAdjustments} />
          </HStack>
        </Td>
        <Td textAlign="center">
          <PowerPrice {...{ item, index }} onChange={(powerPricePerKwh) => handleChange("powerPricePerKwh", powerPricePerKwh)} />
        </Td>
        <Td textAlign="right">
          <Text>{currency(item.amount)}</Text>
        </Td>
      </Tr>
      {index === lastEletricPowerIndex && (
        <Tr>
          <Td colSpan={2}>
            <Text fontWeight="bold" color="main.500">
              DESCONTO CLIENTE CLICKLIVRE
            </Text>
          </Td>
          <Td textAlign="center">
            <Text fontWeight="bold" whiteSpace="nowrap" color="main.500">
              -{percent(formData.discountPercentage, { precision: 0 })}
            </Text>
          </Td>
          <Td textAlign="right">
            <Text fontWeight="bold" whiteSpace="nowrap" color="main.500">
              -{currency(formData.economyAmount)}
            </Text>
          </Td>
        </Tr>
      )}
      <PowerQttAdjusments index={index} isOpen={isOpenPowerQttAdjustments} onClose={onClosePowerQttAdjustments} />
    </Fragment>
  );
};

const PowerQttAdjusments = ({ index, isOpen, onClose }) => {
  const { formData: parentFormData, setFormData: setParentFormData, isAnalyzing } = useContext(InvoicesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [operation, setOperation] = useState(1);

  useEffect(() => {
    setOperation(1);
    setFormData({ description: "", powerQttInKwh: 0 });
    setFormErrors({});
  }, [isOpen]);

  const handleAddItem = useCallback(
    (data) => {
      setParentFormData((state) => {
        const tmp = { ...state };
        if (tmp.items[index].powerQttAdjustments) tmp.items[index].powerQttAdjustments.push(data);
        else tmp.items[index].powerQttAdjustments = [data];
        invoiceHelpers.reprocessEletricPower(tmp);
        invoiceHelpers.reprocessAmounts(tmp);
        return tmp;
      });
    },
    [index]
  );

  const handleRemoveItem = useCallback(
    (_index) => {
      setParentFormData((state) => {
        const tmp = { ...state };
        tmp.items[index].powerQttAdjustments.splice(_index, 1);
        invoiceHelpers.reprocessEletricPower(tmp);
        invoiceHelpers.reprocessAmounts(tmp);
        return tmp;
      });
    },
    [index]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        description: yup.string().required(messages.error.required),
        powerQttInKwh: yup.number().required(messages.error.required),
      });
      const data = { ...formData, powerQttInKwh: Math.abs(formData.powerQttInKwh) * operation };
      await schema.validate(data, { abortEarly: false });
      handleAddItem(data);
      setFormData({});
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, operation, handleAddItem]);

  return (
    <>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text>Ajustes da injeção</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          {isAnalyzing && (
            <>
              <Divider />
              <ModalHeader>
                <HStack fontWeight="regular" alignItems="flex-start">
                  <FormControl>
                    <FormLabel fontSize="sm">Tipo</FormLabel>
                    <Select size="sm" value={operation.toString()} onChange={({ target }) => setOperation(parseInt(target.value))}>
                      <option value="1">Acréscimo</option>
                      <option value="-1">Decréscimo</option>
                    </Select>
                  </FormControl>
                  <FormControl isRequired={true} isInvalid={formErrors.description}>
                    <FormLabel fontSize="sm">Descrição</FormLabel>
                    <Input
                      size="sm"
                      value={formData.description ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.description}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired={true} isInvalid={formErrors.powerQttInKwh}>
                    <FormLabel fontSize="sm">Quantidade</FormLabel>
                    <InputGroup size="sm">
                      <Input
                        as={InputCurrency}
                        precision="0"
                        value={formData.powerQttInKwh ?? ""}
                        onChange={(powerQttInKwh) => setFormData((state) => ({ ...state, powerQttInKwh }))}
                      />
                      <InputRightAddon>kWh</InputRightAddon>
                    </InputGroup>
                    <FormErrorMessage>{formErrors.powerQttInKwh}</FormErrorMessage>
                  </FormControl>
                  <Box pt="1.4em">
                    <Button size="sm" colorScheme="main" onClick={handleSubmit}>
                      adicionar
                    </Button>
                  </Box>
                </HStack>
              </ModalHeader>
            </>
          )}
          <Divider />
          <ModalBody>
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th w="100%">Descrição</Th>
                  <Th textAlign="center">Quantidade</Th>
                  {isAnalyzing && <Th textAlign="center">#</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(parentFormData.items[index].powerQttAdjustments, (item, index) => (
                  <Tr key={index.toString()}>
                    <Td>{item.description}</Td>
                    <Td textAlign="center">{locale(item.powerQttInKwh, { precision: 0 })} kWh</Td>
                    {isAnalyzing && (
                      <Td textAlign="center">
                        <Button size="xs" variant="outline" rightIcon={<Icon as={MdDelete} />} onClick={() => handleRemoveItem(index)}>
                          remover
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {_.size(parentFormData.items[index].powerQttAdjustments) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={TbBolt} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum ajuste adicionado
                  </Text>
                  <Text fontSize="sm">Esta fatura ainda não ajustes de quantidade de injeção adicionados.</Text>
                </Box>
              </Center>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TraderEletricPower;
