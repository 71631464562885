import ObjectID from "bson-objectid";
import _ from "lodash";

const listeners = {};

export const addListener = (eventName, cb) => {
  const id = ObjectID().toHexString();
  _.set(listeners, [eventName, id], cb);
  return { remove: () => _.unset(listeners, [eventName, id]) };
};

export const emit = (eventName, value) => {
  for (const cb of Object.values(listeners[eventName] || {})) {
    if (cb instanceof Function) cb(value);
  }
};
