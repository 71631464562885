import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import ReactQrcode from "qrcode.react";
import { InputCurrency } from "components";
import { useClipboard } from "hooks";
import { useParams } from "react-router-dom";
import { FiCheck, FiClipboard } from "react-icons/fi";

const Qrcode = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [formData, setFormData] = useState({ qrcodeSize: 400 });
  const url = useMemo(() => `${process.env.REACT_APP_WEBSITE_BASE_URL}/quero-economizar?evento=${_id}`, [_id]);
  const copyToClipboard = useClipboard();

  useEffect(() => {
    const timeout = setTimeout(() => setIsCopied(false), [2000]);
    return () => clearTimeout(timeout);
  }, [isCopied]);

  const handleCopyToClipboard = useCallback(() => {
    copyToClipboard(url);
    setIsCopied(true);
  }, [url, copyToClipboard]);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>QrCode para cadastro de leads</ModalHeader>
        <ModalBody as={VStack} spacing="20px">
          <ReactQrcode value={url} size={formData.qrcodeSize} />
          <FormControl isRequired={true}>
            <FormLabel fontSize="sm">Tamanho do QrCode</FormLabel>
            <Input
              as={InputCurrency}
              precision="0"
              value={formData.qrcodeSize ?? ""}
              onChange={(qrcodeSize) => setFormData((state) => ({ ...state, qrcodeSize }))}
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel fontSize="sm">Link</FormLabel>
            <HStack>
              <Input value={url} isDisabled={true} />
              <Tooltip label="Copiar">
                <IconButton
                  colorScheme={isCopied ? "main" : "gray"}
                  icon={<Icon as={isCopied ? FiCheck : FiClipboard} />}
                  onClick={handleCopyToClipboard}
                />
              </Tooltip>
            </HStack>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Qrcode;
