import React, { useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import { Link as RouterLink, useParams } from "react-router-dom";
import { HStack, IconButton, StackDivider, Text, VStack, Icon, Box, Center, Spinner } from "@chakra-ui/react";
import { useFetchData, usePermissioned } from "hooks";
import { MdRefresh } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { FiUserCheck } from "react-icons/fi";
import CustomersDetailsContext from "../context";
import { PrivateContext } from "pages/Private";

const Indications = () => {
  const { _id } = useParams();
  const { currentUser } = useContext(PrivateContext);
  const { setCounters, setIsLoading } = useContext(CustomersDetailsContext);
  const [indications, isLoadingIndications, refreshIndications] = useFetchData(
    useMemo(
      () => ({
        path: `/private/customers/${_id}/indications`,
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );
  const isAllowedCustomersRead = usePermissioned("customers.read");

  useEffect(() => {
    setCounters((state) => ({ ...state, indications: indications?.size }));
  }, [indications?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, indications: isLoadingIndications }));
  }, [isLoadingIndications]);

  return (
    <>
      <HStack justifyContent="space-between">
        <Text fontSize="sm" fontWeight="semibold">
          {_.size(indications)} indicações
        </Text>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingIndications}
          onClick={refreshIndications}
        />
      </HStack>

      {isLoadingIndications ? (
        <Center p="50px">
          <Spinner />
        </Center>
      ) : (
        _.size(indications) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={FiUserCheck} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma indicação
              </Text>
              <Text fontSize="sm">Este cliente ainda não realizou indicações.</Text>
            </Box>
          </Center>
        )
      )}

      <VStack align="stretch" divider={<StackDivider />}>
        {_.map(indications, (item) => (
          <HStack key={item._id} py="20px">
            <Box flex="1">
              <Text fontWeight="semibold">{item.name}</Text>
              <Text fontSize="sm">{item.email}</Text>
            </Box>
            <IconButton
              as={RouterLink}
              to={`/customers/edit/${item._id}`}
              target="_blank"
              size="sm"
              variant="outline"
              icon={<Icon as={TbExternalLink} />}
              isDisabled={!isAllowedCustomersRead && item.createdBy?._id !== currentUser._id}
            />
          </HStack>
        ))}
      </VStack>
    </>
  );
};

export default Indications;
