import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Box, HStack, Icon, IconButton, useDisclosure, VStack, Center, Heading, Text, StackDivider, Link } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { MdErrorOutline, MdKeyboardArrowDown, MdKeyboardArrowUp, MdRefresh } from "react-icons/md";

const InReview = ({ statuses }) => {
  const [data, isLoadingData, refreshData] = useFetchData(
    useMemo(
      () => ({
        path: "/private/invoices",
        params: { query: { invoice: { status: { $in: statuses }, "distributor.waiting": { $exists: true } } } },
        options: { isEnabled: true },
      }),
      [statuses]
    )
  );
  const { isOpen: isOpenContainer, onToggle: onToggleContainer } = useDisclosure();
  const [colorScheme, message] = useMemo(() => {
    if (_.isEqual(["validated", "invalidated"], statuses))
      return ["yellow", "Existem faturas pendentes de revisão por terem sido alteradas após a análise do operador."];
    return ["red", "Existem faturas pendentes de revisão por terem sido alteradas após a emissão de cobrança."];
  }, [statuses]);

  return (
    data?.size >= 1 && (
      <Box _light={{ bg: colorScheme.concat(".100") }} _dark={{ bg: "gray.900" }} p="20px" borderRadius="lg" my={4}>
        <HStack>
          <Center w="30px" h="30px" borderRadius="full" bg={colorScheme.concat(".500")}>
            <Icon as={MdErrorOutline} color="white" />
          </Center>
          <Box flex="1">
            <Heading size="xs">Faturas em aviso ({data.size})</Heading>
            <Text fontSize="xs">{message}</Text>
          </Box>
          <IconButton
            size="xs"
            variant="outline"
            colorScheme={colorScheme}
            icon={<Icon as={isOpenContainer ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
            onClick={onToggleContainer}
          />
          <IconButton
            size="xs"
            variant="outline"
            colorScheme={colorScheme}
            icon={<Icon as={MdRefresh} />}
            isLoading={isLoadingData}
            onClick={refreshData}
          />
        </HStack>
        {isOpenContainer && (
          <VStack alignItems="stretch" divider={<StackDivider borderColor="blackAlpha.100" />} spacing={4} mt={4}>
            <Box borderLeft="1px" borderLeftColor="blackAlpha.200" ml="12px" mt="4px" pl="20px">
              {_.map(data.data, (item) => (
                <Text key={item._id} fontSize="x-small">
                  Fatura{" "}
                  <Link as={RouterLink} to={`/invoices/details/${item._id}`} fontSize="x-small" fontWeight="semibold">
                    #{item.nid}
                  </Link>
                  . Aguardando revisão desde {moment(item.distributor?.waiting?.createdAt).format("DD/MM/YYYY")} às{" "}
                  {moment(item.distributor?.waiting?.createdAt).format("HH:mm")}.
                </Text>
              ))}
            </Box>
          </VStack>
        )}
      </Box>
    )
  );
};

export default InReview;
