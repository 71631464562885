import React, { useCallback, useContext, useMemo, useRef } from "react";
import _ from "lodash";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useConfirmDialog } from "hooks";
import { FiChevronDown, FiChevronUp, FiPlus, FiTrash } from "react-icons/fi";
import StatusesDetailsContext from "./context";
import EmailVariables from "./emailVariables";

const Email = ({ title, path }) => {
  const { formData, setFormData, formErrors } = useContext(StatusesDetailsContext);
  const isActive = useMemo(() => _.isObject(formData[path]), [formData[path]]);
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { confirmAction, ConfirmDialog } = useConfirmDialog();
  const subjectRef = useRef();
  const bodyRef = useRef();

  const handleToggleEmail = useCallback(async () => {
    if (isActive) {
      const confirm = await confirmAction();
      if (confirm) setFormData((state) => ({ ...state, [path]: null }));
      onClose();
    } else {
      setFormData((state) => ({ ...state, [path]: {} }));
      onOpen();
    }
  }, [path, isActive]);

  return (
    <VStack p="20px" alignItems="stretch" borderWidth="1px" borderRadius="lg">
      <HStack>
        <Box flex="1">
          <Heading size="sm">{title}</Heading>
          <Text fontSize="xs">Informações para o envio de mensagem via e-mail.</Text>
        </Box>
        {isActive && <IconButton variant="outline" icon={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />} onClick={onToggle} />}
        <Tooltip
          placement="left"
          label={isActive ? `Remover informações de envio do ${title}.` : `Adicionar informações de envio do ${title}.`}
        >
          <IconButton variant="outline" icon={<Icon as={isActive ? FiTrash : FiPlus} />} onClick={handleToggleEmail} />
        </Tooltip>
      </HStack>

      {isActive && isOpen && (
        <Grid templateColumns="repeat(12, 1fr)" mt={4} gap={4}>
          <GridItem colSpan={12}>
            <FormControl isRequired={true} isInvalid={formErrors[path]?.subject}>
              <HStack mb="5px">
                <FormLabel flex="1" fontSize="sm" mb="0">
                  Assunto do e-mail
                </FormLabel>
                <EmailVariables
                  inputRef={subjectRef}
                  text={formData[path]?.subject}
                  onChangeText={(subject) => setFormData((state) => ({ ...state, [path]: { ...state[path], subject } }))}
                />
              </HStack>
              <Input
                ref={subjectRef}
                value={formData[path]?.subject ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, [path]: { ...state[path], subject: target.value } }))}
              />
              <FormErrorMessage>{formErrors[path]?.subject}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={12}>
            <FormControl isRequired={true} isInvalid={formErrors[path]?.body}>
              <HStack mb="5px">
                <FormLabel flex="1" fontSize="sm" mb="0">
                  Corpo da mensagem
                </FormLabel>
                <EmailVariables
                  inputRef={bodyRef}
                  text={formData[path]?.body}
                  onChangeText={(body) => setFormData((state) => ({ ...state, [path]: { ...state[path], body } }))}
                />
              </HStack>
              <Textarea
                h="200px"
                ref={bodyRef}
                value={formData[path]?.body ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, [path]: { ...state[path], body: target.value } }))}
              />
              <FormErrorMessage>{formErrors[path]?.body}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      )}

      <ConfirmDialog
        title="Atenção"
        description="Deseja realmente remover as informações de envio do E-mail?"
        buttons={[
          { value: false, text: "cancelar" },
          { value: true, text: "remover", colorScheme: "red" },
        ]}
      />
    </VStack>
  );
};

export default Email;
