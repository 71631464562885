import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "consts";
import moment from "moment";
import "moment/locale/pt-br";
import { initializeApp } from "firebase/app";
import AppProvider from "./AppProvider";

moment.locale("pt-br");

initializeApp({
  apiKey: "AIzaSyBmpb2ch9qfLeNEOoGCD-Dp77pwZqkFjFI",
  authDomain: "click-livre.firebaseapp.com",
  projectId: "click-livre",
  storageBucket: "click-livre.appspot.com",
  messagingSenderId: "2000860197",
  appId: "1:2000860197:web:abbb38dfdbc0f2117c7bcc",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AppProvider />
  </ChakraProvider>
);
