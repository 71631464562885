import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { api } from "lib";
import { Breadcrumb, BoxData, StatusBadge } from "components";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { MdChevronLeft, MdOutlineDownload } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const ConsumerUnitsPowerPlantsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle("Detalhamento da importação das usinas das UCs");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData] = useFetchData(useMemo(() => ({ path: `/private/imports/consumer-units-power-plants/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? {};
    setFormData(formData);
  }, [data]);

  const handleDownloadData = useCallback(async () => {
    try {
      setIsDownloading(true);
      const data = await api({
        method: "post",
        url: `/private/files/${formData.file._id}/object`,
        responseType: "blob",
      });
      fileDownload(data, `${formData.file.title}.${formData.file.ext}`);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  }, [formData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button
              size="sm"
              variant="outline"
              leftIcon={<Icon as={MdChevronLeft} />}
              onClick={() => navigate("/settings/imports/consumer-units-power-plants")}
            >
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#imports", label: "importações" },
                { to: "/settings/imports/consumer-units-power-plants", label: "importação das usinas das ucs" },
                { to: location.pathname, label: "detalhamento" },
              ]}
            />
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Detalhamento da importação das usinas das UCs</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id}</Text>
          </Box>
          {formData.file && (
            <Button
              size="sm"
              variant="outline"
              rightIcon={<Icon as={MdOutlineDownload} />}
              isLoading={isDownloading}
              onClick={handleDownloadData}
            >
              baixar arquivo
            </Button>
          )}
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={{ base: 12, lg: 2 }}>
            <BoxData label="NID" value={formData.nid ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 10 }}>
            <BoxData label="Título" value={formData.title ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <BoxData label="Criado por" value={formData.createdBy?.name ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <BoxData label="Criado em" value={formData.createdAt ? moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm") : "-"} />
          </GridItem>
        </Grid>

        <Divider my={8} />

        <Heading size="md" mb={4}>
          Unidades consumidoras ({_.size(formData.consumerUnits)})
        </Heading>

        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              <Th>N° de instalação</Th>
              <Th>Usina</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(formData.consumerUnits, (item) => (
              <Tr key={item.installationNumber}>
                <Td>{item.installationNumber}</Td>
                <Td>{item.powerPlant || "-"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Content>

      <Divider />
      <SlideFade in={true} offsetY="20px">
        <HStack p="20px">
          <Button size="sm" variant="ghost" onClick={() => navigate("/invoice-captures")}>
            voltar
          </Button>
        </HStack>
      </SlideFade>
    </>
  );
};
