import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { Box, Center, Divider, HStack, Icon, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { useClipboard } from "hooks";
import { FiClipboard } from "react-icons/fi";
import ReactJson from "react-json-view";
import { MdError } from "react-icons/md";

export const ErrViewer = ({ err }) => {
  const copyToClipboard = useClipboard();

  return (
    <Box bg="red.100" borderRadius="lg">
      <HStack p="10px">
        <Center bg="red.500" w="30px" h="30px" borderRadius="full">
          <Icon as={MdError} color="white" />
        </Center>
        <Box flex="1">
          <Text fontSize="xs" fontWeight="semibold">
            Problemas detectados
          </Text>
          <Text fontSize="xs">{err.message}</Text>
          {err.createdAt && <Text fontSize="x-small">Criado em {moment(err.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>}
        </Box>
        {_.isObject(err.data) && (
          <Tooltip label="Copiar log de erro" placement="left">
            <IconButton
              size="xs"
              colorScheme="red"
              variant="ghost"
              icon={<Icon as={FiClipboard} />}
              onClick={() => copyToClipboard(JSON.stringify(err.data, null, 2))}
            />
          </Tooltip>
        )}
      </HStack>
      {_.isObject(err.data) && (
        <Fragment>
          <Divider />
          <Box p="10px">
            <ReactJson src={err.data} name={false} collapsed={false} />
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
