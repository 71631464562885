import React, { memo } from "react";
import { Box, FormLabel, Heading, HStack } from "@chakra-ui/react";

export const BoxData = memo(({ label, value, _light, _dark, RightComponent, ...props }) => {
  return (
    <HStack
      h="69px"
      px="15px"
      borderRadius="lg"
      _light={{ bg: "gray.50", ..._light }}
      _dark={{ bg: "whiteAlpha.100", ..._dark }}
      {...props}
    >
      <Box flex="1" overflow="hidden">
        <FormLabel fontSize="sm" fontWeight="normal" mb={0}>
          {label}
        </FormLabel>
        <Heading size="sm">{value}</Heading>
      </Box>
      {RightComponent}
    </HStack>
  );
});
