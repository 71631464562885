import axios from "axios";
import _ from "lodash";
import { getAuth, getIdToken } from "@firebase/auth";
import * as EventEmitter from "./eventEmitter";

const errorHandler = {
  INVALID_AUTHORIZATION: (error) => EventEmitter.emit("onAuthorizationError", error),
  PERMISSION_DENIED: (error) => EventEmitter.emit("onPermissionDenied", error),
  APP_VERSION: (error) => EventEmitter.emit("onAppVersionError", error),
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 180000,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const auth = getAuth();
      const token = await getIdToken(auth.currentUser);
      config.headers.Authorization = token;
      config.headers.appversion = process.env.REACT_APP_VERSION;
      config.headers.platform = "dash";
      return config;
    } catch (error) {
      return config;
    }
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    if (error.code === "ERR_NETWORK") EventEmitter.emit("onNetworkError", error);
    else errorHandler[error?.response?.data?.type]?.(error?.response?.data);
    return Promise.reject(error?.response?.data ?? error.message);
  }
);

api.upload = (path, files = [], data = {}) => {
  const formData = new FormData();
  for (const file of files) formData.append("files", file, file.name);
  formData.append("data", JSON.stringify(data));
  return api.put(path, formData);
};

api.fetchAllPages = async (path, params = {}) => {
  const perPage = 100000;
  const fetchData = (page) => api.post(path, { search: params.search, query: params.query, sort: params.sort, page, perPage });
  let { data, size } = await fetchData(0);
  const pages = Math.ceil(size / Math.abs(perPage));
  for (let currentPage = 1; currentPage < pages; currentPage++) {
    const response = await fetchData(currentPage);
    data = _.concat(data, response.data);
  }
  return data;
};
