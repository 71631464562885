import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { Breadcrumb, EmailAutocomplete, InputCurrency, PermissionedContainer, PhoneInput } from "components";
import { DocumentHistory } from "containers";
import { api, yup } from "lib";
import { useFetchData, useArrayItemHandlers, useCustomToast, useDocumentTitle } from "hooks";
import { messages } from "consts";
import { MdChevronLeft, MdOutlineDelete, MdAddCircleOutline, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";

export const PowerPlantsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar usina" : "Nova usina");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/power-plants/${_id}` }), [_id]));
  const [states, isLoadingStates] = useFetchData(useMemo(() => ({ path: "/common/states" }), []));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isPrimary: false, isActive: true };
    if (_.size(formData.contacts) === 0) formData.contacts = [{ phoneCategory: "whatsapp" }];
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/power-plants/${_id}`, data) : await api.put("/private/power-plants", data);
        navigate(`/power-plants/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const contactsSchema = yup.object().shape({
        email: yup.string().email(messages.error.invalidEmail),
      });
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        document: yup.string().required(messages.error.required),
        phone: yup.string().isValidPhoneNumber(),
        cemigInstallationNumber: yup.string().isValidCemigNumber(3).required(messages.error.required),
        productionInKwhByMonth: yup.number().required(messages.error.required),
        powerInKw: yup.number().required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          number: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required),
          city: yup.string().required(messages.error.required),
          state: yup.string().required(messages.error.required),
        }),
        contacts: yup.array().of(contactsSchema),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleZipCodeChange = useCallback(async ({ target }) => {
    try {
      const zipCode = target.value.replace(/\D/g, "");
      setFormData((state) => ({ ...state, address: { ...state.address, zipCode } }));
      if (zipCode.length !== 8) return;
      setIsLoadingZipCode(true);
      const address = await api.post(`/common/locales/${zipCode}`);
      setFormData((state) => ({ ...state, address: { ...state.address, ...address } }));
    } finally {
      setIsLoadingZipCode(false);
    }
  }, []);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/power-plants")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/power-plants", label: "usinas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Usina</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Principal?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isPrimary}
                onChange={() => setFormData((state) => ({ ...state, isPrimary: !state.isPrimary }))}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors.nid}>
              <FormLabel fontSize="sm">NID</FormLabel>
              <Input value={formData.nid ?? ""} isDisabled={true} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={10}>
            <FormControl isRequired={true} isInvalid={formErrors.name}>
              <FormLabel fontSize="sm">Nome</FormLabel>
              <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isRequired={true} isInvalid={formErrors.document}>
              <FormLabel fontSize="sm">CNPJ</FormLabel>
              <Input
                as={InputMask}
                mask="99.999.999/9999-99"
                value={formData.document ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              />
              <FormErrorMessage>{formErrors.document}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl isRequired={true} isInvalid={formErrors.cemigInstallationNumber}>
              <FormLabel fontSize="sm">Número de instalação na CEMIG</FormLabel>
              <Input
                as={InputMask}
                mask="9999999999"
                value={formData.cemigInstallationNumber ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, cemigInstallationNumber: target.value }))}
              />
              <FormErrorMessage>{formErrors.cemigInstallationNumber}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl isRequired={true} isInvalid={formErrors.productionInKwhByMonth}>
              <FormLabel fontSize="sm">Geração mensal</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="0"
                  value={formData.productionInKwhByMonth ?? ""}
                  onChange={(productionInKwhByMonth) => setFormData((state) => ({ ...state, productionInKwhByMonth }))}
                />
                <InputRightElement fontSize="xs">kWh</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.productionInKwhByMonth}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl isRequired={true} isInvalid={formErrors.powerInKw}>
              <FormLabel fontSize="sm">Potência</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.powerInKw ?? ""}
                  onChange={(powerInKw) => setFormData((state) => ({ ...state, powerInKw }))}
                />
                <InputRightElement fontSize="xs">kW</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.powerInKw}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider />
        <Heading size="sm" mt={8} mb={6}>
          Endereço
        </Heading>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={4}>
          <GridItem>
            <FormControl isRequired={true} isInvalid={formErrors["address.zipCode"]}>
              <FormLabel fontSize="sm">CEP</FormLabel>
              <InputGroup>
                <Input as={InputMask} mask="99999-999" value={formData.address?.zipCode ?? ""} onChange={handleZipCodeChange} />
                {isLoadingZipCode && (
                  <InputRightElement>
                    <Spinner size="sm" />
                  </InputRightElement>
                )}
              </InputGroup>
              <FormErrorMessage>{formErrors["address.zipCode"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isRequired={true} isInvalid={formErrors["address.city"]}>
              <FormLabel fontSize="sm">Município</FormLabel>
              <Input
                value={formData.address?.city ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, city: target.value } }))}
              />
              <FormErrorMessage>{formErrors["address.city"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired={true} isInvalid={formErrors["address.state"]}>
              <FormLabel fontSize="sm">Estado</FormLabel>
              <HStack>
                <Select
                  flex="1"
                  value={formData.address?.state ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, state: target.value } }))}
                >
                  <option value="">--Selecione</option>
                  {_.map(states, (state) => (
                    <option key={state.initials} value={state.initials}>
                      {state.name}
                    </option>
                  ))}
                </Select>
                {isLoadingStates && <Spinner />}
              </HStack>
              <FormErrorMessage>{formErrors["address.state"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors["address.street"]}>
              <FormLabel fontSize="sm">Rua</FormLabel>
              <Input
                value={formData.address?.street ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, street: target.value } }))}
              />
              <FormErrorMessage>{formErrors["address.street"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={8}>
          <GridItem>
            <FormControl isRequired={true} isInvalid={formErrors["address.neighborhood"]}>
              <FormLabel fontSize="sm">Bairro</FormLabel>
              <Input
                value={formData.address?.neighborhood ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, neighborhood: target.value } }))}
              />
              <FormErrorMessage>{formErrors["address.neighborhood"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired={true} isInvalid={formErrors["address.number"]}>
              <FormLabel fontSize="sm">Número</FormLabel>
              <Input
                value={formData.address?.number ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, number: target.value } }))}
              />
              <FormErrorMessage>{formErrors["address.number"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors["address.complement"]}>
              <FormLabel fontSize="sm">Complemento</FormLabel>
              <Input
                value={formData.address?.complement ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, address: { ...state.address, complement: target.value } }))}
              />
              <FormErrorMessage>{formErrors["address.complement"]}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider />
        <Heading size="sm" mt={8} mb={6}>
          Pessoas de contato
        </Heading>
        {_.map(formData.contacts, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={4}>
              <FormControl isInvalid={formErrors[`contacts[${index}].name`]}>
                <FormLabel fontSize="sm">Nome</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].name`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={formErrors[`contacts[${index}].office`]}>
                <FormLabel fontSize="sm">Cargo</FormLabel>
                <Input
                  value={contact.office ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { office: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].office`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isInvalid={formErrors[`contacts[${index}].email`]}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <EmailAutocomplete
                  value={contact.email ?? ""}
                  onChange={(email) => handleChangeArrayItem("contacts", index, { email })}
                  isInvalid={formErrors[`contacts[${index}].email`]}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].email`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl isRequired={true} isInvalid={formErrors[`contacts[${index}].phone`]}>
                <FormLabel fontSize="sm">Telefone</FormLabel>
                <PhoneInput
                  phone={contact.phone}
                  category={contact.phoneCategory}
                  onChangePhone={(phone) => handleChangeArrayItem("contacts", index, { phone })}
                  onChangeCategory={(phoneCategory) => handleChangeArrayItem("contacts", index, { phoneCategory })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].phone`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={formErrors[`contacts[${index}].branch`]}>
                <FormLabel fontSize="sm">Ramal</FormLabel>
                <Input
                  value={contact.branch ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("contacts", index, { branch: target.value })}
                />
                <FormErrorMessage>{formErrors[`contacts[${index}].branch`]}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("contacts", index)}
              >
                excluir
              </Button>
            </GridItem>
          </Grid>
        ))}
        <Button
          size="sm"
          colorScheme="main"
          variant="ghost"
          mb={8}
          leftIcon={<Icon as={MdAddCircleOutline} />}
          onClick={() => handleAddArrayItem("contacts", { phoneCategory: "whatsapp" })}
        >
          adicionar contato
        </Button>
      </Content>

      <PermissionedContainer required={"powerPlants.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/power-plants")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/private/power-plants/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </>
  );
};
