import React, { Fragment, useCallback, useContext, useRef, useState } from "react";
import _ from "lodash";
import { Box, Button, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, VisuallyHidden, VStack } from "@chakra-ui/react";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { PermissionedContainer } from "components";
import { TbFileZip } from "react-icons/tb";
import InvoiceCapturesContext from "./context";

const Upload = () => {
  const { refreshData } = useContext(InvoiceCapturesContext);
  const attachFormRef = useRef();
  const attachInputRef = useRef();
  const toast = useCustomToast();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  const handleSaveData = useCallback(
    async ({ target }) => {
      try {
        setIsLoadingSaveData(true);
        await api.upload("/private/invoice-captures", target.files);
        refreshData();
      } catch (error) {
        toast({ description: error.message, data: error.data, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
        attachFormRef.current.reset();
      }
    },
    [attachFormRef.current]
  );

  return (
    <Fragment>
      <PermissionedContainer required="invoiceCaptures.create">
        <Button
          size="sm"
          colorScheme="main"
          leftIcon={<Icon as={TbFileZip} />}
          onClick={() => attachInputRef.current.click()}
          isLoading={isLoadingSaveData}
        >
          incluir captura de faturas
        </Button>
      </PermissionedContainer>
      <VisuallyHidden>
        <form ref={attachFormRef}>
          <input ref={attachInputRef} type="file" accept=".zip" multiple={true} onChange={handleSaveData} />
        </form>
      </VisuallyHidden>
      <Modal isOpen={isLoadingSaveData} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody as={VStack} spacing={6} p="40px">
            <Spinner size="lg" />
            <Box textAlign="center">
              <Text fontWeight="semibold">Upload em andamento</Text>
              <Text fontSize="xs">
                O upload dos arquivos de importação está em endamento. Por favor, aguarde até que o processo seja finalizado.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Upload;
