import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Breadcrumb, InputCurrency, PermissionedContainer } from "components";
import { DocumentHistory } from "containers";
import { useFetchData, useCustomToast, useDocumentTitle } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";
import InputMask from "react-input-mask";
import moment from "moment";

export const PromotionsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar promoção" : "Nova promoção");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useFetchData(useMemo(() => ({ path: `/private/promotions/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { isActive: true };
    if (formData.finishesAt) formData.finishesAt = moment(formData.finishesAt).format("DD/MM/YYYY");
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.patch(`/private/promotions/${_id}`, data) : await api.put("/private/promotions", data);
        navigate(`/promotions/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        discountPercentage: yup.number().moreThan(0, messages.error.moreThan.concat(" 0.")).required(messages.error.required),
        discountDurationInMonths: yup.number().typeError(messages.error.required).required(messages.error.required),
        finishesAt: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      });
      const data = { ...formData, finishesAt: moment(formData.finishesAt, "DD/MM/YYYY").endOf("day").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <Content>
        <HStack justify="space-between">
          <HStack>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate("/promotions")}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/promotions", label: "promoções" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Promoção</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={formErrors.nid}>
              <FormLabel fontSize="sm">NID</FormLabel>
              <Input value={formData.nid ?? ""} isDisabled={true} />
              <FormErrorMessage>{formErrors.nid}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={10}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.discountPercentage}>
              <FormLabel fontSize="sm">Desconto</FormLabel>
              <InputGroup>
                <Input
                  as={InputCurrency}
                  precision="2"
                  value={formData.discountPercentage ?? ""}
                  onChange={(discountPercentage) => setFormData((state) => ({ ...state, discountPercentage }))}
                  isPercentage={true}
                />
                <InputRightElement fontSize="xs">%</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formErrors.discountPercentage}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.discountDurationInMonths}>
              <FormLabel fontSize="sm">Duração desconto</FormLabel>
              <Select
                value={formData.discountDurationInMonths ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, discountDurationInMonths: parseInt(target.value) || "" }))}
              >
                <option>--Selecione</option>
                {_.range(1, 31, 1).map((month) => (
                  <option key={month} value={month}>
                    {month} {month === 1 ? "mês" : "meses"}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formErrors.discountDurationInMonths}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isRequired={true} isInvalid={formErrors.finishesAt}>
              <FormLabel fontSize="sm">Finaliza em</FormLabel>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.finishesAt ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, finishesAt: target.value }))}
              />
              <FormErrorMessage>{formErrors.finishesAt}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Content>

      <PermissionedContainer required={"promotions.".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate("/promotions")}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/private/promotions/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </>
  );
};
