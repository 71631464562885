export const personTypes = {
  pf: {
    name: "Nome",
    nickname: "Nome social",
    document: "CPF",
    documentMask: "999.999.999-99",
  },
  pj: {
    name: "Razão social",
    nickname: "Nome fantasia",
    document: "CNPJ",
    documentMask: "99.999.999/9999-99",
  },
};
