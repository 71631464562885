import React, { useState, useMemo, useCallback, useContext, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { useCustomToast } from "hooks";
import { api, yup } from "lib";
import { messages } from "consts";
import InvoicesDetailsContext from "./context";
import { RiAuctionFill } from "react-icons/ri";
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineNumber } from "react-icons/ai";

const ProtestOrSpcRegister = ({ includeText, excludeText, field, protocolField }) => {
  const { _id } = useParams();
  const { formData: parentFormData, refreshData } = useContext(InvoicesDetailsContext);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isActive = useMemo(() => parentFormData[field], [parentFormData[field]]);
  const text = useMemo(() => (isActive ? excludeText : includeText), [isActive, excludeText, includeText]);
  const toast = useCustomToast();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData({});
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/invoices/${_id}/protest-or-spc`, data);
        refreshData();
        onClose();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        protocol: yup.string().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      const data = (() => {
        if (isActive) return { [field]: false, [protocolField.concat("ExitProtocol")]: formData.protocol };
        return { [field]: true, [protocolField.concat("EntryProtocol")]: formData.protocol };
      })();
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [field, protocolField, formData, isActive, handleSaveData]);

  return (
    <Fragment>
      <PermissionedContainer required="invoices.sendToCollectionAgency">
        <VStack alignItems="stretch">
          <Button
            size="sm"
            colorScheme="purple"
            variant={isActive ? "outline" : "solid"}
            leftIcon={<Icon as={field === "isProtested" ? RiAuctionFill : FiAlertTriangle} />}
            onClick={onOpen}
          >
            {text}
          </Button>

          <Popover placement="bottom-end">
            <PopoverTrigger>
              <Button
                size="xs"
                leftIcon={<Icon as={AiOutlineNumber} />}
                isDisabled={
                  !(parentFormData[protocolField.concat("EntryProtocol")] || parentFormData[protocolField.concat("ExitProtocol")])
                }
              >
                Protocolos
              </Button>
            </PopoverTrigger>
            <PopoverContent textAlign="left">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Protocolos</PopoverHeader>
              <PopoverBody as={VStack} alignItems="stretch">
                <HStack>
                  <Text fontSize="sm">Protocolo de entrada</Text>
                  <Divider flex="1" />
                  <Text fontSize="sm" fontWeight="semibold">
                    {parentFormData[protocolField.concat("EntryProtocol")]}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize="sm">Protocolo de saída</Text>
                  <Divider flex="1" />
                  <Text fontSize="sm" fontWeight="semibold">
                    {parentFormData[protocolField.concat("ExitProtocol")]}
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>
      </PermissionedContainer>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{text}</AlertDialogHeader>
          <AlertDialogBody>
            <Text mb={4}>Deseja realmente aplicar a ação nesta fatura?</Text>
            <FormControl isRequired={true} isInvalid={formErrors.protocol}>
              <FormLabel fontSize="sm" mb="5px">
                Protocolo de {isActive ? "saída" : "entrada"}
              </FormLabel>
              <Input
                value={formData.protocol || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, protocol: target.value }))}
              />
              <FormErrorMessage>{formErrors.protocol}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="purple" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default ProtestOrSpcRegister;
