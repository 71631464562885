import React, { Fragment, useContext } from "react";
import _ from "lodash";
import XMLViewer from "react-xml-viewer";
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { TbFileTypeXml } from "react-icons/tb";
import InvoicesDetailsContext from "../context";

const DistributorXmlContent = () => {
  const { formData } = useContext(InvoicesDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (_.size(formData.distributor.current.xmlContent) === 0) return null;

  return (
    <Fragment>
      <Button size="sm" variant="outline" leftIcon={<Icon as={TbFileTypeXml} />} onClick={onOpen}>
        visualizar xml
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered={true} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Text fontSize="sm">{formData.distributor.current.xmlFileName}</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <XMLViewer xml={formData.distributor.current.xmlContent} />
          </ModalBody>
          <Divider />
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default DistributorXmlContent;
