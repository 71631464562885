import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Center,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { api } from "lib";
import { useCustomToast } from "hooks";
import { MdAdd, MdDragHandle, MdEdit, MdClose, MdVisibility } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import StatusesListContext from "./context";
import { List, ListContext } from "./list";

const Header = ({ item }) => {
  const { isOuter } = useContext(ListContext);
  const { refreshStatuses, setSelected } = useContext(StatusesListContext);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);

  const toast = useCustomToast();

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/private/statuses", { data: [item._id] });
      refreshStatuses();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [onCloseDeleteDialog, toast, refreshStatuses]);

  return (
    <Fragment>
      <HStack flex="1">
        <Center
          py="10px"
          px="10px"
          bg={item.colorScheme.concat(".100")}
          color={item.colorScheme.concat(".500")}
          borderRadius="xl"
          fontSize="xs"
          fontWeight="semibold"
        >
          {item.ordination}
        </Center>
        <Box flex="1">
          <Text fontSize="xs" fontWeight="semibold">
            {item.title}
          </Text>
          {isOuter && (
            <Text fontSize="xs" fontWeight="light">
              {_.size(item.items)} substatus
            </Text>
          )}
        </Box>
        {isOuter && (
          <Button
            size="xs"
            variant="outline"
            leftIcon={<Icon as={MdAdd} />}
            onClick={() => setSelected({ parent: item._id, isActive: true })}
          >
            incluir substatus
          </Button>
        )}
        <Button size="xs" variant="outline" leftIcon={<Icon as={MdEdit} />} onClick={() => setSelected(item)}>
          editar
        </Button>
        <IconButton size="xs" variant="outline" icon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog} />
      </HStack>

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

const ListItem = ({ provided, item }) => {
  const { isOuter, isOpen, setIsOpen, isLoadingDragEnd } = useContext(ListContext);

  return (
    <Fragment>
      <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <HStack
          p="10px"
          borderWidth="1px"
          borderRadius="md"
          borderBottomRadius={isOpen[item._id] && "none"}
          _light={{ bg: "white" }}
          _dark={{ bg: "gray.800" }}
          _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}
          _active={{ opacity: 0.6 }}
          onDoubleClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
        >
          {isOuter ? (
            <IconButton
              size="xs"
              icon={<Icon as={MdVisibility} />}
              isLoading={isLoadingDragEnd}
              onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
            />
          ) : (
            <IconButton size="xs" variant="ghost" icon={<Icon as={MdDragHandle} />} isLoading={isLoadingDragEnd} />
          )}
          <Header {...{ item }} />
        </HStack>
      </Box>
      {isOuter && (
        <Modal
          size="2xl"
          scrollBehavior="inside"
          isCentered
          blockScrollOnMount={false}
          isOpen={isOpen[item._id]}
          onClose={() => setIsOpen({})}
        >
          <ModalOverlay zIndex="1300" />
          <ModalContent zIndex="1301">
            <ModalHeader as={HStack} justifyContent="space-between">
              <Header {...{ item }} />
              <IconButton size="xs" variant="outline" icon={<Icon as={MdClose} />} onClick={() => setIsOpen({})} />
            </ModalHeader>
            <Divider />
            <ModalBody p="10px">
              <List type="INNER" droppableId={item._id} items={item.items} />
            </ModalBody>
            <Divider />
            <ModalFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={() => setIsOpen({})}>
                fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Fragment>
  );
};

export default ListItem;
