import React, { useMemo } from "react";
import _ from "lodash";
import {
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { phoneCategories } from "consts";
import { Portal } from "components";
import { MdRemove } from "react-icons/md";

export const PhoneInput = ({ phone, category, onChangePhone, onChangeCategory, isDisabled }) => {
  const [icon, mask] = useMemo(() => {
    const phoneCategory = phoneCategories.find((o) => o.value === category);
    if (phoneCategory) return [phoneCategory.icon, phoneCategory.mask(phone)];
    return [];
  }, [phone, category]);

  return (
    <InputGroup>
      <InputLeftElement>
        <Menu closeOnSelect={false}>
          <MenuButton as={IconButton} size="sm" variant="outline" icon={<Icon as={icon ?? MdRemove} />} isDisabled={isDisabled} />
          <Portal>
            <MenuList minWidth="240px" zIndex="9999">
              <MenuOptionGroup title="Categoria do telefone" type="radio" value={category} onChange={onChangeCategory}>
                {phoneCategories.map(({ icon, label, value }) => (
                  <MenuItemOption key={value} value={value}>
                    <HStack justifyContent="space-between">
                      <Text fontSize="xs">{label}</Text>
                      <Icon as={icon} boxSize="15px" />
                    </HStack>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </InputLeftElement>
      <Input
        as={InputMask}
        mask={mask}
        value={phone ?? ""}
        onChange={({ target }) => onChangePhone(target.value)}
        isDisabled={isDisabled}
      />
    </InputGroup>
  );
};
