import { memo, useMemo } from "react";
import { Badge } from "@chakra-ui/react";
import { translator } from "lib";
import { statuses } from "consts";

export const Status = memo(({ status, ...props }) => {
  const colorScheme = useMemo(() => {
    try {
      const finded = statuses.invoiceCaptures.find((o) => o.value === status);
      const [colorScheme] = finded.color.split(".");
      return colorScheme;
    } catch (error) {
      return "gray";
    }
  }, [status]);

  return (
    <Badge colorScheme={colorScheme} {...props}>
      {translator(status)}
    </Badge>
  );
});
