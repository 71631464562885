import React, { Fragment, useMemo } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { useFetchData } from "hooks";
import { MdClose, MdOnlinePrediction, MdRefresh, MdVisibility, MdWarning } from "react-icons/md";
import moment from "moment";
import { locale, percent } from "lib";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaAnchor } from "react-icons/fa";

const PredictedBalances = ({ consumerUnit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, isLoadingData, refreshData] = useFetchData(
    useMemo(() => ({ path: `/private/consumer-units/${consumerUnit._id}/predicted-balances` }), [consumerUnit])
  );
  const balances = useMemo(() => (isLoadingData ? [] : data), [data, isLoadingData]);
  const totalBalanceInKwh = useMemo(() => {
    const references = {};
    for (const balance of balances) references[balance.referenceDate] = balance.totalBalanceInKwh;
    return _(references).values().sum();
  }, [balances]);

  return (
    <Fragment>
      <GridItem colSpan={{ base: 5, lg: 6 }} as={HStack} justifyContent="center" bg="whiteAlpha.100" p="10px" borderRadius="lg">
        <Center bg="green.600" w="40px" h="40px" borderRadius="full">
          <Icon as={MdOnlinePrediction} />
        </Center>
        <Box pr={2}>
          <Text fontSize="xs">Saldo futuro estimado</Text>
          <Text fontSize="sm" fontWeight="semibold">
            {locale(totalBalanceInKwh || 0, { precision: 0 })} kWh
          </Text>
        </Box>
        <IconButton size="xs" colorScheme="green" icon={<Icon as={MdVisibility} />} onClick={onOpen} />
        <Tooltip label="Saldo estimado baseado nos últimos envios de quotas menos o consumo médio da UC.">
          <IconButton size="xs" colorScheme="whiteAlpha" icon={<Icon as={AiOutlineQuestionCircle} />} />
        </Tooltip>
        {!!consumerUnit.anchor && (
          <Tooltip label="Está UC foi utilizada como âncora nas últimas importações de quotas.">
            <IconButton size="xs" colorScheme="yellow" icon={<Icon as={FaAnchor} />} />
          </Tooltip>
        )}
      </GridItem>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="80%">
          <ModalHeader as={HStack}>
            <Text flex="1">Saldo futuro estimado</Text>
            {!!consumerUnit.anchor && (
              <Tooltip label="Está UC foi utilizada como âncora nas últimas importações de quotas.">
                <IconButton size="sm" colorScheme="yellow" icon={<Icon as={FaAnchor} />} />
              </Tooltip>
            )}
            <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} onClick={refreshData} isLoading={isLoadingData} />
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th>Referência</Th>
                  <Th>Quota enviada</Th>
                  <Th>Dt. envio quota</Th>
                  <Th>Saldo anterior</Th>
                  <Th>Usina</Th>
                  <Th>Mês geração usina</Th>
                  <Th>Energ. transf. UC</Th>
                  <Th>Saldo previsto</Th>
                </Tr>
              </Thead>
              <Tbody>
                {_.map(balances, (item) => (
                  <Tr>
                    <Td>{moment(item.referenceDate).format("MMM/YYYY").toUpperCase()}</Td>
                    <Td>{percent(item.quota, { precision: 5 })}</Td>
                    <Td>{moment(item.quotaSentAt).format("DD/MM/YYYY")}</Td>
                    <Td>{locale(item.prevBalanceInKwh, { precision: 0 })} kWh</Td>
                    <Td whiteSpace="nowrap">{item.powerPlantName}</Td>
                    <Td>
                      {item.powerPlantGenerationReferenceDate ? (
                        <Text>{moment(item.powerPlantGenerationReferenceDate).format("MMM/YYYY").toUpperCase()}</Text>
                      ) : (
                        <HStack>
                          <Text>-</Text>
                          <Tooltip label="A geração do período não foi encontrada para esta usina.">
                            <IconButton size="xs" colorScheme="yellow" icon={<Icon as={MdWarning} />} />
                          </Tooltip>
                        </HStack>
                      )}
                    </Td>
                    <Td>{locale(item.transferredPowerQttInKwh, { precision: 0 })} kWh</Td>
                    <Td>{locale(item.totalBalanceInKwh, { precision: 0 })} kWh</Td>
                  </Tr>
                ))}
                {isLoadingData && (
                  <Tr>
                    <Td p="20px" colSpan={8} textAlign="center">
                      <Spinner />
                    </Td>
                  </Tr>
                )}
              </Tbody>
              <Tfoot>
                <Tr fontWeight="semibold">
                  <Td colSpan={7}>TOTAL</Td>
                  <Td>{locale(totalBalanceInKwh, { precision: 0 })} kWh</Td>
                </Tr>
              </Tfoot>
            </Table>
          </ModalBody>
          <ModalFooter as={HStack} justifyContent="space-between">
            <Box>
              <Text fontSize="sm">Méd. últ. {_.size(consumerUnit.lastsElectricityConsumes)} consumos</Text>
              <Text fontWeight="semibold">{locale(consumerUnit.avgLastsElectricityConsumes || 0, { precision: 0 })} kWh</Text>
            </Box>
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default PredictedBalances;
