import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import XMLViewer from "react-xml-viewer";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import { masks, percent, currency, locale } from "lib";
import { MdCheck, MdClose, MdInfoOutline } from "react-icons/md";
import InvoicesDetailsContext from "../context";
import { FaMinus, FaPlus } from "react-icons/fa";
import DistributorXmlContent from "./distributorXmlContent";

const Infos = ({ data }) => (
  <Popover placement="bottom-end">
    <PopoverTrigger>
      <IconButton size="xs" icon={<Icon as={MdInfoOutline} />} />
    </PopoverTrigger>
    <PopoverContent textAlign="left">
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>Avisos</PopoverHeader>
      <PopoverBody>
        <UnorderedList fontWeight="regular" fontSize="xs">
          {data.map((text) => (
            <ListItem key={text}>{text}</ListItem>
          ))}
        </UnorderedList>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const TrNext = ({ to }) => (
  <Tr bg={"green.100"}>
    <Td>{to.description}</Td>
    <Td textAlign="center">{to.powerQttInKwh ? `${to.powerQttInKwh?.toLocaleString()} kWh` : "-"}</Td>
    <Td textAlign="center">{to.powerTariffPerKwh ? currency(to.powerTariffPerKwh, { precision: 8 }) : "-"}</Td>
    <Td textAlign="center">{to.icmsAliquot ? percent(to.icmsAliquot) : "-"}</Td>
    <Td textAlign="center">{to.icmsAmount ? currency(to.icmsAmount) : "-"}</Td>
    <Td textAlign="center">{to.pisCofinsAmount ? currency(to.pisCofinsAmount) : "-"}</Td>
    <Td textAlign="center">{to.powerPricePerKwh ? currency(to.powerPricePerKwh, { precision: 8 }) : "-"}</Td>
    <Td textAlign="right">{to.amount ? currency(to.amount) : "-"}</Td>
  </Tr>
);

const Distributor = () => {
  const { formData } = useContext(InvoicesDetailsContext);

  const TextDiff = useCallback(
    ({ path, children, ...rest }) => {
      const prevValue = _.get(formData.distributor?.current, path);
      const nextValue = _.get(formData.distributor?.changes, `${path}.to`);
      return nextValue ? (
        <Box>
          <HStack justifyContent="space-between" bg="red.100" px="10px" borderRadius="md">
            <Text fontSize="sm" fontWeight="semibold" {...rest}>
              {children?.(prevValue) ?? prevValue}
            </Text>
            <Icon as={FaMinus} color="red.500" boxSize="8px" />
          </HStack>
          <HStack justifyContent="space-between" bg="green.100" px="10px" borderRadius="md">
            <Text fontSize="sm" fontWeight="semibold" {...rest}>
              {children?.(nextValue) ?? nextValue}
            </Text>
            <Icon as={FaPlus} color="green.500" boxSize="8px" />
          </HStack>
        </Box>
      ) : (
        <Text fontSize="sm" fontWeight="semibold" {...rest}>
          {children?.(prevValue) ?? prevValue}
        </Text>
      );
    },
    [formData.distributor?.current, formData.distributor?.changes]
  );

  return (
    <>
      {formData.distributor?.current?.isDemonstrative && (
        <Alert status="info" fontSize="sm" borderRadius="lg" mb={2}>
          <AlertIcon />
          <Box>
            <Text fontWeight="semibold">Atenção</Text>
            <Text>
              Esta conta CEMIG foi dispensada de pagamento por não possuir código de barras ou não atingir o valor mínimo necessário.
            </Text>
          </Box>
        </Alert>
      )}
      <Box borderRadius="lg" borderWidth="1px" mb={2}>
        <HStack p="20px">
          <Box flex="1">
            <Heading size="sm">Fatura CEMIG</Heading>
            <Text fontSize="sm">Detalhamento da fatura da CEMIG importada através da captura de faturas.</Text>
          </Box>
          <DistributorXmlContent />
          <InReviewActions />
        </HStack>

        <Divider />

        <Box p="20px">
          <Grid templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Instalação</Text>
              <TextDiff path="installationNumber" />
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Nº do cliente</Text>
              <TextDiff path="customerNumber" />
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Referência</Text>
              <TextDiff path="referenceDate">{(value) => moment(value).format("MMM/YYYY").toUpperCase()}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Vencimento</Text>
              <TextDiff path="dueDate">{(value) => moment(value).format("DD/MM/YYYY")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Emissão</Text>
              <TextDiff path="issueDate">{(value) => moment(value).format("DD/MM/YYYY")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Energia consumida</Text>
              <TextDiff path="baseConsumedPowerQttInKwh">{(value) => (value || 0).toLocaleString().concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Energia consumida (calculada)</Text>
              <TextDiff path="consumedPowerQttInKwh">{(value) => (value || 0).toLocaleString().concat(" kWh")}</TextDiff>
            </GridItem>

            <GridItem colSpan={2}>
              <Text fontSize="xs">Data da leitura anterior</Text>
              <TextDiff path="previousReadingDate">{(value) => moment(value).format("DD/MM/YYYY")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Leitura anterior</Text>
              <TextDiff path="previousReadingInKwh">{(value) => (value || 0).toLocaleString().concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Data da leitura atual</Text>
              <TextDiff path="currentReadingDate">{(value) => moment(value).format("DD/MM/YYYY")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Leitura atual</Text>
              <TextDiff path="currentReadingInKwh">{(value) => (value || 0).toLocaleString().concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Data da próxima leitura</Text>
              <TextDiff path="nextReadingDate">{(value) => moment(value).format("DD/MM/YYYY")}</TextDiff>
            </GridItem>

            <GridItem colSpan={2}>
              <Text fontSize="xs">Saldo PONTA</Text>
              <TextDiff path="onPeakBalanceInKwh">{(value) => locale(value || 0, { precision: 0 }).concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Saldo FORAP</Text>
              <TextDiff path="offPeakBalanceInKwh">{(value) => locale(value || 0, { precision: 0 }).concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Saldo TOTAL</Text>
              <TextDiff path="totalBalanceInKwh">{(value) => locale(value || 0, { precision: 0 }).concat(" kWh")}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Alíquota PASEP</Text>
              <TextDiff path="pasepAliquot">{(value) => percent(value)}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Alíquota ICMS</Text>
              <TextDiff path="icmsAliquot">{(value) => percent(value)}</TextDiff>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontSize="xs">Alíquota COFINS</Text>
              <TextDiff path="cofinsAliquot">{(value) => percent(value)}</TextDiff>
            </GridItem>

            <GridItem colSpan={2}>
              <Text fontSize="xs">N° de Série da Nota Fiscal</Text>
              <TextDiff path="fiscalReceiptSerialNumber" />
            </GridItem>
            <GridItem colSpan={10}>
              <Text fontSize="xs">Código de barras CEMIG</Text>
              <TextDiff path="digitableLine">{(value) => masks.digitableLine(value) || "-"}</TextDiff>
            </GridItem>
          </Grid>
        </Box>

        <Divider mb={4} />

        <Table variant="striped" fontSize="sm">
          <Thead>
            <Tr whiteSpace="nowrap">
              <Th>Descrição</Th>
              <Th textAlign="center">Quantidade</Th>
              <Th textAlign="center">Tarifa base</Th>
              <Th textAlign="center">Alíq. ICMS</Th>
              <Th textAlign="center">Valor ICMS</Th>
              <Th textAlign="center">Valor PIS/COFINS</Th>
              <Th textAlign="center">Tarifa</Th>
              <Th textAlign="right">Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(formData.distributor?.current?.items, (item, index) => {
              const to = _.get(formData, `distributor.changes.items.${index}.to`);
              return (
                <Fragment key={item.description}>
                  <Tr bg={to && "red.100"}>
                    <Td>
                      <TextDiff fontWeight="regular" path={`items.${index}.description`} />
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.powerQttInKwh`}>
                        {(value) => (value ? `${(value || 0).toLocaleString()} kWh` : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.powerTariffPerKwh`}>
                        {(value) => (value ? currency(value, { precision: 8 }) : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.icmsAliquot`}>
                        {(value) => (value ? percent(value) : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.icmsAmount`}>
                        {(value) => (value ? currency(value) : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.pisCofinsAmount`}>
                        {(value) => (value ? currency(value) : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="center">
                      <TextDiff fontWeight="regular" path={`items.${index}.powerPricePerKwh`}>
                        {(value) => (value ? currency(value, { precision: 8 }) : "-")}
                      </TextDiff>
                    </Td>
                    <Td textAlign="right" whiteSpace="nowrap">
                      <TextDiff fontWeight="regular" path={`items.${index}.amount`}>
                        {(value) => (value ? currency(value) : "-")}
                      </TextDiff>
                    </Td>
                  </Tr>
                  {to && <TrNext to={to} />}
                </Fragment>
              );
            })}
            {Object.entries(_.get(formData.distributor, "changes.items") || {}).map(([key, value]) => {
              if (_.has(formData.distributor, `items.${key}`)) return;
              if (value?.to) return <TrNext key={key} to={value?.to} />;
            })}
          </Tbody>
          <Tfoot>
            <Tr fontWeight="semibold">
              <Td colSpan={6} borderBottomWidth="0">
                <Text>TOTAL</Text>
                {formData.distributorCreditAmount > 0 && (
                  <Text fontWeight="bold" color="green.500">
                    CRÉDITO APLICADO
                  </Text>
                )}
              </Td>
              <Td colSpan={2} borderBottomWidth="0" textAlign="right">
                <HStack justifyContent="flex-end">
                  {_.size(formData.distributor?.current?.infos) >= 1 && <Infos data={formData.distributor?.current?.infos} />}
                  {formData.distributor?.current?.baseAmount !== formData.distributor?.current?.amount && (
                    <TextDiff textDecoration="line-through" whiteSpace="nowrap" path="baseAmount">
                      {(value) => currency(value)}
                    </TextDiff>
                  )}
                  <TextDiff whiteSpace="nowrap" path="amount">
                    {(value) => currency(value)}
                  </TextDiff>
                </HStack>
                {formData.distributorCreditAmount > 0 && (
                  <Text fontWeight="bold" whiteSpace="nowrap" color="main.500">
                    -{currency(formData.distributorCreditAmount)}
                  </Text>
                )}
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </Box>
    </>
  );
};

const InReviewActions = () => {
  const { formData, handleActionData, isLoadingSaveData } = useContext(InvoicesDetailsContext);
  const [actionType, setActionType] = useState();
  const [label, colorScheme] = useMemo(() => {
    if (actionType === "distributor_apply") return ["aplicar", "green"];
    return ["descartar", "red"];
  }, [actionType]);

  const handleClose = useCallback(() => setActionType(), []);

  return (
    formData.distributor?.waiting && (
      <>
        <Button
          size="sm"
          variant="ghost"
          colorScheme="red"
          rightIcon={<Icon as={MdClose} />}
          onClick={() => setActionType("distributor_discard")}
        >
          descartar mudanças
        </Button>
        <Tooltip
          placement="left"
          label="Esta fatura possui uma cobrança relacionada. Para aplicar as alterações a cobrança deve ser cancelada."
          isDisabled={!formData.charge}
        >
          <Button
            size="sm"
            colorScheme="green"
            rightIcon={<Icon as={MdCheck} />}
            onClick={() => setActionType("distributor_apply")}
            isDisabled={formData.charge}
          >
            aplicar mudanças
          </Button>
        </Tooltip>
        <AlertDialog isOpen={_.isString(actionType)} onClose={handleClose} isCentered>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Atenção</AlertDialogHeader>
            <AlertDialogBody>
              <Text>Deseja {label} as mudanças na fatura do distribuidor? </Text>
              {actionType === "distributor_apply" && (
                <Text fontSize="xs" mt={2}>
                  Após este processo a fatura será movida novamente para o status "Em análise" devido ao reprocessamento dos cálculos.
                </Text>
              )}
            </AlertDialogBody>
            <AlertDialogFooter as={HStack} justify="flex-end">
              <Button size="sm" onClick={handleClose}>
                cancelar
              </Button>
              <Button
                size="sm"
                colorScheme={colorScheme}
                isLoading={isLoadingSaveData}
                onClick={handleActionData.bind(this, actionType, handleClose)}
              >
                {label} mudanças
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  );
};

export default Distributor;
