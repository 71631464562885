import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Center, HStack, Icon, IconButton, Spinner, Text, VStack, useDisclosure, Button } from "@chakra-ui/react";
import _ from "lodash";
import { useFetchData } from "hooks";
import { MdRefresh, MdAdd } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import CustomersDetailsContext from "../context";
import AddContract from "./add";
import ListItem from "./item";

export const ContractsContext = createContext();

const Contracts = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading, consumerUnits, refreshConsumerUnits } = useContext(CustomersDetailsContext);
  const [contracts, isLoadingContracts, refreshContracts] = useFetchData(
    useMemo(
      () => ({
        path: `/private/customers/${_id}/contracts`,
        params: { sort: { createdAt: 1 }, perPage: -1 },
        options: { isEnabled: _.isString(_id) },
      }),
      [_id]
    )
  );
  const consumerUnitsWithoutContract = useMemo(
    () => _.filter(consumerUnits?.data, (o) => !o.contract && o.isActive),
    [consumerUnits?.data]
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCounters((state) => ({ ...state, contracts: contracts?.size }));
  }, [contracts?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, contracts: isLoadingContracts }));
  }, [isLoadingContracts]);

  return (
    <ContractsContext.Provider value={{ refreshContracts }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(contracts?.data)} registros
        </Text>
        {_.size(consumerUnitsWithoutContract) > 0 && (
          <Button size="sm" variant="outline" rightIcon={<Icon as={MdAdd} />} onClick={onOpen}>
            adicionar contrato ({_.map(consumerUnitsWithoutContract, "cemigInstallationNumber").join(", ")})
          </Button>
        )}
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingContracts} onClick={refreshContracts} />
      </HStack>

      <VStack align="stretch">
        {_.map(contracts?.data, (item) => (
          <Box borderWidth="1px" borderRadius="lg" position="relative">
            <ListItem key={item._id} {...{ item }} />
            {_.size(item.additives) >= 1 && (
              <VStack alignItems="stretch" p="15px">
                {_.map(item.additives, (item) => (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    position="relative"
                    _light={{ bg: item.parent && "gray.50" }}
                    _dark={{ bg: item.parent && "gray.900" }}
                  >
                    <ListItem key={item._id} {...{ item }} isAdditive={true} />
                  </Box>
                ))}
              </VStack>
            )}
          </Box>
        ))}
      </VStack>

      {isLoadingContracts ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(contracts?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={HiOutlineDocumentText} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum contrato adicionado
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui contratos adicionados.</Text>
            </Box>
          </Center>
        )
      )}

      <AddContract isOpen={isOpen} onClose={onClose} consumerUnits={consumerUnitsWithoutContract} onFinish={refreshConsumerUnits} />
    </ContractsContext.Provider>
  );
};

export default Contracts;
