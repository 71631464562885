import { memo } from "react";
import _ from "lodash";
import { HStack, Spinner, Tab, Text } from "@chakra-ui/react";

export const CustomTab = memo(({ icon, title, isLoading, count, RightComponent, ...props }) => {
  return (
    <Tab {...props}>
      <HStack>
        {icon}
        <Text>{title}</Text>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          RightComponent ||
          (_.isNumber(count) && (
            <Text fontSize="sm" fontWeight="semibold">
              ({count})
            </Text>
          ))
        )}
      </HStack>
    </Tab>
  );
});
