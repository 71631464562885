import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { api } from "lib";
import { useFetchData, useCustomToast } from "hooks";

export const Address = ({ data = {}, errors = {}, isRequired = false, onChange, isDisabled }) => {
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);
  const [states, isLoadingStates] = useFetchData(useMemo(() => ({ path: "/common/states" }), []));
  const toast = useCustomToast();

  const handleZipCodeChange = useCallback(
    async ({ target }) => {
      try {
        const zipCode = target.value.replace(/\D/g, "");
        onChange({ zipCode });
        if (zipCode.length !== 8) return;
        setIsLoadingZipCode(true);
        const address = await api.post(`/common/locales/${zipCode}`);
        onChange(address);
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingZipCode(false);
      }
    },
    [onChange]
  );

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 2 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.zipCode}>
            <FormLabel fontSize="sm">CEP</FormLabel>
            <InputGroup>
              <Input as={InputMask} mask="99999-999" value={data.zipCode ?? ""} onChange={handleZipCodeChange} isDisabled={isDisabled} />
              {isLoadingZipCode && (
                <InputRightElement>
                  <Spinner size="sm" />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{errors.zipCode}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 8, lg: 6 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.city}>
            <FormLabel fontSize="sm">Município</FormLabel>
            <Input value={data.city ?? ""} onChange={({ target }) => onChange({ city: target.value })} isDisabled={isDisabled} />
            <FormErrorMessage>{errors.city}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 4 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.state}>
            <FormLabel fontSize="sm">Estado</FormLabel>
            <HStack>
              <Select
                flex="1"
                value={data.state ?? ""}
                onChange={({ target }) => onChange({ state: target.value })}
                isDisabled={isDisabled}
              >
                <option value="">--Selecione</option>
                {_.map(states, (state) => (
                  <option key={state.initials} value={state.initials}>
                    {state.name}
                  </option>
                ))}
              </Select>
              {isLoadingStates && <Spinner />}
            </HStack>
            <FormErrorMessage>{errors.state}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 12 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.street}>
            <FormLabel fontSize="sm">Rua</FormLabel>
            <Input value={data.street ?? ""} onChange={({ target }) => onChange({ street: target.value })} isDisabled={isDisabled} />
            <FormErrorMessage>{errors.street}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 6, lg: 3 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.neighborhood}>
            <FormLabel fontSize="sm">Bairro</FormLabel>
            <Input
              value={data.neighborhood ?? ""}
              onChange={({ target }) => onChange({ neighborhood: target.value })}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>{errors.neighborhood}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 6, lg: 3 }}>
          <FormControl isRequired={isRequired} isInvalid={errors.number}>
            <FormLabel fontSize="sm">Número</FormLabel>
            <Input value={data.number ?? ""} onChange={({ target }) => onChange({ number: target.value })} isDisabled={isDisabled} />
            <FormErrorMessage>{errors.number}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <FormControl isInvalid={errors.complement}>
            <FormLabel fontSize="sm">Complemento</FormLabel>
            <Input
              value={data.complement ?? ""}
              onChange={({ target }) => onChange({ complement: target.value })}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>{errors.complement}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
};
