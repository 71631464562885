import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = useMemo(() => {
    const response = {};
    for (const [key, value] of searchParams.entries()) response[key] = value;
    return response;
  }, [searchParams]);

  const setQueryParams = useCallback(
    (params, options) => {
      if (params instanceof Function) setSearchParams(params(queryParams), options);
      else setSearchParams(params, options);
    },
    [queryParams, setSearchParams]
  );

  return { queryParams, setQueryParams };
};
