import React, { memo, useState } from "react";
import _ from "lodash";
import { StackDivider, VStack } from "@chakra-ui/react";
import FileItem from "./item";
import Downloads from "./downloads";
import FileListContext from "./context";

export const FileList = memo(({ path = "/private/files", data = [], onChange, isEditable = true, isDeletable = true }) => {
  const [selected, setSelected] = useState();

  return (
    <FileListContext.Provider value={{ selected, setSelected, onChange }}>
      <VStack align="stretch" divider={<StackDivider />}>
        {_.map(data, (item) => (
          <FileItem key={item._id} {...{ path, item, isEditable, isDeletable }} />
        ))}
      </VStack>
      <Downloads path={path} />
    </FileListContext.Provider>
  );
});
