import React, { createContext, useEffect, useState } from "react";
import { Box, Center, HStack, Icon, IconButton, StackDivider, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { currency } from "lib";
import { MdAdd, MdVisibility } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import List from "./list";
import Create from "./create";
import { PermissionedContainer } from "components";

export const entityTypes = { trader: "ClickLivre", distributor: "CEMIG" };

export const CreditsContext = createContext();

const Credits = ({ consumerUnit = {}, entityType }) => {
  const { isOpen: isOpenList, onOpen: onOpenList, onClose: onCloseList } = useDisclosure();
  const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();
  const [query, setQuery] = useState();

  useEffect(() => {
    if (isOpenList) setQuery({ entityType, status: "consolidated" });
    else setQuery();
  }, [isOpenList]);

  return (
    <CreditsContext.Provider
      value={{
        consumerUnit,
        entityType,
        query,
        setQuery,
        isOpenList,
        onOpenList,
        onCloseList,
        isOpenCreate,
        onOpenCreate,
        onCloseCreate,
      }}
    >
      <HStack borderWidth="1px" borderRadius="lg" p="15px" spacing={2}>
        <Center w="40px" h="40px" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }} borderRadius="full">
          <Icon as={GrMoney} />
        </Center>
        <Box flex="1">
          <Text fontSize="xs" fontWeight="semibold">
            Créditos {entityTypes[entityType]}
          </Text>
          <HStack spacing={3} alignItems="flex-start" divider={<StackDivider />}>
            <Text fontSize="xs">Disponível • {currency(consumerUnit[entityType.concat("AvailableCreditBalance")] || 0)}</Text>
            <Text fontSize="xs">Bloqueado • {currency(consumerUnit[entityType.concat("BlockedCreditBalance")] || 0)}</Text>
            <Text fontSize="xs">Agendado • {currency(consumerUnit[entityType.concat("ScheduledCreditBalance")] || 0)}</Text>
          </HStack>
        </Box>
        <PermissionedContainer required={`customers.consumerUnits.credits.${entityType}.create`}>
          <Tooltip label="Novo lançamento">
            <IconButton size="sm" icon={<Icon as={MdAdd} />} variant="outline" onClick={onOpenCreate} />
          </Tooltip>
        </PermissionedContainer>
        <Tooltip label="Visualizar lançamentos">
          <IconButton size="sm" icon={<Icon as={MdVisibility} />} onClick={onOpenList} />
        </Tooltip>
      </HStack>
      <List />
      <Create />
    </CreditsContext.Provider>
  );
};

export default Credits;
