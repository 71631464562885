import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import ObjectID from "bson-objectid";
import { useCustomToast } from "hooks";
import { api } from "lib";
import { messages } from "consts";
import { MdClose, MdSave } from "react-icons/md";
import ConsumerUnitsStatusesContext from "./context";

const UploadConfirm = ({ file, consumerUnits, setConsumerUnits }) => {
  const { refreshData } = useContext(ConsumerUnitsStatusesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isOpen = useMemo(() => _.size(consumerUnits) >= 1, [consumerUnits]);
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({});
  }, [isOpen]);

  const handleClose = useCallback(() => setConsumerUnits(), []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.upload("/private/imports/consumer-units-power-plants/create", [file], data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        handleClose();
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [file, handleClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = { _id: ObjectID().toHexString(), consumerUnits };
      handleSaveData(data);
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, consumerUnits, handleSaveData]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="space-between">
          <Text>Confirmar importação</Text>
          <IconButton sze="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={handleClose} />
        </ModalHeader>
        <ModalBody>
          <Text mb={4}>
            O arquivo selecionado possui {_.size(consumerUnits)} unidades consumidoras. Deseja realmente realizar a importação?
          </Text>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={handleClose}>
            cancelar
          </Button>
          <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSave} />} onClick={handleSubmit} isLoading={isLoadingSaveData}>
            confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadConfirm;
