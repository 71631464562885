import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useCustomToast, useFetchData } from "hooks";
import { Paginator, PermissionedContainer } from "components";
import { api, currency, EventEmitter } from "lib";
import { MdAdd, MdBlock, MdCheck, MdClose, MdHourglassEmpty, MdRefresh, MdRemove } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { CreditsContext, entityTypes } from "./index";
import { BiTrash } from "react-icons/bi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";

const StatusButton = ({ icon, colorScheme, title, value, isActive, onClick }) => {
  return (
    <HStack
      flex="1"
      p="10px"
      justifyContent="center"
      cursor="pointer"
      borderRadius="lg"
      _light={{ bg: isActive ? colorScheme.concat(".500") : "gray.50" }}
      _dark={{ bg: isActive ? colorScheme.concat(".500") : "whiteAlpha.100" }}
      color={isActive && "white"}
      onClick={onClick}
    >
      <Center
        w="35px"
        h="35px"
        _light={{ bg: isActive ? colorScheme.concat(".600") : "gray.100" }}
        _dark={{ bg: isActive ? colorScheme.concat(".600") : "gray.900" }}
        color={isActive && "white"}
        borderRadius="full"
      >
        <Icon as={icon} />
      </Center>
      <Box>
        <Text fontSize="xs">{title}</Text>
        <Text fontSize="sm" fontWeight="semibold">
          {currency(value || 0)}
        </Text>
      </Box>
    </HStack>
  );
};

const ListItem = ({ item }) => {
  const { consumerUnit, entityType } = useContext(CreditsContext);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const toast = useCustomToast();
  const [icon, colorScheme] = useMemo(() => {
    if (item.transactionType === "inflow") return [MdAdd, "green"];
    return [MdRemove, "red"];
  }, [item.transactionType]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/private/consumer-units/${consumerUnit._id}/credits/${item._id}`);
      EventEmitter.emit("consumerUnits.refresh");
      EventEmitter.emit("consumerUnitCredits.refresh");
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [consumerUnit._id, onCloseDeleteDialog, toast]);

  return (
    <Fragment>
      <HStack py="10px">
        <Center color={colorScheme.concat(".500")} bg={colorScheme.concat(".100")} w="50px" h="50px" borderRadius="full">
          {item.installmentCount > 1 ? (
            <Text fontSize="xs" fontWeight="bold">
              {item.currentInstallment}/{item.installmentCount}
            </Text>
          ) : (
            <Icon as={icon} />
          )}
        </Center>
        <Box flex="1">
          <Text fontWeight="semibold">{currency(item.amount)}</Text>
          {item.status === "scheduled" && <Text fontSize="xs">Agendado para {moment(item.scheduledDate).format("DD/MM/YYYY")}</Text>}
          {item.status === "consolidated" && (
            <Text fontSize="xs">Consolidado em {moment(item.consolidatedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
          )}
          <Text fontSize="xs">
            Criado {moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm")} por {item.createdBy?.name || "-"}
          </Text>
        </Box>
        {_.size(item.description) >= 1 && (
          <Popover placement="left">
            <PopoverTrigger>
              <IconButton size="xs" icon={<Icon as={HiOutlineChatBubbleBottomCenterText} />} />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody fontSize="xs">{item.description}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {item.invoice && (
          <Tooltip label="Fatura de origem do lançamento" placement="left">
            <Button as={RouterLink} to={`/invoices/details/${item.invoice._id}`} target="_blank" size="xs" variant="outline">
              #{item.invoice.nid}
            </Button>
          </Tooltip>
        )}
        <PermissionedContainer required={`customers.consumerUnits.credits.${entityType}.delete`}>
          {item.status !== "consolidated" && (
            <IconButton size="xs" colorScheme="red" icon={<Icon as={BiTrash} />} onClick={onOpenDeleteDialog} />
          )}
        </PermissionedContainer>
      </HStack>
      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir o registro selecionado?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onCloseDeleteDialog}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

const List = () => {
  const { consumerUnit, entityType, query, setQuery, isOpenList, onCloseList, onOpenCreate } = useContext(CreditsContext);
  const [page, setPage] = useState(0);
  const [perPage] = useState(20);
  const [data, isLoadingData, refreshData] = useFetchData(
    useMemo(
      () => ({
        path: `/private/consumer-units/${consumerUnit._id}/credits`,
        params: { query, page, perPage, sort: { createdAt: -1 } },
        options: { isEnabled: _.isObject(query) },
      }),
      [consumerUnit._id, query, page, perPage]
    )
  );

  useEffect(() => {
    const listener = EventEmitter.addListener("consumerUnitCredits.refresh", refreshData);
    return () => listener.remove();
  }, [refreshData]);

  return (
    <Modal
      isOpen={isOpenList}
      onClose={onCloseList}
      size="xl"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack}>
          <Text flex="1" fontSize="md">
            Créditos {entityTypes[entityType]}
          </Text>
          <PermissionedContainer required={`customers.consumerUnits.credits.${entityType}.create`}>
            <Button size="sm" leftIcon={<Icon as={MdAdd} />} variant="outline" onClick={onOpenCreate}>
              novo lançamento
            </Button>
          </PermissionedContainer>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onCloseList} />
        </ModalHeader>
        <Divider />

        <HStack p="10px">
          <StatusButton
            icon={MdCheck}
            colorScheme="green"
            title="Crédito disponível"
            value={consumerUnit[entityType.concat("AvailableCreditBalance")]}
            isActive={query?.status === "consolidated"}
            onClick={() => setQuery((state) => ({ ...state, status: "consolidated" }))}
          />
          <StatusButton
            icon={MdHourglassEmpty}
            colorScheme="yellow"
            title="Crédito agendado"
            value={consumerUnit[entityType.concat("ScheduledCreditBalance")]}
            isActive={query?.status === "scheduled"}
            onClick={() => setQuery((state) => ({ ...state, status: "scheduled" }))}
          />
          <StatusButton
            icon={MdBlock}
            colorScheme="red"
            title="Crédito bloqueado"
            value={consumerUnit[entityType.concat("BlockedCreditBalance")]}
            isActive={query?.status === "blocked"}
            onClick={() => setQuery((state) => ({ ...state, status: "blocked" }))}
          />
        </HStack>

        <Divider />

        <ModalBody>
          {isLoadingData ? (
            <Center p="40px">
              <Spinner />
            </Center>
          ) : (
            _.size(data?.data) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={GrMoney} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum lançamento
                  </Text>
                  <Text fontSize="sm">Ainda não lançamentos para esta unidade consumidora.</Text>
                </Box>
              </Center>
            )
          )}
          <VStack align="stretch" divider={<StackDivider />}>
            {_.map(data?.data, (item) => (
              <ListItem key={item._id} item={item} />
            ))}
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack} justifyContent="center">
          <Paginator
            loading={isLoadingData}
            page={page}
            size={data?.size}
            perPage={perPage}
            onPaginate={setPage}
            isResultSizeVisible={false}
            isCentered={true}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default List;
