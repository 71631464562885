import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  MenuItem,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { api, EventEmitter, yup } from "lib";
import { messages } from "consts";
import { TbArchive } from "react-icons/tb";
import MessagesDetailsContext from "./context";

const Archive = () => {
  const { _id, formData: parentFormData, refreshData } = useContext(MessagesDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAllowedArchived = useMemo(
    () =>
      _.includes(["bounce", "complaint", "failed", "error", "timeout"], parentFormData.status) &&
      parentFormData.verificationStatus === "pending",
    [parentFormData.status, parentFormData.verificationStatus]
  );
  const toast = useCustomToast();

  useEffect(() => {
    setFormData({ verificationStatus: "archived", archivedComments: "" });
    setFormErrors({});
  }, [isOpen]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.patch(`/private/messages/${_id}`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        onClose();
        refreshData();
        EventEmitter.emit("messages.refresh");
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        archivedComments: yup.string().max(100, messages.error.maxLength(100)).required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Fragment>
      {isAllowedArchived && (
        <Button size="sm" variant="outline" leftIcon={<Icon as={TbArchive} />} onClick={onOpen}>
          arquivar mensagem
        </Button>
      )}
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <Text>Atenção</Text>
            <Text fontSize="md" fontWeight="light">
              Deseja realmente arquivar a mensagem atual?
            </Text>
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.archivedComments}>
              <HStack mb="5px" justifyContent="space-between">
                <FormLabel mb="0">Observações</FormLabel>
                <Text fontSize="xs" color={_.size(formData.archivedComments) > 100 && "red.500"}>
                  {_.size(formData.archivedComments)}/100
                </Text>
              </HStack>
              <Textarea
                h="100px"
                value={formData.archivedComments || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, archivedComments: target.value }))}
              />
              <FormErrorMessage>{formErrors.archivedComments}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" onClick={handleSubmit} isLoading={isLoadingSaveData}>
              continuar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
};

export default Archive;
