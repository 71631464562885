import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import ReactJson from "react-json-view";
import { HStack, Icon, IconButton, Text, VStack, StackDivider, Box } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { useClipboard, useQueryParams } from "hooks";
import { FiCopy } from "react-icons/fi";
import PaymentsDetailsContext from "./context";
import { currency } from "lib";
import { TbExternalLink } from "react-icons/tb";

const General = () => {
  const { formData } = useContext(PaymentsDetailsContext);
  const { setQueryParams } = useQueryParams();
  const copyToClipboard = useClipboard();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status
        </Text>
        <StatusBadge schema="webhooks" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Evento
        </Text>
        <Text noOfLines={1} fontSize="xs">
          {formData.event || "-"}
        </Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          ID do Webhook
        </Text>
        <Text noOfLines={1} fontSize="xs">
          {formData.webhookId || "-"}
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.webhookId)} />
      </HStack>

      {formData.data?.bankSlipId && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              ID do boleto
            </Text>
            <Text noOfLines={1} fontSize="xs">
              {formData.data?.bankSlipId || "-"}
            </Text>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={FiCopy} />}
              onClick={() => copyToClipboard(formData.data?.bankSlipId)}
            />
          </HStack>

          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Cobrança
            </Text>
            <Text>#{formData.charge?.nid || "-"}</Text>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={TbExternalLink} />}
              onClick={() => setQueryParams((params) => ({ ...params, charge_id: formData.charge._id }))}
            />
          </HStack>
        </VStack>
      )}

      {formData.data?.paymentId && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              ID do pagamento
            </Text>
            <Text noOfLines={1} fontSize="xs">
              {formData.data?.paymentId || "-"}
            </Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.data?.paymentId)} />
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Pagamento
            </Text>
            <Text>#{formData.payment?.nid || "-"}</Text>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={TbExternalLink} />}
              onClick={() => setQueryParams((params) => ({ ...params, payment_id: formData.payment._id }))}
            />
          </HStack>
        </VStack>
      )}

      {formData.data?.approvalId && (
        <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              ID da aprovação
            </Text>
            <Text noOfLines={1} fontSize="xs">
              {formData.data?.approvalId || "-"}
            </Text>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={FiCopy} />}
              onClick={() => copyToClipboard(formData.data?.approvalId)}
            />
          </HStack>
          <HStack>
            <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
              Aprovação
            </Text>
            <Text>{formData.approval?.nid || "-"}</Text>
            <IconButton
              size="sm"
              variant="outline"
              icon={<Icon as={TbExternalLink} />}
              onClick={() => setQueryParams((params) => ({ ...params, approval_id: formData.approval._id }))}
            />
          </HStack>
        </VStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data da criação
        </Text>
        <Text>{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      {formData.error && (
        <Box bg="gray.50" p="10px" borderRadius="lg">
          <ReactJson src={formData.error} name={false} />
        </Box>
      )}

      <HStack bg="main.100" color="main.900" p="20px" borderRadius="lg">
        <Text flex="1" whiteSpace="nowrap" fontSize="lg">
          Valor
        </Text>
        <Text fontSize="lg">{currency(formData.data?.amount)}</Text>
      </HStack>
    </VStack>
  );
};

export default General;
