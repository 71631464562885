import React, { useMemo } from "react";
import _ from "lodash";
import { Button, HStack, Icon, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { VscSend } from "react-icons/vsc";
import Context from "./context";
import Dialog from "./dialog";

export const BankSlipSend = ({
  appearance = "Button",
  label = "Enviar boleto",
  invoice = {},
  charge = {},
  customer = {},
  colorScheme = "main",
  variant = "solid",
  isDisabled,
  onRefreshCustomer,
  isLoadingCustomer,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const ButtonComponent = useMemo(() => {
    switch (appearance) {
      case "Button":
        return (
          <Button size="sm" leftIcon={<Icon as={VscSend} />} isDisabled={isDisabled} onClick={onOpen} {...{ colorScheme, variant }}>
            {label.toLowerCase()}
          </Button>
        );
      case "MenuItem":
        return (
          <MenuItem isDisabled={isDisabled} onClick={onOpen}>
            <HStack>
              <Icon as={VscSend} />
              <Text>{label.toLowerCase()}</Text>
            </HStack>
          </MenuItem>
        );
    }
  }, [appearance, colorScheme, variant]);

  return (
    <Context.Provider value={{ label, isOpen, onClose, invoice, charge, customer, onRefreshCustomer, isLoadingCustomer }}>
      {ButtonComponent}
      <Dialog />
    </Context.Provider>
  );
};
