import React, { memo } from "react";
import { Text } from "@chakra-ui/react";
import { masks } from "lib";

export const AddressText = memo(({ address = {}, ...props }) => {
  return (
    <Text fontSize="xs" {...props}>
      {[address.street, address.number, address.complement, address.neighborhood].filter((o) => o).join(", ")} - {address.city}/
      {address.state} - CEP {masks.zipCode(address.zipCode)}
    </Text>
  );
});
