import React from "react";
import _ from "lodash";
import { HStack, Icon, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { MdClose } from "react-icons/md";
import Context from "./context";
import Dialog from "./dialog";

export const ChargeCancel = ({ charge, isDisabled }) => {
  const { isOpen: isOpenCancelDialog, onOpen: onOpenCancelDialog, onClose: onCloseCancelDialog } = useDisclosure();

  return (
    <Context.Provider value={{ charge, isOpenCancelDialog, onCloseCancelDialog }}>
      <PermissionedContainer required="charges.cancel">
        <MenuItem isDisabled={isDisabled} onClick={onOpenCancelDialog}>
          <HStack>
            <Icon as={MdClose} />
            <Text>cancelar cobrança</Text>
          </HStack>
        </MenuItem>
        <Dialog />
      </PermissionedContainer>
    </Context.Provider>
  );
};
