import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { HStack, Icon, IconButton, Text, VStack, StackDivider, Box } from "@chakra-ui/react";
import { StatusBadge } from "components";
import { useClipboard, useQueryParams } from "hooks";
import { masks, currency } from "lib";
import { TbExternalLink } from "react-icons/tb";
import { FiCopy } from "react-icons/fi";
import PaymentsDetailsContext from "./context";

const General = () => {
  const { setQueryParams } = useQueryParams();
  const { formData } = useContext(PaymentsDetailsContext);
  const copyToClipboard = useClipboard();

  return (
    <VStack alignItems="stretch" divider={<StackDivider />} spacing={4}>
      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Fatura de origem
        </Text>
        <Text>#{formData.invoice?.nid || "-"}</Text>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/invoices/details/${formData.invoice?._id}`}
          target="_blank"
        />
      </HStack>

      {formData.approval && (
        <HStack>
          <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
            Aprovação
          </Text>
          <Text>#{formData.approval.nid || "-"}</Text>
          <IconButton
            size="sm"
            variant="outline"
            icon={<Icon as={TbExternalLink} />}
            onClick={() => setQueryParams((params) => ({ ...params, approval_id: formData.approval._id }))}
          />
        </HStack>
      )}

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Status do pagamento
        </Text>
        <StatusBadge schema="payments" status={formData.status} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Linha digitável
        </Text>
        <Text noOfLines={1} fontSize="xs" maxW="200px">
          {formData.digitableLine || "-"}
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.digitableLine)} />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Cliente
        </Text>
        <Box textAlign="right">
          <Text>{formData.customer?.name}</Text>
          <Text fontSize="xs">{masks.document(formData.customer?.document)}</Text>
        </Box>
        <IconButton
          size="sm"
          variant="outline"
          as={RouterLink}
          icon={<Icon as={TbExternalLink} />}
          to={`/customers/edit/${formData.customer?._id}`}
          target="_blank"
        />
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data da criação
        </Text>
        <Text>{moment(formData.createdAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data do último evento
        </Text>
        <Text>{moment(formData.updatedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          Data do agendamento
        </Text>
        <Text>{moment(formData.scheduledDate).format("DD/MM/YYYY")}</Text>
      </HStack>

      <HStack>
        <Text opacity="0.6" flex="1" whiteSpace="nowrap" fontWeight="semibold">
          ID do pagamento
        </Text>
        <Text fontSize="xs">{formData.paymentId || "-"}</Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={FiCopy} />} onClick={() => copyToClipboard(formData.paymentId)} />
      </HStack>

      <HStack bg="main.100" color="main.900" p="20px" borderRadius="lg">
        <Text flex="1" whiteSpace="nowrap" fontSize="lg">
          Valor do boleto
        </Text>
        <Text fontSize="lg">{currency(formData.amount)}</Text>
      </HStack>
    </VStack>
  );
};

export default General;
