import { useEffect, useState } from "react";

export const useStickyState = ({ key, defaultValue, useCached = true, _v, processor }) => {
  const [value, setValue] = useState(() => {
    try {
      const parsed = JSON.parse(window.localStorage.getItem(key));
      if (useCached && parsed._v === _v) return processor?.(parsed.value) ?? parsed.value;
      return defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (useCached) window.localStorage.setItem(key, JSON.stringify({ _v, value }));
  }, [key, useCached, _v, value]);

  return [value, setValue];
};
