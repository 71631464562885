import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Button, HStack, Icon, IconButton, Text, Tooltip, useDisclosure, Center, Spinner, Box } from "@chakra-ui/react";
import { FileList, FileUpload } from "containers";
import { useFetchData } from "hooks";
import { MdAttachFile, MdRefresh } from "react-icons/md";
import CustomersDetailsContext from "../context";

const Files = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoading } = useContext(CustomersDetailsContext);
  const [cemigFiles, isLoadingCemigFiles, refreshCemigFiles] = useFetchData(
    useMemo(
      () => ({
        path: `/private/files`,
        params: {
          query: { owner: _id, group: "cemig" },
          perPage: -1,
          sort: { title: "asc" },
        },
        options: { isEnabled: !!_id },
      }),
      [_id]
    )
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCounters((state) => ({ ...state, cemigFiles: cemigFiles?.size }));
  }, [cemigFiles?.size]);

  useEffect(() => {
    setIsLoading((state) => ({ ...state, cemigFiles: isLoadingCemigFiles }));
  }, [isLoadingCemigFiles]);

  return (
    <>
      <HStack mb="20px">
        <Text fontSize="sm" fontWeight="semibold" flex="1">
          {_.size(cemigFiles?.data)} arquivos adicionados
        </Text>
        <HStack justifyContent="flex-end">
          <Tooltip
            placement="left"
            isDisabled={_id}
            shouldWrapChildren
            label="Antes de adicionar arquivos você deve salvar o cliente atual."
          >
            <Button
              flex={{ base: "1", lg: "none" }}
              size="sm"
              colorScheme="main"
              rightIcon={<Icon as={MdAttachFile} />}
              isDisabled={!_id}
              onClick={onOpen}
            >
              incluir arquivos
            </Button>
          </Tooltip>
        </HStack>
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingCemigFiles}
          onClick={refreshCemigFiles}
        />
      </HStack>

      <FileList data={cemigFiles?.data} onChange={refreshCemigFiles} />
      <FileUpload group="cemig" ownerRef="Customer" owner={_id} isOpen={isOpen} onClose={onClose} onChange={refreshCemigFiles} />

      {isLoadingCemigFiles ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(cemigFiles?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdAttachFile} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhum arquivo adicionado
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui arquivos da CEMIG adicionados.</Text>
            </Box>
          </Center>
        )
      )}
    </>
  );
};

export default Files;
