import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Box, Button, Center, Grid, GridItem, HStack, Heading, Icon, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { PermissionedContainer } from "components";
import { FileList, FileUpload } from "containers";
import { usePermissioned } from "hooks";
import { MdAttachFile } from "react-icons/md";
import LeadsDetailsContext from "../context";

const GroupList = ({ group, title, description, data, isInvalid }) => {
  const { _id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { refreshFiles } = useContext(LeadsDetailsContext);
  const isEditable = usePermissioned("leads.files.update");
  const isDeletable = usePermissioned("leads.files.delete");

  return (
    <Box align="stretch" borderWidth="1px" borderRadius="lg" borderColor={isInvalid && "red.500"} p="20px">
      <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={_.size(data) > 0 && "20px"}>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <HStack>
            <Center bg={isInvalid ? "red.100" : "main.100"} w="45px" h="45px" borderRadius="full">
              <Icon as={MdAttachFile} color={isInvalid ? "red.500" : "main.500"} />
            </Center>
            <Box flex="1">
              <Heading size="md">{title}</Heading>
              <Text fontSize="xs">{description}</Text>
            </Box>
          </HStack>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 6 }}>
          <HStack justifyContent="flex-end">
            <PermissionedContainer required="leads.files.create">
              <Tooltip
                placement="left"
                isDisabled={_id}
                shouldWrapChildren
                label="Antes de adicionar arquivos você deve salvar a lead atual."
              >
                <Button
                  flex={{ base: "1", lg: "none" }}
                  size="sm"
                  colorScheme="main"
                  rightIcon={<Icon as={MdAttachFile} />}
                  isDisabled={!_id}
                  onClick={onOpen}
                >
                  incluir arquivos
                </Button>
              </Tooltip>
            </PermissionedContainer>
          </HStack>
        </GridItem>
      </Grid>
      <FileList data={data} onChange={refreshFiles} isEditable={isEditable} isDeletable={isDeletable} />
      <FileUpload group={group} ownerRef="Lead" owner={_id} isOpen={isOpen} onClose={onClose} onChange={refreshFiles} />
    </Box>
  );
};

export default GroupList;
